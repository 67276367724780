<template>
    <div class="row">
        <div :class="{ 'form-group': true, 'col-12': !inline, 'col-4': inline }">
            <label>Variable {{variableNum}} Type:</label>
            <rq-select-box
                :automation_id="`drp_variable_${variableNum}_type`"
                :items="variableTypes"
                :default-item-enabled="false"
                v-model="varType"
                @change="onVariableTypeChanged"
            />
        </div>
        <div :class="{ 'col-12': !inline, 'col-8': inline }">
            <rq-action-form-group
                :label="`Variable ${variableNum} Value:`"
                action-label="Assign Selected"
                :show-action="assignSelectedVisible"
                @action-click="assignSelectedValue">
                <input v-if="isConstant"
                    :automation_id="`txt_variable_${variableNum}_value`"
                    type="text"
                    class="form-control"
                    v-model="varValue">
                <span v-else-if="varValue" class="form-text">{{varValue}}&nbsp;</span>
                <span v-else class="form-text text-muted">Select a {{selectedTypeText}}&nbsp;</span>
            </rq-action-form-group>
        </div>
    </div>
</template>

<script>
    import { VariableType } from "./models";
    import { StatementHelpers } from "./helpers";
    export default {
        name:"MergeStatementVariable",
        props: {
            index: { type: Number, default: 0 },
            variableKey: { type: String, default: null },
            variableType: { type: Number, default: 1 },
            variableValue: { type: String, default: null },
            selectedPromptItem: { type: Object, default: null },
            selectedPromptData: { type: Object, default: null },
            selectedFieldItem: { type: Object, default: null },
            assignOnChange: { type: Boolean, default: false },
            inline: { type: Boolean, default: false }
        },
        data () {
            const self = this;
            return {
                variableTypes: VariableType.lookupItems
            };
        },
        computed: {
            isConstant() { return this.varType === VariableType.Constant; },
            variableNum() { return this.index + 1; },
            varKey: {
                get() { return this.variableKey; },
                set(val) { this.$emit("update:variableKey", val); }
            },
            varType: {
                get() { return this.variableType; },
                set(val) { this.$emit("update:variableType", val); }
            },
            varValue: {
                get() { return this.variableValue; },
                set(val) { this.$emit("update:variableValue", val); }
            },
            selectedTypeText() { return VariableType.displayValue(this.varType); },
            assignSelectedVisible() {
                return !this.assignOnChange && ((this.varType === VariableType.MergeField && !_.isNil(this.selectedFieldItem)) ||
                    (this.varType === VariableType.Prompt && !_.isNil(this.selectedPromptItem)));
            }
        },
        watch: {
            selectedPromptItem(newValue, oldValue) {
                if(!this.assignOnChange || this.varType !== VariableType.Prompt || newValue === oldValue) return;
                this.assignSelectedValue();
            },
            selectedFieldItem:{
                handler(newValue, oldValue) {
                    if(!this.assignOnChange || this.varType !== VariableType.MergeField) return;
                    this.assignSelectedValue();
                },
                deep: true
            }
        },
        methods: {
            onVariableTypeChanged(e) {
                this.varKey = "";
                this.varValue = "";
                this.$emit("type-change", { value: this.varType });
            },

            assignSelectedValue() {
                if(this.varType === VariableType.MergeField && !_.isNil(this.selectedFieldItem)) {
                    this.varKey = this.selectedFieldItem.key;
                    this.varValue = `[MF:${this.selectedFieldItem.objectPath}]`;
                }
                if(this.varType === VariableType.Prompt && !_.isNil(this.selectedPromptItem) && !_.isNil(this.selectedPromptData)) {
                    this.varKey = this.selectedPromptItem.key;
                    this.varValue = StatementHelpers.buildPromptValueStatement(this.selectedPromptData);
                }
            }
        }
    };
</script>