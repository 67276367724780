<template>
    <div class="rq-doc-dialog prompt-form">
        <rq-scroll-container>
            <rqValidationSummary :dataSource="validationErrors" />
            <rq-page-section title="Clause Prompt Settings" borderless>
                <fieldset>
                    <div class="row">
                        <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group form-required">
                            <label for="txt_prompt_name">Name</label>
                            <input automation_id="txt_prompt_name" v-model="name" type="text" class="form-control" id="txt_prompt_name"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-12 col-sm-12 col-md-12 col-lg-12 form-group form-required">
                            <label for="txt_prompt_text">Prompt Text</label>
                            <input automation_id="txt_prompt_text" v-model="promptText" type="text" class="form-control" id="txt_prompt_text"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-4 col-sm-12 col-md-4 col-lg-4 form-group">
                            <label for="drp_level">Level</label>
                            <rq-select-box
                                :items="levelOptions"
                                :default-item-enabled="false"
                                v-model="level"
                            />
                        </div>
                        <div class="col col-4 col-sm-12 col-md-4 col-lg-4 form-group">
                            <label for="drp_level">Default Category</label>
                            <rq-select-box
                                ref="categoryDropDown"
                                automation_id="drp_category"
                                :items="categories"
                                data-text-field="category"
                                data-value-field="standardLanguageCategoryID"
                                show-clear-value-icon
                                v-model="defaultCategory"
                            />
                        </div>
                        <div class="col col-4 col-sm-12 col-md-4 col-lg-4 form-group">
                            <label for="drp_level">Save Options</label>
                            <rq-select-box
                                :items="saveOptions"
                                :default-item-enabled="false"
                                v-model="saveOption"
                            />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col col-4 col-sm-12 col-md-6 col-lg-4 form-group">
                            <label for="chk_required">Prompt Required</label>
                            <b-form-checkbox
                                automation_id="chk_required"
                                id="chk_required"
                                v-model="isRequired">Required</b-form-checkbox>
                        </div>
                        <div class="col col-4 col-sm-12 col-md-6 col-lg-4 form-group">
                            <label for="chk_keep_doc_font">Font</label>
                            <b-form-checkbox
                                automation_id="chk_keep_doc_font"
                                id="chk_keep_doc_font"
                                v-model="keepDocFont">Keep Document Font</b-form-checkbox>
                        </div>
                    </div>
                </fieldset>
            </rq-page-section>
        </rq-scroll-container>
        <statement-preview :statement="currentStatement"/>
    </div>
</template>
<script>

    import StatementPreview from "./StatementPreview";
    import { ClientDropDownListItem, PromptLookupOptions, PromptDataType, PromptDataFormat } from "./models";

    export default{
        name: "StandardLanguagePromptForm",
        components: {StatementPreview},
        data(){
            return {
                name: null,
                promptText: null,
                level: 1,
                saveOption: 2,
                isRequired: false,
                keepDocFont: false,
                categories: [],
                defaultCategory: null,
                validationErrors: []
            }
        },
        created(){
            this.fetchCategories();
        },
        computed:{
            levelOptions(){
                return PromptLookupOptions.level;
            },
            saveOptions(){
                return PromptLookupOptions.saveOption;
            },
            currentStatement(){
                const self = this;
                if(!self.name) return "";

                let selectedLevel = _.find(self.levelOptions, l => { return l.id === self.level;});
                let selectedSaveOption = _.find(self.saveOptions, so => { return so.id === self.saveOption;});
                let defaultCategoryString = "";

                if(self.defaultCategory){
                    let selectedCategory = _.find(self.categories, c => { return c.standardLanguageCategoryID === self.defaultCategory; });
                    defaultCategoryString = `~Default=${selectedCategory.category}`;
                }

                let isRequiredString = self.isRequired ? "~IsRequired=True" : "";
                let keepDocFontString = self.keepDocFont ? "~KeepDocFont=True" : "";

                return `[STDLANGPROMPT @${self.name || ''} "${self.promptText || ''}"~Level=${selectedLevel.value}~SaveOption=${selectedSaveOption.value}${defaultCategoryString}${isRequiredString}${keepDocFontString}]`;
            },


        },
        watch:{

        },
        methods:{

            fetchCategories(){
                const self = this;
                let apiPromise = self.$api.DocumentsApi.getStandardLanguageCategories(0, 0);
                return self.$rqBusy.wait(apiPromise)
                    .then(response => {
                       self.categories = response;
                    });
            },

            validate(){
                const self = this;
                self.validationErrors.length = 0;
                if(!self.name)
                    self.validationErrors.push("Clause Prompt Name is required");
                if(!self.promptText)
                    self.validationErrors.push("Clause Prompt Text is required");
                return self.validationErrors.length === 0;
            },


        }
    }
</script>