<template>
    <div class="content-wrapper save-documents h-theme">
        <rq-banner
            icon="fas fa-exclamation-circle"
            variant="warn"
            :message="warnMessage"
            :visible="warnMessage.length > 0"
            @dismiss="warnMessage=''"
            dismissable
        />
        <rq-banner
            icon="fas fa-exclamation-circle"
            variant="error"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <div v-show="!saveComplete" class="save-documents-options">
            <div v-show="!saveComplete" class="save-options">
                <fieldset>
                    <div v-if="showFileScan" class="file-scan-options">
                        <div v-if="showFileScanGrid" class="modal-grid-container">
                            <rqdx-action-data-grid
                                ref="dataGrid"
                                automation_id="tbl_file_scan_documents"
                                :actions="selectionActions"
                                :config="gridConfig"
                                :data-source="gridDataSource"
                                @delete="onDeleteDocument"
                                export-file-name="generated_file_scan_documents"
                                focus-after-last-row="first-row"
                                hide-search
                                hide-export
                                hide-show-column-chooser
                                rq-editable
                            />
                        </div>
                        <div v-if="!showFileScanGrid">
                            <div class="row">
                                <div class="form-group form-required" :class="{ 'col-lg-4': viewModel.requiresAttention, 'col-lg-6': !viewModel.requiresAttention, 'has-error':v$.viewModel.fileScanCategoryID.$error }">
                                    <label>Document Management Category</label>
                                    <dx-select-box
                                        :input-attr="{ automation_id: 'drp_filescan_category', id: 'drp_filescan_category' }"
                                        :items="fileScanCategories"
                                        :search-enabled="true"
                                        :show-clear-button="false"
                                        placeholder="Select..."
                                        value-expr="id"
                                        display-expr="name"
                                        v-model="v$.viewModel.fileScanCategoryID.$model"
                                        @valueChanged="onDocumentCategoryChanged"
                                    />
                                    <rq-validation-feedback>Category is required</rq-validation-feedback>
                                </div>
                                <div class="form-group form-required" :class="{ 'col-lg-5': viewModel.requiresAttention, 'col-lg-6': !viewModel.requiresAttention, 'has-error':v$.viewModel.fileScanDescriptionID.$error }">
                                    <label>Document Management Description</label>
                                    <dx-select-box
                                        ref="filescan_description"
                                        :input-attr="{ automation_id: 'drp_filescan_description', id: 'drp_filescan_description' }"
                                        :items="fileScanDescriptions"
                                        :search-enabled="true"
                                        value-expr="id"
                                        display-expr="name"
                                        :accept-custom-value="!localSecurity.PredefinedDescriptions"
                                        v-model="v$.viewModel.fileScanDescriptionID.$model"
                                        @customItemCreating="onCustomItemCreating"
                                        @valueChanged="onPredefinedDescriptionChanged"
                                        :disabled="isDescriptionDisabled"
                                    />
                                    <rq-validation-feedback>Description is required</rq-validation-feedback>
                                </div>
                                <div class="form-group col-lg-3" v-if="viewModel.requiresAttention">
                                    <label>Action</label>
                                    <dx-select-box
                                        :input-attr="{ automation_id: 'drp_filescan_action', id: 'drp_filescan_action' }"
                                        :items="documentActionsShort"
                                        :search-enabled="true"
                                        value-expr="id"
                                        display-expr="name"
                                        v-model="viewModel.fileScanDocumentDuplicateAction"
                                        @valueChanged="onDocumentActionChanged"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-12 form-group">
                                    <label for="tb_tags">Tags</label>
                                    <dx-tag-box
                                        automation_id="tb_tags"
                                        ref="tb_tags"
                                        class="form-control"
                                        :data-source="documentTags"
                                        display-expr="name"
                                        value-expr="id"
                                        :search-enabled="true"
                                        :show-selection-controls="true"
                                        :show-clear-button="true"
                                        :max-displayed-tags="3"
                                        :show-drop-down-button="true"
                                        apply-value-mode="useButtons"
                                        v-model="viewModel.tagIDs"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>

        <div v-show="saveComplete" class="save-success-msg">
            <span>{{successMessage}}</span>
            <div v-show="showDownloadLinks" class="container-fluid">
                <div v-for="doc in documentDownloads" :key="doc.clientKey" class="row">
                    <div class="col-6">{{doc.fileName}}</div>
                    <div class="col-6">
                        <a ref="downloadLinks" :href="doc.downloadUrl" :download="doc.fileName">Download</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { DateTime } from "luxon";
    import { mapState, mapGetters } from "vuex";
    import { SystemLookupItem } from "@/shared/models/models";
    import { required } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { FileScanDocumentDuplicateAction }  from "../../document-mgmt/enums";
    import { SaveDocumentViewModel, DocumentModel, DocumentDownloadModel, DocumentSaveRequest, DocumentFileType, FileScanDocumentSaveRequest } from '../models';
    import { SaveDocumentLocation } from "../enums";

    export default {
        name: "SaveDocumentsDialog",
        props: {
            viewModel: { type: Object, default: new SaveDocumentViewModel() },
            documents: { type: Array, default: () => [] },
            isDocumentGeneration: { type: Boolean, default: true },
        },
        setup: () => ({ v$: useVuelidate() }),
        data () {
            return {
                targetDocuments: [],
                existingDocuments: [],
                fileTypes: [],
                documentDownloads: [],
                errorMessage: "",
                warnMessage: "",
                originalFileScanDescription: "",
                saveComplete: false,
                successMessage: "Saving Documents...",
                showDownloadLinks: false,
                fileScanCategories: [],
                fileScanDescriptions: [],
                usedFileScanDescriptions: [],
                saveLocations: [],
                selectionActions: [],
                gridConfig: {}
            };
        },

        watch: {
            "v$.$error":{
                handler: function(newValue, oldValue) {
                    this.$emit(`${newValue ? "disable" : "enable"}-ok`);
                },
                deep: true,
                immediate: true
            },
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                order: state => state.orders.order,
                user: state => state.authentication.session.user
            }),
            localSecurity(){
                return this.securitySettings.findValues(["CanReplaceDocuments","FileScanCanAttachFilesAndDocuments","PredefinedDescriptions", "RestrictSendToOptionsToPDFEntry"]);
            },
            hasDescriptionChanged() { return !_.isEqual(this.originalDescription, this.targetDocuments[0].description); },
            isDescriptionDisabled() { return this.viewModel.documentDescription !== ""; },
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null) || {}; },
            dxThemeClass() {
                let routeTheme = _.get(this, "$route.meta.theme", null);
                return routeTheme ? `rq-dx-theme-${routeTheme}` : "";
            },
            showFileScan () {
                return this.viewModel.saveLocation === SaveDocumentViewModel.SaveLocation.FileScan;
            },
            showFileScanGrid () {
                return this.viewModel.saveAll && Number(this.viewModel.fileType) !== DocumentFileType.PDF_COMBINED;
            },
            canEditDescription () {
                return true;
            }
        },

        validations: () => ({
            viewModel: {
                fileScanCategoryID: { required },
                fileScanDescriptionID: { required },
            }
        }),

        created () {
            this.initNonReactiveVariables();
            if (!this.showFileScan && this.viewModel.fileType > 0) return;
            this.initGridConfig();
            this.fetchData();
        },

        mounted() {
            if (this.showFileScan || this.viewModel.fileType <= 0) return;
            //immediately triggering a save causes the load indicator to display before the dialog manager component
            //has updated the dialog position and dimensions, causing it to display in the wrong location on the screen
            _.delay(() => {
                this.saveComplete = true;
                this.save();
            }, 200);
        },

        methods: {
            beginLinkDownload(index=0) {
                const self = this;
                let downloadLinks = _.get(self, "$refs.downloadLinks", null) || [];
                if(downloadLinks.length === 0 || downloadLinks.length <= index)
                {
                    self.setSaveComplete({ message: `${downloadLinks.length} Document${downloadLinks.length > 1 ? 's' : ''} saved.` });
                    return;
                }
                downloadLinks[index].click();
                _.delay(function() {
                    self.beginLinkDownload(index + 1);
                }, 500);
            },

            createSaveRequest() {
                const self = this;
                let saveDocuments = _.map(self.targetDocuments, d => new DocumentModel(d));
                _.remove(saveDocuments, d => d.fileScanDocumentDuplicateAction === FileScanDocumentDuplicateAction.Ignore);
                _.forEach(saveDocuments, (sd, i) => {
                    sd.fileType = self.isDocumentGeneration ? DocumentFileType.DOCX : _.get(sd, 'fileType') || DocumentFileType.DOCX;
                    sd.fileContentType = self.isDocumentGeneration ? DocumentFileType.DOCX : _.get(sd, 'fileType') || DocumentFileType.DOCX;
                    sd.ordersID = self.isDocumentGeneration ? _.get(self, 'order.ordersID') : _.get(sd, 'ordersID') || _.get(self, 'order.ordersID');
                    if(_.isNil(sd.fileBytes) && !_.isNil(sd.fileContent)) {
                        sd.fileBytes = sd.fileContent;
                        sd.fileContent = null;
                    }
                });

                if (self.viewModel.saveLocation === SaveDocumentViewModel.SaveLocation.FileScan) {
                    return new FileScanDocumentSaveRequest({
                        ordersID: self.viewModel.orderId,
                        fileType: self.viewModel.fileType,
                        fileScanDocumentDuplicateAction: self.viewModel.fileScanDocumentDuplicateAction,
                        fileScanCategoryID: self.viewModel.fileScanCategoryID,
                        fileScanDescription: self.viewModel.fileScanDescription,
                        fileScanDescriptionID: self.viewModel.fileScanDescriptionID,
                        tagIDs: self.viewModel.tagIDs,
                        documents: saveDocuments
                    });
                } else {
                    return new DocumentSaveRequest({
                        orderId: self.viewModel.orderId,
                        outputFileType: self.viewModel.fileType,
                        documents: saveDocuments
                    });
                }
            },

            downloadDocuments () {
                const self = this;
                let downloadUrlBase = `${location.origin}/api`;
                let saveRequest = self.createSaveRequest();

                let apiPromise = self.$api.DocumentsApi.processForDownload(saveRequest);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.documentDownloads = _.map(result.documentDownloads, dl => new DocumentDownloadModel(dl, downloadUrlBase));
                        self.$nextTick(() => {
                            //Download links will only work once unless the period with which they are cached is altered
                            //self.showDownloadLinks = true;

                            self.beginLinkDownload();
                        });
                    })
                    .catch(error => {
                        self.$toast.error({ message: error.errorMessage });
                    });
            },

            encodeRequestContent(blob) {
                return new Promise((resolve,reject) => {
                    let reader = new FileReader();
                    reader.onload = function(e) {
                        resolve(btoa(e.target.result));
                    }
                    reader.readAsBinaryString(blob);
                });
            },

            fetchData () {
                const self = this;
                let apiPromise = self.$api.FileScanApi.getFileScanDocuments(self.viewModel.orderId);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.usedFileScanDescriptions = _.map(result, r => _.pick(r, ["fileType", "fileScanCategoryID", "description"]));
                        if (self.targetDocuments.length == 1) {
                            self.setDocumentRequiresAction();
                        } else {
                            self.setGridDocumentRequiresAction();
                            self.validate();
                        }
                    })
                    .finally(() => {
                        self.refresh();
                        self.validate();
                    });
            },

            getExistingFileScanCategoryID(item){
                let dup = {};
                dup = _.find(this.usedFileScanDescriptions, (i) => {
                    return _.toLower(_.trim(i.description)) === _.toLower(_.trim(item.description))
                           && _.parseNumber(i.fileType, -1) === _.parseNumber(item.fileType, -1);
                });
                return _.isEmpty(dup) ? this.user.defaultCategory : _.get(dup, "fileScanCategoryID", 0);
            },

            isUniqueDocument(fileScanDescription, fileType, clientKey){
                let dup = {};
                dup = _.find(this.usedFileScanDescriptions, (i) => {
                    return _.toLower(_.trim(i.description)) === _.toLower(_.trim(fileScanDescription))
                        && _.parseNumber(i.fileType, -1) === _.parseNumber(fileType, -1)
                        ;
                });
                if (_.isEmpty(dup)) {
                    dup = _.find(this.targetDocuments, (i) => {
                        return _.toLower(_.trim(i.fileScanDescription)) === _.toLower(_.trim(fileScanDescription))
                            && _.parseNumber(i.fileType, -1) === _.parseNumber(fileType, -1)
                            && _.parseNumber(i.clientKey, -1) !== _.parseNumber(clientKey, -1)
                            ;
                    });
                }
                return _.isEmpty(dup);
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemKey = "clientKey";
                self.labelTimestamp = DateTime.now().toFormat("yyyy-MM-dd hh:mm:ss a");
                self.fileScanCategories = self.lookupHelpers.getLookupItems(self.lookupItems.FILE_SCAN_CATEGORIES, self.order?.regionID);
                self.fileScanDescriptions = _.sortBy(self.lookupHelpers.getLookupItems(self.lookupItems.FILE_SCAN_DESCRIPTIONS, self.order?.regionID), ['name']);
                self.documentTags = self.lookupHelpers.getLookupItems(self.lookupItems.DOCUMENT_TAGS);
                self.fileTypes = DocumentFileType.saveLookupItems;
                self.saveLocations = SaveDocumentViewModel.getSaveLocationOptions(self.localSecurity.FileScanCanAttachFilesAndDocuments);
                self.documentActions = self.localSecurity.CanReplaceDocuments
                                     ? FileScanDocumentDuplicateAction.lookupItems
                                     : _.filter(FileScanDocumentDuplicateAction.lookupItems, function(i) { return i.id != FileScanDocumentDuplicateAction.ReplaceExisting ; });
                self.documentActionsShort = _.remove(_.clone(self.documentActions), (i) => {return i.id > 0;});

                self.targetDocuments = _.map(self.documents, d => {
                    let temp = new DocumentModel(d);
                    temp.fileScanDescriptionID = null;
                    temp.fileScanDescription = '';
                    return self.localSecurity.PredefinedDescriptions ? temp : new DocumentModel(d);
                });

                if (!self.viewModel.saveAll) {
                    _.remove(self.fileTypes, s => _.parseNumber(s.id, null) === DocumentFileType.PDF_COMBINED);
                }

                if(self.targetDocuments.length === 1)
                {
                    // Single Document
                    if (self.isDescriptionDisabled) {
                        self.viewModel.fileScanDescription = self.originalFileScanDescription = self.viewModel.documentDescription;
                        self.fileScanDescriptions.push(new SystemLookupItem({id: 0, name: self.viewModel.fileScanDescription}));
                    } else if(self.localSecurity.PredefinedDescriptions) {
                        // Can only select from predefined descriptions
                        // fileScanDescription should remain empty and force user to select from predefined descriptions
                        self.viewModel.fileScanDescription = "";
                        self.viewModel.fileScanDescriptionID = null;
                    } else {
                        // Can create custom
                        self.viewModel.fileScanDescription = self.originalFileScanDescription = self.targetDocuments[0].description;
                        self.fileScanDescriptions.push(new SystemLookupItem({id: 0, name: self.viewModel.fileScanDescription}));
                    }
                }
                else if(self.targetDocuments.length > 1 && self.viewModel.fileType === DocumentFileType.PDF_COMBINED )
                {
                    if(self.localSecurity.PredefinedDescriptions) {
                        // Can only select from predefined descriptions
                        // fileScanDescription should remain empty and force user to select from predefined descriptions
                        self.viewModel.fileScanDescription = "";
                        self.viewModel.fileScanDescriptionID = null;
                    }
                    else {
                        self.viewModel.fileScanDescription = self.originalFileScanDescription =  `Combined_Document_${self.labelTimestamp}`;
                        self.fileScanDescriptions.push(new SystemLookupItem({id: 0, name: self.viewModel.fileScanDescription}));
                    }
                }
            },
            initGridConfig() {
                const self = this;
                self.gridConfig = {
                    focusedRowEnabled: false,
                    cacheEnabled: false,
                    paging: { enabled: false },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    columns: [
                        {
                            dataField: "fileScanDescription",
                            dataType: "string",
                            caption: "Description",
                            cellTemplate(cellElement, cellInfo) {
                                cellElement.removeClass("rq-cell-invalid");
                                if(!_.isEmpty(cellInfo.text)){
                                    return DxGridUtils.truncateCellTemplate(cellElement, cellInfo);
                                }
                                cellElement.addClass("rq-cell-invalid");
                            },
                            minWidth: 200,
                            setCellValue: function(rowData, data) {
                                rowData.fileScanDescriptionID = data.cellData.fileScanDescriptionID = data.value.id;
                                rowData.fileScanDescription = data.cellData.fileScanDescription = data.value.name;
                                rowData.useDefaultDescription = data.cellData.useDefaultDescription = (data.value.id > 0);
                                if (data.cellData.fileScanDocumentDuplicateAction == 0 && !self.isUniqueDocument(data.cellData.fileScanDescription, data.cellData.fileType, data.cellData.clientKey)) {
                                    rowData.requiresAttention = data.cellData.requiresAttention = true;
                                }

                                self.setItemData(data.cellData);
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                let customItem = cellInfo.data.fileScanDocumentDuplicateAction == FileScanDocumentDuplicateAction.ReplaceExisting
                                               ? new SystemLookupItem({id: 0, name: cellInfo.data.originalDescription})
                                               : new SystemLookupItem({id: 0, name: cellInfo.data.fileScanDescription});
                                let fileScanDescriptionsPlus = _.concat([customItem], self.fileScanDescriptions);
                                $("<div />").dxSelectBox({
                                    dataSource: {
                                        loadMode: "raw", key: "id",
                                        load() {return fileScanDescriptionsPlus;}
                                    },
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    placeholder: "Select...",
                                    acceptCustomValue: !self.localSecurity.PredefinedDescriptions,
                                    value: cellInfo.data.fileScanDescriptionID,
                                    onValueChanged: function(e) {
                                        let fileScanDescription = _.find(fileScanDescriptionsPlus, ['id', e.value]);
                                        cellInfo.setValue({value: fileScanDescription, cellData: cellInfo.data});
                                    },
                                    onCustomItemCreating: function(e) {
                                        customItem.name = e.text;
                                        e.customItem = customItem;
                                        _.assign(fileScanDescriptionsPlus[0], customItem);
                                        cellInfo.setValue({value: customItem, cellData: cellInfo.data});
                                    }
                                }).appendTo(cellElement);
                            },
                            validationRules: [{ type: "required"}]
                        },
                        { dataField: "fileScanCategoryID", caption: "Category", dataType: "number",
                            minWidth: 150,
                            lookup: {
                                dataSource: self.fileScanCategories,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            editorOptions: {
                                dropDownOptions: {
                                    minWidth: 200
                                }
                            },
                            cellTemplate: function (cellElement, cellInfo) {
                                cellElement.removeClass("rq-cell-invalid");
                                if(!_.isEmpty(cellInfo.text)){
                                    $("<span/>")
                                    .append(cellInfo.text)
                                    .appendTo(cellElement);
                                    return
                                }
                                cellElement.addClass("rq-cell-invalid");
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxSelectBox({
                                    dataSource: {
                                        loadMode: "raw",
                                        load() {
                                            return self.fileScanCategories;
                                        }
                                    },
                                    searchEnabled: true,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    value: cellInfo.data.fileScanCategoryID,
                                    onValueChanged: function(e) {
                                        cellInfo.setValue({value: e.value, cellData: cellInfo.data});
                                    },
                                }).appendTo(cellElement);
                            },
                            setCellValue: function(rowData, data) {
                                rowData.fileScanCategoryID = data.cellData.fileScanCategoryID = data.value;
                                self.setItemData(data.cellData);
                            },
                            validationRules: [{ type: "required"}]
                        },
                        {
                            dataField: "fileScanDocumentDuplicateAction",
                            dataType: "string",
                            caption: "Action",
                            alignment: "left",
                            lookup: {
                                dataSource: self.documentActions,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            minWidth: 100,
                            setCellValue: function(rowData, data) {
                                if (data.value == null) return;
                                rowData.fileScanDocumentDuplicateAction = data.cellData.fileScanDocumentDuplicateAction = data.value;
                                if (data.value == FileScanDocumentDuplicateAction.CreateNew && data.cellData.fileScanDescriptionID == 0) {
                                    rowData.fileScanDescription = data.cellData.fileScanDescription = `${data.cellData.originalDescription}_${self.labelTimestamp}`;
                                }
                                if (data.value == FileScanDocumentDuplicateAction.ReplaceExisting && data.cellData.fileScanDescriptionID == 0) {
                                    rowData.fileScanDescription = data.cellData.fileScanDescription = data.cellData.originalDescription;
                                    rowData.fileScanCategoryID = data.cellData.fileScanCategoryID = self.getExistingFileScanCategoryID({description: data.cellData.fileScanDescription, fileType: self.viewModel.fileType});
                                }
                                self.setItemData(data.cellData);
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxSelectBox({
                                    dataSource: {
                                        loadMode: "raw",
                                        load() {
                                            return self.documentActions;
                                        }
                                    },
                                    searchEnabled: true,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    disabled: !_.get(cellInfo.data, 'requiresAttention', false),
                                    value: cellInfo.data.fileScanDocumentDuplicateAction,
                                    onValueChanged: function(e) {
                                        cellInfo.setValue({value: e.value, cellData: cellInfo.data});
                                    },
                                }).appendTo(cellElement);
                            }
                        },
                        {
                            dataField: "tagIDs",
                            dataType: "string",
                            caption: "Tags",
                            minWidth: 150,
                            setCellValue: function(rowData, data) {
                                let itemNames = _.map(data.value, id => _.find(self.documentTags, { id }).name);
                                rowData.tagIDs = data.cellData.tagIDs = data.value;
                                self.setItemData(data.cellData);
                            },
                            cellTemplate: function(cellElement, cellInfo) {
                                let itemNames = _.map(cellInfo.data.tagIDs, id => _.find(self.documentTags, { id }).name);
                                let tagNames = _.join(itemNames, ", ") || "";
                                $("<span />")
                                    .addClass("text-truncate")
                                    .append(tagNames)
                                    .appendTo(cellElement);
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxTagBox({
                                    dataSource: {
                                        key: "id",
                                        load() {
                                            return self.documentTags;
                                        }
                                    },
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    value: cellInfo.value,
                                    showSelectionControls: true,
                                    showDropDownButton: true,
                                    searchEnabled: true,
                                    maxDisplayedTags: 0,
                                    onValueChanged: function(e) {
                                        cellInfo.setValue({value: e.value, cellData: cellInfo.data});
                                    },
                                    onOpened: function(e) {
                                        $('.dx-list-select-all').hide()
                                    }
                                }).appendTo(cellElement);
                            }
                        },
                    ],
                };
                self.selectionActions = [
                        { name: "delete", text: "Remove", eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Remove Document` }
                    ];

                self.gridDataSource = {
                    key: self.itemKey,
                    load () {
                        return Promise.resolve(self.targetDocuments);
                    }
                };
            },
            onCustomItemCreating (e) {
                const self = this;
                if (_.isNullOrEmpty(e.text)) {
                    self.viewModel.fileScanDescriptionID = null;
                    self.viewModel.fileScanDescription = '';
                } else {
                    let customItem = _.find(self.fileScanDescriptions, ['id', 0]);
                    customItem.name = e.text;
                    self.$refs.filescan_description.instance.repaint();
                    self.viewModel.fileScanDescriptionID = customItem.id;
                    self.originalFileScanDescription = self.viewModel.fileScanDescription = customItem.name;
                    e.customItem = customItem;
                }
                self.validate();
            },

            onDeleteDocument(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = _.map(items, self.itemKey);
                    _.pullAllBy(self.targetDocuments, items, self.itemKey);
                    let message = toBeDeletedKeys.length > 1 ? `${toBeDeletedKeys.length} Documents were removed from the Save List.` : `Document was removed from the Save List.`
                    self.$toast.success({ message: message });
                    self.refresh();
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to remove the selected ${items.length > 1 ? "Documents" : "Document"} from the Save List?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onDocumentActionChanged (e) {
                if(_.isNil(e.event)) return;
                const self = this;
                if (e.value === FileScanDocumentDuplicateAction.CreateNew) {
                    let description = self.isDescriptionDisabled
                        ? `${self.originalFileScanDescription} - ${self.labelTimestamp}`
                        : `${self.originalFileScanDescription}_${self.labelTimestamp}`;
                    self.updateDescriptionDisplay(0, description);
                }
                else if(e.value === FileScanDocumentDuplicateAction.ReplaceExisting) {
                    self.updateDescriptionDisplay(0, self.originalFileScanDescription);
                }
                self.validate();
            },

            onDocumentCategoryChanged (e) {
                this.validate();
            },

            onPredefinedDescriptionChanged(e) {
                const self = this;
                let items = e.component.option("items");
                let item = _.find(items, ["id", e.value]);
                self.viewModel.fileScanDescriptionID = item?.id || null;
                self.viewModel.fileScanDescription = item?.name || "";
                self.originalFileScanDescription = item?.name || "";

                if (!self.isUniqueDocument(self.viewModel.fileScanDescription, DocumentFileType.PDF, "") && self.viewModel.fileScanDocumentDuplicateAction == 0) {
                    self.viewModel.requiresAttention = true;
                    self.viewModel.fileScanCategoryID = self.getExistingFileScanCategoryID({description: self.viewModel.fileScanDescription, fileType: DocumentFileType.PDF});
                }
                self.validate();
            },

            save () {
                const self = this;
                self.validate();

                if(!self.localSecurity.FileScanCanAttachFilesAndDocuments && self.viewModel.saveLocation == SaveDocumentLocation.DocumentManagement){
                    return;
                }
                if(self.localSecurity.RestrictSendToOptionsToPDFEntry && self.viewModel.fileType !== DocumentFileType.PDF)
                {
                   return;
                }

                if (self.errorMessage.length > 0) return;
                _.forEach(self.targetDocuments, td => {td.fileScanDocumentID = 0;});
                if (self.viewModel.saveLocation === SaveDocumentViewModel.SaveLocation.FileScan) {
                    self.saveToFileScan();
                }
                else if (!_.isEmpty(self.targetDocuments)) {
                    self.downloadDocuments();
                }
            },

            saveToFileScan () {
                const self = this;
                let saveRequest = self.createSaveRequest();
                if (saveRequest.documents.length > 0) {
                    if (!self.viewModel.saveAll) {
                        saveRequest.documents[0].description = saveRequest.documents[0].standardDescription = saveRequest.documents[0].fileScanDescription = self.viewModel.fileScanDescription;
                        saveRequest.documents[0].fileScanCategoryID = self.viewModel.fileScanCategoryID;
                        saveRequest.documents[0].fileScanDescriptionID = self.viewModel.fileScanDescriptionID;
                        saveRequest.documents[0].fileScanDocumentDuplicateAction = self.viewModel.fileScanDocumentDuplicateAction;
                        saveRequest.documents[0].tagIDs = self.viewModel.tagIDs;
                    }
                    let saveFailedMessage = "A problem occurred while saving your document(s) to Document Management.  Please try again or contact your administrator if the problem persists.";
                    let apiPromise = self.$api.FileScanApi.saveToFileScan(saveRequest);
                    self.$rqBusy.wait(apiPromise)
                        .then(result => {
                            if (!result.success) {self.errorMessage = result.message || saveFailedMessage; return}
                            self.setSaveComplete({ message: `${saveRequest.documents.length} Document${saveRequest.documents.length > 1 ? 's' : ''} saved to Document Management.` });
                        })
                        .catch(error => {
                            self.errorMessage = error.errorMessage;
                        });
                } else {
                    self.reset();
                }
            },

            setItemData(data){
                const self = this;
                let itemIndex = _.findIndex(self.targetDocuments, [self.itemKey, _.get(data, self.itemKey)]);
                self.errorMessage = "";
                let item = new DocumentModel(_.assign({}, self.targetDocuments[itemIndex], data));
                _.assign(self.targetDocuments[itemIndex], item);
                if (item.fileScanDocumentDuplicateAction == 0 && !self.isUniqueDocument(item.fileScanDescription, self.viewModel.fileType, item.clientKey)) {
                    let fileTypeName = DocumentFileType.displayValue(self.viewModel.fileType);
                    let fileScanDescription = _.parseNumber(item.fileScanDescriptionID, 0) > 0 ? self.lookupHelpers.getLookupItemName(self.lookupItems.FILE_SCAN_DESCRIPTIONS, item.fileScanDescriptionID) : item.fileScanDescription;
                    self.errorMessage = `Document with file type [${fileTypeName}] and Description [${fileScanDescription}] is already in use, please select an action.`;
                    return;
                }
                self.setGridDocumentRequiresAction();
                self.refresh(false);
                self.validate();
            },

            setDocumentRequiresAction() {
                const self = this;
                self.viewModel.requiresAttention = !self.isUniqueDocument(self.viewModel.fileScanDescription, self.viewModel.fileType, "");
                self.viewModel.fileScanCategoryID = (_.isNil(self.targetDocuments[0].fileScanCategoryID) || self.targetDocuments[0].fileScanCategoryID === 0) ? 1 : self.targetDocuments[0].fileScanCategoryID;
                if (!self.localSecurity.CanReplaceDocuments && self.viewModel.requiresAttention) {
                    self.viewModel.fileScanDocumentDuplicateAction = FileScanDocumentDuplicateAction.CreateNew;
                    let description = self.documentDescription
                        ? `${self.originalFileScanDescription} - ${self.labelTimestamp}`
                        : `${self.originalFileScanDescription}_${self.labelTimestamp}`;
                    self.updateDescriptionDisplay(0, description);
                } else {
                    self.viewModel.fileScanDocumentDuplicateAction = self.viewModel.requiresAttention ? FileScanDocumentDuplicateAction.ReplaceExisting : 0;
                }
            },

            setGridDocumentRequiresAction() {
                const self = this;
                _.each(self.targetDocuments, d => {
                    if (!self.isUniqueDocument(d.fileScanDescription, self.viewModel.fileType, d.clientKey) && d.fileScanDocumentDuplicateAction == 0) {
                        if (self.localSecurity.CanReplaceDocuments) {
                            d.fileScanDocumentDuplicateAction = FileScanDocumentDuplicateAction.ReplaceExisting;
                        } else {
                            d.fileScanDocumentDuplicateAction = FileScanDocumentDuplicateAction.CreateNew;
                            d.fileScanDescription = `${d.originalDescription}_${self.labelTimestamp}`;
                        }
                        d.fileScanCategoryID = self.getExistingFileScanCategoryID({description: d.fileScanDescription, fileType: self.viewModel.fileType});
                        d.requiresAttention = true;
                    } else {
                        if (_.isNil(d.fileScanCategoryID) && _.parseNumber(self.user.defaultCategory, 0) > 0) {
                            d.fileScanCategoryID = _.parseNumber(self.user.defaultCategory, 0);
                        }
                    }
                });
            },

            setSaveComplete ({ error, message }) {
                const self = this;
                if (error) {
                    console.error(error);
                    self.reset();
                    self.errorMessage = message || (error && (error.message || error.errorMessage)) || error;
                } else {
                    // self.successMessage = message;
                    // self.saveComplete = true;
                    // self.$emit("update-dialog-options", { okOnly: true });
                    self.$toast.success(message);
                    self.$emit("close-dialog");
                }
                // self.$emit("enable-ok");
            },

            updateDescriptionDisplay(id, name) {
                const self = this;
                self.viewModel.fileScanDescriptionID = id;
                self.viewModel.fileScanDescription = name;
                let optionIndex = _.findIndex(self.fileScanDescriptions, { id });
                if(optionIndex >= 0) {
                    self.fileScanDescriptions[optionIndex].name = name;
                }
                self.$refs.filescan_description.instance.repaint();
            },

            refresh(clear=true) {
                const self = this;
                if (!self.gridInstance || !self.showFileScanGrid) return;
                if (clear) {
                    self.gridInstance.option("focusedRowIndex", -1);
                    self.gridInstance.clearSelection();
                }
                self.gridInstance.refresh();
                self.gridInstance.updateDimensions();
            },
            reset () {
                const self = this;
                _.forEach(self.targetDocuments, d => {d.existsAction = 1;});
                self.saveComplete = false;
                self.existingDocuments = [];
            },
            validate() {
                const self = this;
                self.errorMessage = self.warnMessage = "";
                self.$emit("disable-ok");
                self.v$.$touch();
                if (self.v$.$error) {
                    self.errorMessage = "Please provide all required fields.";
                    return;
                }
                if (self.showFileScan && self.showFileScanGrid) {
                    if (self.localSecurity.PredefinedDescriptions && _.some(self.targetDocuments, ["fileScanDescriptionID", 0])) {
                        self.errorMessage = `Please select a Predefined Description.`;
                        return;
                    }
                    let requiresAttention = _.filter(self.targetDocuments, i => i.requiresAttention == true && i.fileScanDocumentDuplicateAction == 0) || [];
                    if (requiresAttention.length > 0) {
                        let invalidCount = _.size(requiresAttention);
                        self.errorMessage = `You have ${invalidCount} document${invalidCount == 1 ? '' : 's'} that require${invalidCount == 1 ? 's' : ''} attention, please select an action.`;
                    }
                    let needCategory = _.some(self.targetDocuments, ["fileScanCategoryID", null]) || _.some(self.targetDocuments, ["fileScanCategoryID", 0]);
                    if (needCategory) {
                        self.errorMessage = `Please provide all required fields.`;
                    }
                } else if (self.showFileScan && !self.showFileScanGrid) {
                    if (!self.isUniqueDocument(self.viewModel.fileScanDescription, self.viewModel.fileType, "") && self.viewModel.fileScanDocumentDuplicateAction == 0) {
                        self.errorMessage = `Document with file type [${DocumentFileType.displayValue(self.viewModel.fileType)}] and Description [${self.viewModel.fileScanDescription}] is already in use, please select an action.`;
                        self.viewModel.requiresAttention = true;
                    }
                    if (self.viewModel.fileScanCategoryID == null || self.viewModel.fileScanCategoryID == 0 || self.viewModel.fileScanDescriptionID == -1) {
                        self.errorMessage = `Please provide all required fields.`;
                    }
                }
                self.$emit(`${self.errorMessage.length + self.warnMessage.length > 0 ? 'disable' : 'enable'}-ok`);
            },
        }
    };
</script>