<template>
    <div class="rq-doc-dialog merge-condition">
        <div class="row m-0">
            <div :class="formColClassAttr">
                <rq-scroll-container>
                    <rq-page-section :borderless="!showInstanceOptions" no-header>
                        <merge-statement-variable
                            :index="0"
                            v-model:variable-key="variableKey"
                            v-model:variable-type="variableType"
                            v-model:variable-value="variableValue"
                            :selected-field-item="selectedFieldItem"
                            :selected-prompt-item="selectedPromptItem"
                            :selected-prompt-data="selectedPromptData"
                            assign-on-change
                        />
                    </rq-page-section>
                    <rq-page-section v-if="showInstanceOptions"
                        title="Instance Options"
                        header-size="sm"
                        borderless>
                        <div class="row">
                            <div class="col-12 col-lg-6 form-group">
                                <label>Type:</label>
                                <rq-select-box
                                    :items="instanceTypeItems"
                                    :default-item-enabled="false"
                                    v-model="selectedFieldItem.instanceType"
                                    @change="onInstanceTypeChange"
                                />
                            </div>
                            <div class="col-12 col-lg-6 form-group">
                                <label>Index:</label>
                                <rq-input-number
                                    default-value="0"
                                    min-value="0"
                                    value-event="input"
                                    v-model="selectedFieldItem.instanceIndex"
                                />
                            </div>
                        </div>
                    </rq-page-section>
                </rq-scroll-container>
            </div>
            <div v-show="showFieldTree" class="col col-12 col-lg-4 p-0">
                <merge-field-tree
                    ref="fieldTreeView"
                    id="rq-mf-tree-view"
                    title="Fields"
                    :items="fields"
                    v-model="selectedFieldItemKey"
                    @item-selected="onFieldItemSelected"
                    reset-item-state
                    show-title-border
                />
            </div>
            <div v-show="showPromptTree" class="col col-12 col-lg-4 p-0">
                <rq-tree-view
                    ref="promptTreeView"
                    id="rq-prompt-tree-view"
                    title="Prompts"
                    :items="promptTreeItems"
                    v-model="selectedPromptItemKey"
                    @item-selected="onPromptItemSelected"
                    show-title-border
                />
            </div>
        </div>
        <statement-preview :statement="currentStatement"/>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import MergeFieldTree from "../MergeFieldTree";
    import MergeStatementVariable from "./MergeStatementVariable";
    import StatementPreview from "./StatementPreview";
    import { TreeViewItemModel } from "@/shared/models/rq-ui";
    import { StatementHelpers } from "./helpers";
    import { MergeFieldTreeItem, VariableType } from "./models.js";

    export default {
        name:"MergeConditionForm",
        components: { MergeStatementVariable, MergeFieldTree, StatementPreview },
        props: {
            fields: { type: Array, default: () => [] },
            prompts: { type: Array, default: () => [] }
        },
        data () {
            const self = this;
            return {
                variableKey: null,
                variableType: VariableType.Constant,
                variableValue: null,
                allRoles: [],
                selectedFieldItemKey: null,
                selectedPromptItemKey: null,
                selectedFieldItem: null,
                selectedPromptItem: null,
                selectedPromptData: null,
                fieldStatement: "",
                fieldTreeItems: self.fields.slice(),
                promptTreeItems: [],
                variableTypes: VariableType.lookupItems
            };
        },

        computed: {
            ...mapGetters([
                "lookupHelpers"
            ]),
            instanceTypeItems() { return StatementHelpers.getInstanceTypes(this.selectedFieldItem, this.allRoles); },
            formColClassAttr() { return { "col col-12 p-0": true, "col-md-8": this.showPromptTree || this.showFieldTree }; },
            showFieldTree() { return this.variableType === VariableType.MergeField; },
            showInstanceOptions() { return this.showFieldTree && _.getBool(this, "selectedFieldItem.allowMergeLoop"); },
            showPromptTree() { return this.variableType === VariableType.Prompt; },
            currentStatement() {
                let valueStatement = this.variableType === VariableType.Constant && !_.isEmpty(this.variableValue)
                    ? `"${this.variableValue}"`
                    : this.variableValue;
                if(_.isEmpty(valueStatement)) return "";
                return `[RTFCharCount ${valueStatement}]`;
            }
        },

        created() {
            const self = this;
            self.allRoles = self.lookupHelpers.getRoles();
            self.fieldTreeDataSource = { load: self.fetchChildData };
            self.promptTreeItems = StatementHelpers.mapPromptTreeItems(self.prompts);
        },

        methods: {
            onFieldItemSelected(e) {
                this.selectedFieldItem = e.selectedItem;
            },

            onPromptItemSelected(e) {
                this.selectedPromptItem = e.selectedItem;
                this.selectedPromptData = _.find(this.prompts, p => p.name === e.selectedItem.name);
            },

            onInstanceTypeChange(e) {
                if(e.selectedValue === "Any" || this.selectedFieldItem.instanceIndex > 0) return;
                this.selectedFieldItem.instanceIndex = 1;
            },

            getFieldTreeItems() {
                const self = this;
                return _.invoke(self, "$refs.fieldTreeView.getFieldTree", null) || [];
            }
        }
    };
</script>