<template>
    <li :class="{ 'nav-item doc-nav-item':true, 'doc-error-nav-item': item.hasError }">
        <a :class="{ 'nav-link': true, 'active': active }" href="#" @click.prevent="onNavClick">{{item.label}}</a>
        <b-btn v-if="closeable" variant="icon" class="btn-item-close" @click="onNavClose"><FontAwesomeIcon icon="fas fa-times" /></b-btn>
        <div v-if="sortable" class="doc-nav-handle"><FontAwesomeIcon icon="fas fa-grip-lines" /></div>
    </li>
</template>
<script>
    /*
        alternative handle grip icons:
        vertical -
            fa-grip-vertical
            fa-ellipsis-v
        horizontal -
            fa-grip-horizontal
            fa-grip-lines-horizontal
            fa-ellipsis
        either -
            fa-bars
    */
    export default {
        name: "DocumentNavItem",

        props: {
            item: { default: () => {} },
            active: { type: Boolean, default: false },
            closeable: { type: Boolean, default: false },
            sortable: { type: Boolean, default: false }
        },

        methods: {
            onNavClick() {
                this.$emit("select", this.item);
            },
            onNavClose() {
                this.$emit("close", this.item);
            }
        }
    };
</script>

<style lang="scss">
    //Component-specific styles found in file, "~scss/rqo/app/documents/_doc-nav.scss"
</style>