<template>
    <div class="prompt-entry">
        <rqValidationSummary :dataSource="validationErrors" />
        <div v-if="!standardLanguagePromptMode" class="row">
            <div class="col-auto d-flex">
                <document-nav
                    key-attr="orderDocumentId"
                    label-attr="groupTitle"
                    :items="availableGroups"
                    v-model:selected-index="selectedIndex"
                />
            </div>
            <div class="col">
                <input v-if="renderPreviousFocusTarget" class="focus-target" @focus="onFocusPreviousGroup">
                <prompt-entry-group v-for="(promptGroup,index) in availableGroups"
                    ref="promptGroups"
                    v-show="selectedIndex === index"
                    :key="`prompt-group-${index}`"
                    :groupTitle="promptGroup.groupTitle"
                    :prompts="promptGroup.prompts"
                    :documentIndex="index"
                    class="prompt-doc-card">
                    <template #prompt-group-actions>
                        <ul v-if="groupCount > 1" class="nav">
                            <li class="nav-item">
                                <b-btn
                                    v-show="previousButtonVisible"
                                    variant="theme"
                                    tabindex="-1"
                                    @click="onPreviousGroupClick">
                                    <FontAwesomeIcon icon="fas fa-angle-double-left" class="me-1" />
                                    Previous Document
                                </b-btn>
                                <b-btn v-show="nextButtonVisible"
                                    variant="theme"
                                    tabindex="-1"
                                    @click="onNextGroupClick">
                                    Next Document
                                    <FontAwesomeIcon icon="fas fa-angle-double-right" class="ms-1" />
                                </b-btn>
                            </li>
                        </ul>
                    </template>
                </prompt-entry-group>
                <input v-if="renderNextFocusTarget" class="focus-target" @focus="onFocusNextGroup">
            </div>
        </div>
        <div v-else class="row stand-lang-prompts">
            <div class="col-12">
                <div class="lead mb-4">Some of the clauses you selected contain prompts as well.  Fill these out and click OK.</div>
                <div class="row">
                    <template v-for="doc in availableGroups">
                        <template v-for="promptInfo in doc.prompts">
                            <template v-for="stdLang in promptInfo.prompt.standardLanguageList">
                                <div v-if="(stdLang.prompts && stdLang.prompts.length > 0)" :key="stdLang.clientKey" class="col-12">
                                    <prompt-entry-group
                                        :prompts="stdLang.prompts"
                                        :groupTitle="stdLang.description"
                                        groupIcon="fa-bookmark"
                                        @tab-next="on"
                                    />
                                </div>
                            </template>
                        </template>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { DocumentModel, DocumentPromptInfo, DocumentPrompt, StandardLanguageModel, DocumentPromptGroup } from "../../models";
    import { PromptLevelType } from "../../enums";
    import DocumentNav from "../DocumentNav";
    import PromptEntryGroup from "./PromptEntryGroup";

    export default {
        name: "PromptEntry",

        components: {
            DocumentNav,
            PromptEntryGroup
        },

        props: {
            promptGroups: { default: () => [] }
        },

        data () {
            return {
                globalPromptGroup: new DocumentPromptGroup({ isGlobal: true }),
                validationErrors: [],
                standardLanguagePromptMode: false,
                selectedIndex: 0
            };
        },

        computed: {
            availableGroups() { return _.filter(this.promptGroups, g => g.hasPrompts); },
            groupCount() { return this.availableGroups.length; },
            previousButtonVisible() { return this.selectedIndex > 0; },
            nextButtonVisible() { return this.selectedIndex < this.groupCount - 1; },
            renderPreviousFocusTarget() { return this.groupCount > 1 && this.previousButtonVisible; },
            renderNextFocusTarget() { return this.groupCount > 1 && this.nextButtonVisible; }
        },

        watch: {
            instructionText (newValue, oldValue) {
                this.promptEntryInstruction = newValue;
            },
            selectedIndex(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.syncGlobalPrompts(oldValue);
            }
        },

        mounted() {
            this.invokeSelectedGroupMethod("focusFirstField");
        },

        methods: {
            syncGlobalPrompts(sourceIndex) {
                const self = this;
                let sourceGlobalPrompts = _.filter(self.promptGroups[sourceIndex].prompts, "isGlobal");
                _.each(sourceGlobalPrompts, gp => {
                    _.each(self.promptGroups, (group,index) => {
                        if(index === sourceIndex) return;
                       _.each(group.prompts, p => {
                           if(!p.isGlobal || p.promptName !== gp.promptName) return;
                            p.promptValue = gp.promptValue;
                       });
                    });
                });
            },

            async validate () {
                const self = this;

                self.syncGlobalPrompts(self.selectedIndex);

                let invalidPrompts = [];
                _.forEach(self.promptGroups, d => {
                    let invalidDocPrompts = d.validatePrompts();
                    if (invalidDocPrompts.length > 0) {invalidPrompts = _.concat(invalidPrompts, invalidDocPrompts);}
                });

                if (invalidPrompts.length === 0) {
                    return await self.checkStandardLanguageSubPrompts();
                }
                self.validationErrors = [];
                self.validationErrors.push("The prompts highlighted below are required.");
            },

            async checkStandardLanguageSubPrompts () {
                let components = _.get(this, "$refs.promptGroups", null) || [];
                for (let i = 0; i < components.length; i++) {
                    await components[i].loadPrompts();
                }
                if (!this.standardLanguagePromptMode) {
                    this.standardLanguagePromptMode = _.some(this.promptGroups, d =>
                        _.some(d.prompts, info =>
                            _.some(info.prompt.standardLanguageList, sl => sl.prompts.length > 0)
                        )
                    );
                    return !this.standardLanguagePromptMode;
                }
               
                // if already on slp mode, already have the prompts so return true.  Multi-levels/inner embeds is not supported at this time 
               return true;
            },

            onPreviousGroupClick(e) {
                this.goToPreviousGroup("first");
            },

            onNextGroupClick(e) {
                this.goToNextGroup();
            },

            onFocusPreviousGroup(e) {
                this.goToPreviousGroup();
            },

            onFocusNextGroup(e) {
                this.goToNextGroup();
            },

            goToPreviousGroup(focusField="last") {
                if(this.selectedIndex === 0) return;
                this.selectedIndex--;
                this.invokeSelectedGroupMethod(`focus${_.upperFirst(focusField)}Prompt`);
            },

            goToNextGroup(focusField="first") {
                if(this.selectedIndex === this.groupCount - 1) return;
                this.selectedIndex++;
                this.invokeSelectedGroupMethod(`focus${_.upperFirst(focusField)}Prompt`);
            },

            invokeSelectedGroupMethod(method, ...params) {
                this.$nextTick(() => {
                    _.invoke(this, `$refs.promptGroups[${this.selectedIndex}].${method}`, ...params);
                });
            },

            reset () {
                this.standardLanguagePromptMode = false;
                this.validationErrors = [];
            }
        }
    };
</script>