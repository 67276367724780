<template>
    <div class="rq-container content-wrapper saved-prompts">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="!isValid"
        />
        <rq-page-section v-if="hasPrompts" borderless>
            <template #header-actions>
                <ul class="nav">
                    <li v-if="deleteAllVisible" class="nav-item" v-rq-tooltip.hover.top :title="deleteAllTooltip">
                        <b-btn variant="theme" size="sm" :disabled="readOnly" @click="onDeleteAll">Delete All</b-btn>
                    </li>
                </ul>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-section-expand-collapse-all
                            :tooltip-container="tooltipContainer"
                            :tooltip-boundary="tooltipContainer"
                        />
                    </li>
                </ul>
            </template>
            <saved-prompt-group
                v-for="group in promptGroups"
                :key="group.scopeDocumentDescription"
                ref="promptGroups"
                :title="group.groupTitle"
                :prompts="group.prompts"
                @delete-prompt="onDeletePrompt"
                @delete-all="onDeleteGroup"
            />
        </rq-page-section>
        <rq-no-data v-else text="No saved prompts are available for this file." size="lg" />
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { DocumentPromptGroup } from '../../models';
    import { PromptLevelType } from '../../enums';
    import SavedPromptGroup from "./SavedPromptGroup";
    import DocumentHelpers from "../../helpers";

    export default {
        name: "SavedPrompts",

        components: {
            SavedPromptGroup
        },

        data () {
            return {
                originalData: null,
                promptGroups: [],
                deletedPrompts: [],
                globalPromptGroup: new DocumentPromptGroup({ isGlobal: true }),
                isValid: true
            };
        },

        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                fileReadOnly: state => state.isPageReadOnly || state.orders.orderSummary.isLocked,
                currentUser: state => state.authentication.session.user
            }),
            localSecurity(){
                return this.securitySettings.findValues([
                    "AllowEditPromptsFilledByOthers",
                    ]);
            },
            hasPrompts() { return !_.isEmpty(this.promptGroups); },
            tooltipContainer() { return `#${this.$parent.dialogId}`; },
            readOnly() { return this.fileReadOnly || _.some(this.$refs.promptGroups, "readOnly"); },
            deleteAllVisible() { return this.hasPrompts && _.some(this.promptGroups, g => !g.isGlobal); },
            deleteAllTooltip() {
                if(!this.readOnly) return "";
                return this.fileReadOnly ? "Access restricted on this file" : "One or more fields were saved by another user";
            },
            isDirty () { return _.some(this.promptGroups, g => g.hasDirtyPrompts) || !_.isEmpty(this.deletedPrompts); }
        },

        created() {
            this.fetchData();
        },

        methods: {

            fetchData() {
                const self = this;
                let apiPromise = self.$api.DocumentsApi.getSavedPromptsForOrder(self.orderId);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.originalData = result;
                        self.promptGroups = DocumentHelpers.mapSavedPrompts(result);
                    });
            },

            onExpanded() {
                this.$events.emit("saved-prompts:update-autosize");
            },

            onDeletePrompt(prompt) {
                const self = this;
                self.$dialog.confirm("Delete Saved Prompt", "Are you sure you want to delete this saved prompt value?", () => {
                    self.deletePrompts([prompt.orderPrintPromptValueID]);
                });
            },

            onDeleteGroup(e) {
                const self = this;
                let promptsPendingDeletion = _.map(e.prompts, p => p.orderPrintPromptValueID);
                self.$dialog.confirm("Delete Saved Prompts",
                    e.isGlobal
                        ? "Are you sure you want to delete all global saved prompt values?"
                        : `Are you sure you want to delete all saved prompt values for the document, <em>${e.description}</em>?`,
                    () => { self.deletePrompts(promptsPendingDeletion); });
            },

            onDeleteAll(e) {
                const self = this;
                self.$dialog.confirm("Delete Saved Prompts",
                    `Are you sure you want to delete all saved document prompts?  Global prompts will not be included.`,
                    () => self.deletePrompts());
            },

            deletePrompts(keys=[]) {
                const self = this;
                let allPrompts = _.flatMap(self.promptGroups, "prompts");
                let userPrompts = self.localSecurity.AllowEditPromptsFilledByOthers ? allPrompts : _.filter(allPrompts, ["usersID", self.currentUser.usersID]);
                let warn = !_.isEqual(allPrompts, userPrompts);
                let deleted = [];
                if (self.localSecurity.AllowEditPromptsFilledByOthers) {
                    deleted = _.remove(allPrompts, p => _.isEmpty(keys) ? !p.isGlobal : _.includes(keys, p.orderPrintPromptValueID));
                } else {
                    deleted = _.remove(allPrompts, p => (_.isEmpty(keys) ? !p.isGlobal : _.includes(keys, p.orderPrintPromptValueID)) && _.parseNumber(p.usersID, 0) === _.parseNumber(self.currentUser.usersID, 0));
                }
                self.deletedPrompts.push(...deleted);
                self.promptGroups = DocumentHelpers.mapSavedPrompts(allPrompts);
                if (warn) {
                    self.$dialog.messageBox("Access Restricted", "Some prompts were created by another user and were not deleted.");
                }
            },

            validate() {
                const self = this;
                let invalidPrompts = [];
                _.forEach(self.promptGroups, d => {
                    let invalidDocPrompts = d.validatePrompts();
                    if (invalidDocPrompts.length > 0) {invalidPrompts = _.concat(invalidPrompts, invalidDocPrompts);}
                });
                self.isValid = _.isEmpty(invalidPrompts);
                return self.isValid;
            },

            save() {
                const self = this;
                if(!self.isDirty) return Promise.resolve(true);

                if(!self.validate()) return Promise.resolve(false);

                let updated = _.map(_.flatMap(_.filter(self.promptGroups, "hasDirtyPrompts"), g => _.filter(g.prompts, "isDirty")), pi => pi.toDataObject());
                let deletedKeys = _.map(self.deletedPrompts, "orderPrintPromptValueID");
                let apiPromise = self.$api.DocumentsApi.updateSavedPrompts({ updated, deletedKeys });

                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.$toast.success("Prompts saved successfully.");
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error("An issue occurred while saving changes to prompts for this file.  Please try again.");
                        console.error(error);
                    });
            },

            showMessageBox(title, msg) {
                this.$dialog.open({
                    title: title,
                    height: "auto",
                    width: 400,
                    okOnly: true,
                    scrollable: false,
                    autoFocusOkButton: true,
                    component: {
                        name: "MessageBox",
                        template: `<div class="rq-message-box dialog-confirm-message">${msg}</div>`
                    },
                });
            },

            reset() {
                this.isValid = true;
                this.deletedPrompts = [];
                this.promptGroups = DocumentHelpers.mapSavedPrompts(this.originalData);
            }
        }

    };
</script>
