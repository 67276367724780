<template>
    <div class="row">
        <!--HUD/CDF/Loan-->
        <div
            v-rq-tooltip.top.hover.html
            :title="loanTooltip"
            class="col col-auto form-group">
            <label for="drp_hud_cdf_loan">HUD/CDF/Loan</label>
            <dx-select-box
                :element-attr="{ id:'drp_hud_cdf_loan', automation_id:'drp_hud_cdf_loan' }"
                class="form-control"
                value-expr="id"
                display-expr="name"
                :width="200"
                :items="loanOptions"
                :disabled="loanDisabled"
                v-model="loanIdValue"
            />
        </div>

        <!--Settlement Statements-->
        <div
            v-rq-tooltip.top.hover.html
            :title="settlementStatementTooltip"
            class="col col-auto form-group">
            <label for="drp_settlement_statements">Settlement Statement</label>
            <dx-select-box
                :element-attr="{ id:'drp_settlement_statements', automation_id:'drp_settlement_statements' }"
                class="form-control"
                value-expr="id"
                display-expr="name"
                :width="200"
                :items="settlementStatements"
                :disabled="settlementStatementDisabled"
                v-model="cssMainIdValue"
            />
        </div>

        <!--Invoice-->
        <div
            v-rq-tooltip.top.hover.html
            :title="invoiceTooltip"
            class="col col-auto form-group">
            <label for="drp_invoice">Invoice</label>
            <dx-select-box
                :element-attr="{ id:'drp_invoice', automation_id:'drp_invoice' }"
                class="form-control"
                value-expr="id"
                display-expr="name"
                :width="140"
                :items="invoices"
                :disabled="invoiceDisabled"
                v-model="invoiceIdValue"
            />
        </div>
    </div>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import { ORDER_ACTIONS } from "@/store/actions";

    const computedSync = propName => ({
        get() { return _.getNumber(this, propName, null); },
        set(val) { this.$emit(`update:${propName}`, val); }
    });
    const mapLookupNumberKeys = items => _.map(items, item => ({ id: _.parseNumber(item.id), name: item.name }));
    const mapLookupNumberKeysWithIndex = items => _.map(items, (item, index) => ({ id: _.parseNumber(item.id), name: `${index + 1} of ${items.length} : ${item.name}` }));

    export default {
        name: "DocGenMergeOptions",

        props: {
            loanId: { type: Number, default: null },
            cssMainId: { type: Number, default: null },
            invoiceId: { type: Number, default: null }
        },

        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                invoices: state => mapLookupNumberKeysWithIndex(state.orders.orderLookups.invoices),
                settlementStatements: state => mapLookupNumberKeys(state.orders.orderLookups.settlementStatements)
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            loanOptions () { return this.lookupHelpers.getLoanOptions(); },

            loanDisabled() { return _.isEmpty(this.loanOptions); },
            settlementStatementDisabled() { return this.settlementStatements.length <= 1; },
            invoiceDisabled() { return this.invoices.length <= 1; },

            loanTooltip() { return this.loanDisabled ? "No loans found<br/>on the current file" : ""; },
            settlementStatementTooltip() {
                return this.settlementStatementDisabled ?
                    `${this.settlementStatements.length == 0 ? 'No' : this.settlementStatements.length} settlement statement${this.settlementStatements.length === 0 ? 's' : ''}<br/>found on the current file`
                    : "";
            },
            invoiceTooltip() {
                return this.invoiceDisabled
                    ? `${this.invoices.length == 0 ? 'No' : this.invoices.length} invoice${this.invoices.length === 0 ? 's' : ''} found<br/>on the current file`
                    : "";
            },

            loanIdValue: computedSync("loanId"),
            cssMainIdValue: computedSync("cssMainId"),
            invoiceIdValue: computedSync("invoiceId")
        },

        watch: {
            orderId:{
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    const self = this;

                    if (self.loanOptions.length > 0) self.loanIdValue = self.loanOptions[0].id;

                    self.refreshLookups();
                },
                immediate: true
            }
        },

        methods: {
            refreshLookups() {
                const self = this;

                let promise = self.$store.dispatch(ORDER_ACTIONS.REFRESH_ORDER_LOOKUPS)

                self.$rqBusy
                    .wait(promise)
                    .then(() => {
                        if (self.settlementStatements.length > 0) self.cssMainIdValue = self.settlementStatements[0].id;
                        if (self.invoices.length > 0) self.invoiceIdValue = self.invoices[0].id;
                    });
            }
        }
    };
</script>
