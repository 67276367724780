<template>
    <div class="btn-group-chars" role="group" aria-label="Special Characters">
        <div v-for="(char, index) in symbolChars"
            :key="index"
            :class="{ 'btn-char':true, 'active': char.code === selectedCharCode && char.font === selectedCharFont }"
            @click="onCharClick(char)">
            <span
            :class="{'custom-char': char.font !== 'Symbol' }"
            :style="{fontFamily: char.font}">{{String.fromCharCode(char.code)}}</span>
        </div>
        <div class="btn-char-bookend"></div>
    </div>
</template>
<script>
    export default {
        name: "SpecialCharSelection",
        props: {
            modelValue: { type: String, default: String.fromCharCode(32) },
            charCode: { type: Number, default: 32 },
            charFont:{type: String, default: "Symbol"},
            customChars: {type: Array, default: () => []}
        },
        data () {
            return {
                selectedCharCode: this.modelValue.charCodeAt(0),
                selectedCharValue: this.modelValue,
                selectedCharFont: this.charFont
            };
        },
        computed: {
            symbolChars() {
                let result = [];
                for(let i = 32; i < 255; i++) {
                    if(i >= 127 && i <= 160) continue;
                    result.push({code: i, font: "Symbol"});
                }
                result.push(...this.customChars)
                return result;
            }
        },
        methods: {
            onCharClick({code, font}){
                this.selectedCharCode = code;
                this.selectedCharFont = font;
                this.selectedCharValue = String.fromCharCode(code);
                this.$emit("update:charCode", this.selectedCharCode);
                this.$emit("update:charFont", this.selectedCharValue);
                this.$emit("update:modelValue", this.selectedCharValue);
            }
        },
    }
</script>