<template>
    <div class="rq-container">
        <rq-banner
            icon="fas fa-exclamation-circle"
            variant="error"
            message="Please correct the highlighted errors on screen to continue."
            :visible="showBanner && !isValid"
            dismissable
        />
        <div class="prompt-instruction">Some of the clauses you selected contain prompts.  Fill these out to continue.</div>
        <template v-for="(stdLang, idx) in promptInfo.prompt.standardLanguageList">
            <rq-page-section v-if="(stdLang.prompts && stdLang.prompts.length > 0)" :key="idx" :title="stdLang.description" header-size="sm">
                <prompt-field v-for="(stdPromptInfo, index) in stdLang.prompts"
                    :promptTextWidth="getPromptTextWidth(stdLang.prompts)"
                    :key="stdPromptInfo.clientKey"
                    :prompt="stdPromptInfo.prompt"
                    :set-initial-focus="index===0"
                    show-label
                />
            </rq-page-section>
        </template>
    </div>
</template>
<script>
import { DocumentPromptInfo } from '../../models';
import PromptField from './PromptField';

export default {
    components: { PromptField },

    props: {
        promptInfo: { type: Object, default: () => new DocumentPromptInfo() }
    },

    data() {
        return {
            showBanner: false,
            isValid: false
        }
    },

    methods: {
        getPromptTextWidth(prompts) {
            let resultWidth = 250;
            let inputPrompts = _.filter(prompts, p => p.prompt.promptDataType < 6);
            let promptTextList = _.map(inputPrompts, p => p.prompt.promptText);
            return this.calculateTextWidth(promptTextList);
        },

        calculateTextWidth(textList) {
            let resultWidth = 200;
            _.forEach(textList, text => {
                let approxWidth = text.length * 6.5;
                if (approxWidth > resultWidth) { resultWidth = approxWidth; }
                if (resultWidth > 350) {
                    resultWidth = 350;
                    return false;
                }
            });
            return resultWidth;
        },

        validate() {
            const self = this;
            self.isValid = true;
            self.showBanner = true;
            self.isValid = self.promptInfo.validate(true);
            return self.isValid;
        }
    }
}
</script>

