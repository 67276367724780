<template>
    <div class="rq-stmt-preview-container">
        <div class="rq-title">Statement Result</div>
        <div class="rq-stmt-preview">
            <div class="preview-text">
                {{statement}}
            </div>
        </div>
    </div>
</template>
<script>
    export default{
        name: "StatementPreview",
        props: { statement: { type: String, default:null } }
    }
</script>