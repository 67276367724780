<template>
    <div id="standard-language-selection" :class="{'standard-language-selection':true, 'dialog-content':dialogMode }">
        <h6 v-if="promptInfo.promptText">{{promptInfo.promptText}}</h6>
        <div class="row mb-2 min-h-20">
            <div class="col-lg-6">
                <span class="rq-title rq-section-title">CLAUSES</span>
            </div>
            <div class="col-lg-6">
                <span class="rq-title rq-section-title">CLAUSE QUEUE</span>
            </div>
        </div>
        <div class="row grid-row">
            <div class="col col-lg-5">
                <div class="card grid-card">
                    <div class="card-block">
                        <div class="grid-toolbar">
                            <div class="row">
                                <div class="col-4 form-group mb-0">
                                    <dx-select-box
                                        ref="categoryFilter"
                                        :input-attr="{ automation_id: 'drp_category' }"
                                        :element-attr="{ class: 'form-control form-control-sm' }"
                                        :items="categories"
                                        :show-clear-button="true"
                                        placeholder="Filter by category..."
                                        display-expr="description"
                                        value-expr="standardLanguageCategoryID"
                                        v-model="categoryFilter"
                                        @value-changed="onCategoryFilterChanged"
                                    />
                                </div>
                                <div class="col-4 form-group mb-0">
                                    <rq-search-input-group
                                        automation_id="txt_clause_search"
                                        placeholder="Search..."
                                        size="sm"
                                        input-css-class="rq-placeholder-visible"
                                        v-model="searchText"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="grid-container">
                            <rqdx-data-grid ref="availItemGrid"
                                :automation_id="getAutomationId('dg_available')"
                                :dataSource="availItemGridDataSource"
                                :config="allItemsGridConfig"
                                @contentReady="onGridContentReady('available', $event)"
                                @rowClick="onAvailableGridRowClick"
                                @rowDoubleClick="onGridRowDoubleClick('available', $event)"
                                @keyDown="onGridKeyDown('available', $event)"
                                @selectionChanged="onRowSelectionChanged('available', $event)"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-1">
                <div class="clause-list-actions">
                    <span v-rq-tooltip.top.hover title="Add All to List">
                        <b-btn variant="theme" class="btn-action" :disabled="noAvailableClause" @click="onAddAllClick"><FontAwesomeIcon icon="fas fa-angle-double-right" /></b-btn>
                    </span>
                    <span v-rq-tooltip.top.hover.html :title="`Add to List${noAvailableClauseSelected ? '<br />(none selected)' : ''}`">
                        <b-btn variant="theme" class="btn-action" :disabled="noAvailableClauseSelected" @click="onAddSelectedClick"><FontAwesomeIcon icon="fas fa-angle-right" /></b-btn>
                    </span>
                    <span v-rq-tooltip.top.hover.html :title="`Remove From Added List${noAddedClauseSelected ? '<br />(none selected)' : ''}`">
                        <b-btn variant="theme" class="btn-action" :disabled="noAddedClauseSelected" @click="onRemoveSelectedClick"><FontAwesomeIcon icon="fas fa-angle-left" /></b-btn>
                    </span>
                    <span v-rq-tooltip.top.hover title="Remove All From Added List">
                        <b-btn variant="theme" class="btn-action" :disabled="noAddedClause" @click="onRemoveAllClick"><FontAwesomeIcon icon="fas fa-angle-double-left" /></b-btn>
                    </span>
                </div>
            </div>
            <div class="col col-lg-5">
                <div class="card grid-card">
                    <div class="card-block">
                        <div class="grid-toolbar">
                            <div class="row">
                                <div class="col-auto ms-auto">
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                        <div class="grid-container">
                            <rqdx-data-grid ref="addedItemGrid"
                                :automation_id="getAutomationId('dg_added')"
                                :dataSource="addedItemGridData"
                                :config="addedItemsGridConfig"
                                @contentReady="onGridContentReady('added',$event)"
                                @selectionChanged="onRowSelectionChanged('added', $event)"
                                @rowDoubleClick="onGridRowDoubleClick('added',$event)"
                                @keyDown="onGridKeyDown('added', $event)"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-1">
                <div class="clause-list-actions">
                    <span v-rq-tooltip.top.hover title="Move Selected Up">
                        <b-btn variant="theme" class="btn-action" :disabled="moveUpDisabled" @click="onReorderQueue(-1)"><FontAwesomeIcon icon="fas fa-angle-up" /></b-btn>
                    </span>
                    <span v-rq-tooltip.top.hover title="Move Selected Down">
                        <b-btn variant="theme" class="btn-action" :disabled="moveDownDisabled" @click="onReorderQueue(1)"><FontAwesomeIcon icon="fas fa-angle-down" /></b-btn>
                    </span>
                </div>
            </div>
        </div>
        <div class="row mt-1 min-h-20">
            <div class="col-lg-12 d-flex justify-content-end align-items-center">
                <span class="text-muted me-3">Lines Between Clauses</span>
                <rq-radio-group
                    id="rdo-lines-between-clauses"
                    automation_id="rdo-lines-between-clauses"
                    name="rdo-lines-between-clauses"
                    v-model="lineCountValue"
                    :options="lineCountOptions"
                    inline
                />
            </div>
        </div>
        <rq-rtf-display
            id="clause-preview"
            ref="clausePreview"
            class="stand-lang-preview"
            :placeholder="previewPlaceholderText"
            :min-height="150"
            v-model="previewHtml"
            borderless
            no-hover
        />
        <notes-popover
                container="#standard-language-selection"
                :popover="notesPopover"
                v-model:visible="notesPopover.visible"
            />
    </div>

</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { DOCUMENT_ACTIONS } from "@/store/actions";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import NotesPopover from "@/shared/components/rq/NotesPopover";
    import { DocumentPromptInfo, DocumentPrompt, StandardLanguageModel, StandardLanguageHeaderModel } from "../../models";
    import { SearchRequest, SortOption } from "@/shared/models/models";
    import DxFilterBuilder from "@/shared/utilities/DxFilterBuilder";
    import GridInvokerMixin from "@/shared/mixins/GridInvokerMixin";

    const GRIDNAME = {
        Available: "available",
        Added: "added"
    };

    export default {
        name: "StandardLanguageSelection",

        components: { NotesPopover },

        mixins: [
            GridInvokerMixin({
                availItemGrid: "availItemGrid",
                addedItemGrid: "addedItemGrid"
            })
        ],

        props: {
            promptInfo: { default: function () { return new DocumentPromptInfo(); } },
            dialogMode: { type: Boolean, default:false },
            allowDuplicates: { type: Boolean, default:false },
            standardLanguageSelected: { type: Array, default: () => { return [] } },
            categoryId: { type: Number, default: -1 },
            categoryList: { type: Array, default: () => [] },
            excludePackages: { type: Boolean, default: false },
            linesBetweenClauses: { type: Number, default: null },
        },

        data() {
            const self = this;
            return {
                availItemGridDataSource: [],
                addedItemGridData: [],
                defaultItem: -1,
                lineCountValue: null,
                availItemGridReady: false,
                addedItemGridReady: false,
                subPromptsExist: false,
                searchText: "",
                expandPackagesOnLoad: false,
                categoryFilter: self.categoryId,
                notesPopover: {
                    visible: false,
                    target: null,
                    notes: null,
                    description: null
                },
                previewingId: 0,
                previewHtml: null,
                noAddedClauseSelected: true,
                noAvailableClause: true,
                noAvailableClauseSelected: true,
                addedClauseSelectedIndexList: [],
                categories: [],
                categoriesLoaded: false,
                lineCountOptions: _.map(["NONE",1,2], value => ({ text: value.toString(), value }))
            };
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                orderId: state => state.orders.orderId,
                currentUser: state => state.authentication.session.user
            }),
            gridHeight() { return 250; },
            noSelection(){ return this.previewingId === 0; },
            previewContentEmpty(){ return !this.noSelection && _.isEmpty(this.previewHtml); },
            previewPlaceholderText() { return this.noSelection ? "Select a clause to preview." : "No Content"; },
            regionId() { return _.get(this, "$store.state.orders.orderSummary.regionID", null) || this.currentUser.regionID; },
            moveUpDisabled() { return this.noAddedClauseSelected || _.includes(this.addedClauseSelectedIndexList, 0); },
            moveDownDisabled() { return this.noAddedClauseSelected || _.includes(this.addedClauseSelectedIndexList, this.addedItemGridData.length - 1); },
            workflowTasks() { return this.lookupHelpers.getLookupItems(this.lookupItems.WORKFLOW_TASKS, 1); },
            noAddedClause() { return this.addedItemGridData.length === 0; }
        },

        watch: {
            lineCountValue: {
                handler: function(newValue, oldValue) {
                    if(newValue === oldValue || this.promptInfo.promptValue === newValue) return;
                    this.promptInfo.promptValue = newValue?.toString();
                    this.$emit("update:linesBetweenClauses", newValue);
                }
            },

            searchText(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.refreshGridDebounced(GRIDNAME.Available);
            }
        },

        created(){
            const self = this;
            self.initGrids();
            self.lineCountValue = self.getInitialLineCountValue();
        },

        mounted() {
            _.invoke(this, "$refs.categoryFilter.instance.focus");
        },

        beforeUnmount(){
            this.$rqBusy.clearWaits();
        },

        methods: {
            onGridContentReady(gridName, e) {
                const self = this;
                if (gridName === GRIDNAME.Available) {
                    if(!self.categoriesLoaded) return;
                    if (self.expandPackagesOnLoad) {
                        self.expandPackagesOnLoad = false;
                        _.delay(function () {
                            self.invokeAvailItemGridMethod("updateDimensions");
                         }, 500);
                    }
                    else
                        self.invokeAvailItemGridMethod("updateDimensions");
                }
                if (gridName === GRIDNAME.Added) {
                    self.invokeAddedItemGridMethod("updateDimensions");
                }
                self.updateGridSelectionInfo(gridName);
            },

            onGridRowDoubleClick(gridName, e){
                const self = this;
                let rowData = e.data;
                if (gridName === GRIDNAME.Available){
                    self.addGridItem(rowData, true);
                }
                if (gridName === GRIDNAME.Added){
                    self.removeGridItem(rowData, true);
                }
            },

            onAvailableGridRowClick(e) {
                const self = this;
                if (e.rowType !== "data") return;

                let rowData = e.data;

                //Timeout function that waits 200 milleseconds to avoid rqBusy
                //in updateContentPreview from blocking double click on row
                setTimeout(function(){
                    if(rowData && !rowData.isPackage) {
                        self.updateContentPreview(rowData);
                    } else {
                        self.updateContentPreview();
                    }
                }, 200);
            },

            onRowSelectionChanged(gridName, e) {
                this.updateGridSelectionInfo(gridName);
            },

            onAddSelectedClick(e) {
                this.addSelectedItems();
            },
            onAddAllClick() {
                this.addAllItems();
            },

            onReorderQueue(diff) {
                const self = this;
                if(self.moveUpDisabled && self.moveDownDisabled) return;

                let selectedRowKeys = self.invokeAddedItemGridMethod("getSelectedRowKeys");
                let selectedIndexes = self.getAddedItemGridActiveRowIndexes(selectedRowKeys);

                let tempList = _.cloneDeep(self.addedItemGridData);
                if(diff == -1 && !self.moveUpDisabled)
                {
                    //move up
                    selectedIndexes = _.orderBy(selectedIndexes);
                    selectedIndexes.forEach((itemIndex) => {
                        tempList.splice(itemIndex - 1, 0, self.addedItemGridData[itemIndex]);
                        tempList.splice(itemIndex + 1, 1);
                    });
                }
                else if(diff == 1 && !self.moveDownDisabled)
                {
                    //move down
                    selectedIndexes = _.orderBy(selectedIndexes, [], ["desc"]);
                    selectedIndexes.forEach((itemIndex) => {
                        tempList.splice(itemIndex, 1);
                        tempList.splice(itemIndex + 1, 0, self.addedItemGridData[itemIndex]);
                    });
                }
                self.addedItemGridData = tempList;
                self.$emit("update:standardLanguageSelected", self.addedItemGridData);
            },

            onGridKeyDown(gridName, e) {
                const self = this;
                if (gridName === GRIDNAME.Available){
                    if(e.event.key !== "Enter") return;
                    self.addSelectedItems();
                    self.clearGridSelection(gridName);
                }
                if (gridName === GRIDNAME.Added){
                    if(e.event.key !== "Delete") return;
                    self.removeSelectedItems();
                    self.clearGridSelection(gridName);
                }
            },

            onCategoryFilterChanged(e) {
                if(_.isNil(e.event)) return;
                this.refreshGrid(GRIDNAME.Available);
            },

            updateGridSelectionInfo(gridName) {
                const self = this;
                if (gridName === GRIDNAME.Available) {
                    let selectedRowsData = self.invokeAvailItemGridMethod("getSelectedRowsData") || [];
                    self.noAvailableClauseSelected = selectedRowsData.length === 0;
                }
                if (gridName === GRIDNAME.Added) {
                    let selectedRowKeys = self.invokeAddedItemGridMethod("getSelectedRowKeys") || [];
                    self.noAddedClauseSelected = selectedRowKeys.length === 0;
                    self.addedClauseSelectedIndexList = self.getAddedItemGridActiveRowIndexes(selectedRowKeys);
                }
            },
            getAddedItemGridActiveRowIndexes(selectedRowKeys) {
                // this should be the way it is done, but 0 index always returns null instead of 0.  The latest devextreme does not fix it either as of 23.2.3
                // _.map(selectedRowKeys, key => self.invokeAddedItemGridMethod("getRowIndexByKey", key)
                let rows = this.invokeAddedItemGridMethod("getVisibleRows");
                return _.map(selectedRowKeys, key => _.find(rows, {"key": key})?.rowIndex)
            },

            getInitialLineCountValue() {
                let defaultLineCount = _.parseNumber(_.isNil(this.linesBetweenClauses)
                    ? this.promptInfo?.prompt?.defaultLineCount
                    : this.linesBetweenClauses, 1);
                return _.getNumber(this, "promptInfo.promptValue", defaultLineCount);
            },

            initGrids() {
                const self = this;
                let gridConfig = self.createGridConfig();

                self.allItemsGridConfig = _.cloneDeep(gridConfig);
                self.addedItemsGridConfig = _.cloneDeep(gridConfig);

                self.addedItemsGridConfig.remoteOperations = { paging: false, sorting: false },
                self.addedItemsGridConfig.sorting = { mode: "none" },

                self.addedItemsGridConfig.summary = {
                    totalItems: [{
                        showInColumn: "description",
                        summaryType: "custom",
                        alignment: "left",
                        displayFormat: "CLAUSES IN QUEUE"
                    }, {
                        showInColumn: "standardLanguageCategoryDescription",
                        summaryType: "count",
                        alignment: "right",
                        displayFormat: "{0}"
                    }],
                    calculateCustomSummary(options) {
                        return;
                    }
                };
                self.addedItemsGridConfig.keyExpr = "id";
                self.availItemGridDataSource = {
                    key: "id",
                    load: self.fetchAvailItemGridData
                };

                self.fetchCategories();
                self.setPreviouslySavedValues();
            },

            fetchCategories(){
                const self = this;
                if(!_.isEmpty(self.categoryList)) {
                    self.setCategories(_.cloneDeep(self.categoryList));
                    return Promise.resolve(true);
                }
                let apiPromise = self.$store.dispatch(DOCUMENT_ACTIONS.GET_STANDARD_LANGUAGE_CATEGORIES, false);
                return self.$rqBusy.wait(apiPromise)
                    .then(self.setCategories);
            },

            setCategories(categoryData) {
                const self = this;
                self.categories = categoryData;
                if(self.categoryFilter <= 0) {
                    let defaultCategory = null;

                    if(self.promptInfo?.prompt?.defaultValue)
                        defaultCategory = _.find(self.categories, {"category": self.promptInfo.prompt.defaultValue});

                    if(!defaultCategory)
                        defaultCategory = _.find(self.categories, c => c.isDefault || c.isDefaultCategory);
                         
                    self.categoryFilter = _.getNumber(defaultCategory, "standardLanguageCategoryID", -1);
                }
                if(!self.categoriesLoaded) {
                    self.categoriesLoaded = true;
                    self.refreshGrid(GRIDNAME.Available);
                }
                return true;
            },
            setPreviouslySavedValues() {
                if(!_.isEmpty(this.promptInfo?.prompt?.standardLanguageList))
                    this.addMultipleItems(_.map(this.promptInfo.prompt.standardLanguageList, sl => new StandardLanguageHeaderModel({id: sl.standardLanguageID, ...sl})), true)
            },
            getRequestFilter(loadOptions) {
                const self = this;

                let filterBuilder = new DxFilterBuilder(loadOptions);

                if(_.parseNumber(self.categoryFilter, 0) > 0)
                    filterBuilder.append(["StandardLanguageCategoryID","=",self.categoryFilter]);

                if(self.excludePackages)
                    filterBuilder.append(["IsPackage", "=", false]);

                return filterBuilder.filterExpression;
            },

            fetchAvailItemGridData(loadOptions) {
                const self = this;

                if(!self.categoriesLoaded) return Promise.resolve({ data: [], totalCount: 0 });

                let availableCategories = _.join(_.map(self.categories, "standardLanguageCategoryID"), ",");
                // console.log("Available Categories: %s", availableCategories);
                var request = new SearchRequest({
                    searchTerm: self.searchText,
                    parameters: {
                        regionId: self.regionId,
                        availableCategories
                    }
                });
                request.parseLoadOptions(loadOptions);
                request.removeSorts("id");
                request.filter = self.getRequestFilter(loadOptions);

                let apiPromise = self.$api.DocumentsApi.getStandardLanguages(request);
                return self.$rqBusy.wait(apiPromise)
                    .then(response => {
                        self.noAvailableClause = response.totalRecords === 0;
                        return {
                            data: _.map(response.results, sl => new StandardLanguageHeaderModel(sl)),
                            totalCount: response.totalRecords
                        };
                    });
            },

            addGridItem(rowData, refreshOnComplete = false) {
                const self = this;

                let hasDuplicates = !self.allowDuplicates
                    && _.some(self.addedItemGridData, r =>
                        r.id === rowData.id &&
                        r.isPackage === rowData.isPackage);

                if(hasDuplicates) return;

                self.addedItemGridData.push(rowData);

                self.$emit("update:standardLanguageSelected", self.addedItemGridData);

                if (refreshOnComplete)
                {
                    self.invokeAddedItemGridMethod("refresh");
                }
            },

            addMultipleItems(selectedRows, maintainOrder = false) {
                const self = this;
                let sortedRows = maintainOrder ? selectedRows :_.orderBy(selectedRows, ["id", "standardLanguageCategoryID"]);

                if(!self.allowDuplicates) {
                    sortedRows = _.filter(sortedRows, (x) => {
                        return !_.some(self.addedItemGridData, y => y.id === x.id)
                    });
                }

                if(sortedRows.length > 0) {
                    this.addedItemGridData = this.addedItemGridData.concat(sortedRows);
                    this.$emit("update:standardLanguageSelected", this.addedItemGridData);
                    self.clearGridSelection(GRIDNAME.Available);
                }
            },

            addSelectedItems() {
                const self = this;
                let selectedRows = self.invokeAvailItemGridMethod("getSelectedRowsData") || [];
                self.addMultipleItems(selectedRows);
            },

            addAllItems() {
                const self = this;
                self.$dialog.confirm("Confirm Add All", "Are you sure?  This will add all Available Clauses.", () => {
                    let ds = self.invokeAvailItemGridMethod("getDataSource");
                    let items = ds?._items;
                    self.addMultipleItems(items)
                });
            },

            onRemoveSelectedClick(e) {
                this.removeSelectedItems();
                this.clearGridSelection(GRIDNAME.Added)
            },

            onRemoveAllClick() {
                this.$dialog.confirm("Confirm Remove All", "Are you sure?  This will remove all Added Clauses.", () => {
                    let selectedRowsData = _.clone(_.uniq(this.addedItemGridData));
                    this.removeGridItems(selectedRowsData);
                    this.addedItemGridData = [];
                    this.refreshGrid(GRIDNAME.Added);
                });
                this.clearGridSelection(GRIDNAME.Added)
            },

            removeGridItem(gridItem, refreshOnComplete = false) {
                const self = this;

                const removePredicate = item => (item.id === gridItem.id && item.isPackage === gridItem.isPackage);

                //remove grid items
                _.remove(self.addedItemGridData, removePredicate);

                self.$emit("update:standardLanguageSelected", self.addedItemGridData);

                if (refreshOnComplete)
                    self.invokeAddedItemGridMethod("refresh");
            },

            removeGridItems(rowDataList) {
               const self = this;

               _.forEach(rowDataList, row => self.removeGridItem(row));
               self.refreshGrid(GRIDNAME.Added);
           },

            removeSelectedItems() {
                let selectedRowsData = _.clone(_.uniq(this.invokeAddedItemGridMethod("getSelectedRowsData")));
                this.removeGridItems(selectedRowsData);
            },

            updateContentPreview(item) {
                const self = this;
                if(_.isNil(item)){
                    self.setContentPreview();
                    return;
                }
                item = item || {};

                if(item.html) {
                    self.setContentPreview(item.id, item.html);
                }
                else if(item.id) {
                    let docApiPromise = self.$api.DocumentsApi.getStandardLanguageContent(item.id);
                    self.$rqBusy.wait(docApiPromise, { targetElement: self.$refs.clausePreview.$el })
                        .then(result => {
                            item.html = result;
                            self.setContentPreview(item.id, result);
                            return result;
                        });
                }
            },

            setContentPreview(id, content){
                const self = this;
                self.previewingId = id || 0;
                self.previewHtml = content || "";
            },

            getAutomationId(prefix) {
                return _.uniqueId(prefix);
            },

            updateDimensions(gridName) {
                if (!gridName || gridName === GRIDNAME.Available) {
                    this.invokeAvailItemGridMethod("option", "height", this.gridHeight);
                    this.invokeAvailItemGridMethod("updateDimensions");
                }
                if (!gridName || gridName === GRIDNAME.Added) {
                    this.invokeAddedtemGridMethod("option", "height", this.gridHeight);
                    this.invokeAddedItemGridMethod("updateDimensions");
                }
            },

            updateNotesPopover(notes=null, description=null, target=null) {
                const self = this;
                let newID = _.get(target, "id") || null;
                let lastID = _.get(self.notesPopover, "target") || null;
                let isNewItem = !_.isEqual(newID, lastID);
                let notesPopover = _.clone(self.notesPopover);
                if (isNewItem) {
                    if (!_.isNil(lastID)) {
                        notesPopover.visible = false;
                    }
                    notesPopover.target = newID;
                    notesPopover.notes = notes;
                    notesPopover.description = description;
                    notesPopover.visible = true;
                } else {
                    notesPopover.visible = !notesPopover.visible;
                }
                self.$nextTick(() => {
                    self.notesPopover = notesPopover;
                });
            },

            clearGridSelection(gridName, ignorePackageID=null) {
                const self = this;
                if (!gridName || gridName === GRIDNAME.Available) {
                    self.invokeAvailItemGridMethod("clearSelection");
                }
                if (!gridName || gridName === GRIDNAME.Added) {
                    self.invokeAddedItemGridMethod("clearSelection");
                }
            },

            refreshGrid(gridName) {
                if (!gridName || gridName === GRIDNAME.Available)
                    this.invokeAvailItemGridMethod("refresh");
                if (!gridName || gridName === GRIDNAME.Added)
                    this.invokeAddedItemGridMethod("refresh");
            },

            refreshGridDebounced: _.debounce(function(gridName) {
                this.refreshGrid(gridName);
            }, 300),

            createGridConfig() {
                const self = this;
                return {
                    columns: [
                        {
                            dataField: "description",
                            dataType: "string",
                            cellTemplate: DxGridUtils.standardLanguageDescriptionCellTemplate({
                                idAppend: "std-language-notes-popup-info-",
                                handlers:{
                                    mouseover(cellElement, cellInfo, e) {
                                        self.updateNotesPopover(cellInfo.data.notes, cellInfo.data.description, e.target);
                                    },
                                    mouseout(cellElement, cellInfo, e) {
                                        self.updateNotesPopover();
                                    },
                                    click(cellElement, cellInfo, e) {
                                        e.stopPropagation();
                                    },
                                }
                            }),
                            // cellTemplate: function (cellElement, cellInfo) {
                            //     let rowData = cellInfo.data;
                            //     let rowKey = cellInfo.key;
                            //     let classNames = ["clause-description"];
                            //     let iconClass = "far fa-file";

                            //     if(rowData.isPackage) {
                            //         classNames.push("pkg-description");
                            //         iconClass = "fas fa-folder";
                            //     }
                            //     else {
                            //         iconClass = "fal fa-file-invoice";
                            //     }

                            //     let $clauseText = $("<div>").addClass("clause-text").text(cellInfo.text);
                            //     let $clauseIcon = $("<div>").addClass("clause-icon").append($("<i>").addClass(iconClass));

                            //     $("<div>")
                            //         .addClass(classNames)
                            //         .append($clauseIcon)
                            //         .append($clauseText)
                            //         .appendTo(cellElement);
                            // }
                        },
                        { dataField: "code" },
                        { dataField: "standardLanguageCategoryDescription", caption:"Category" },
                        {
                            dataField: "workflowTaskName",
                            caption: "Associated Task"
                        }
                    ],
                    focusedRowEnabled: true,
                    height: "100%",
                    searchPanel: { visible: false },
                    selection: { mode: "multiple", showCheckBoxesMode: "never" },
                    remoteOperations: { paging: true, sorting: true },
                    paging: { enabled: true, pageIndex: 0, pageSize: 50 },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,150], showInfo: true},
                    hoverStateEnabled: true,
                    rowAlternationEnabled: true,
                    // scrolling: { showScrollbar: "always", useNative: false },
                    showBorders: false
                };
            }
        }
    };
</script>
