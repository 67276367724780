<template>
    <div :class="{
        'rq-container content-wrapper': showNoData,
        'rq-dialog-content': !showNoData
    }">
        <rq-no-data
            v-if="showNoData"
            class="mx-4"
            :text="noDataText"
        />
        <div v-else-if="documentsLoaded" class="row mt-2">
            <div v-show="withinOrderContext" class="col-12">
                <div class="row">
                    <div class="col col-9 form-group">
                        <label for="drp_documents">Document:</label>
                        <dx-select-box
                            ref="docSelectBox"
                            :input-attr="$utils.idAttrs('drp_documents')"
                            value-expr="fileScanDocumentID"
                            display-expr="description"
                            :items="documents"
                            v-model="selectedDocumentId"
                            @selection-changed="onDocumentSelectionChanged"
                        />
                    </div>
                    <div class="col col-3 pt-1">
                        <button
                            type="button"
                            class="btn btn-primary btn-block mt-3"
                            @click="onBrowseDocuments">Browse</button>
                    </div>
                </div>
            </div>
            <div class="col-12 form-group">
                <label for="txt_hyperlink_text">Text to Display</label>
                <input
                    id="txt_hyperlink_text"
                    automation_id="txt_hyperlink_text"
                    type="text"
                    class="form-control"
                    v-model="hyperlinkText"
                >
            </div>
            <div class="col-12 form-group">
                <label for="txt_hyperlink_tooltip">Tooltip</label>
                <input
                    id="txt_hyperlink_tooltip"
                    automation_id="txt_hyperlink_tooltip"
                    type="text"
                    class="form-control"
                    v-model="hyperlinkTooltip">
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from "vuex";
    import DocumentSelection from "@document-mgmt/components/DocumentSelection";
    import { FileScanDocumentDto }  from "@document-mgmt/models";

    const URL_STORAGE_KEY = "rq_document_hyperlink_urls";

    export default {
        name: "DocHyperlinkForm",
        props: {
            text: { type: String, default: "" },
            url: { type: String, default: "" },
            tooltip: { type: String, default: "" },
        },
        data () {
            return {
                hyperlinkText: this.text || "",
                hyperlinkUrl: this.url || "",
                hyperlinkTooltip: this.tooltip || "",
                documents: [],
                selectedDocumentId: null,
                selectedDocument: {},
                documentsLoaded: false,
                replaceText: _.isEmpty(this.text)
            };
        },
        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
            }),
            withinOrderContext() { return _.parseNumber(this.orderId, 0) > 0; },
            textIsEmpty() { return _.isEmpty(_.trim(this.hyperlinkText)); },
            textIsDocument() { return this.hyperlinkText === this.selectedDocument?.description; },
            urlIsEmpty() { return _.isEmpty(_.trim(this.hyperlinkUrl)); },
            noDocumentsAvailable() { return _.isEmpty(this.documents); },
            isValid() { return _.parseNumber(this.selectedDocumentId, 0) > 0; },
            showNoData() { return !this.withinOrderContext || (this.documentsLoaded && this.noDocumentsAvailable); },
            noDataText() {
                return this.withinOrderContext
                    ? this.noDocumentsAvailable ? "No published documents are available on the current file." : ""
                    : "Document links cannot be added outside the context of a file."
            },
            urlInfo() {
                return {
                    text: this.hyperlinkText,
                    url: this.hyperlinkUrl,
                    tooltip: this.hyperlinkTooltip,
                    isValid: this.isValid
                };
            },
        },
        created() {
            this.loadDocuments();
        },
        methods: {
            setUrlInfo({ text="", url="" }={ text: "", url: "" }) {
                if(this.textIsEmpty || this.replaceText) {
                    this.hyperlinkText = text;
                }
                this.hyperlinkUrl = url;
            },

            setSelectedDocument(document) {
                const self = this;
                self.replaceText = self.textIsDocument;
                if(_.isEmpty(document)) {
                    self.selectedDocument = {};
                    self.setUrlInfo();
                    return Promise.resolve(true);
                }

                self.selectedDocument = document;
                return self.getDocumentUrl(document)
                    .then(urlInfo => {
                        self.setUrlInfo(urlInfo);
                        return true;
                    });
            },

            loadDocuments() {
                const self = this;
                let apiPromise = self.$api.FileScanApi.getFileScanDocuments(self.orderId);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.documents = _.map(result, item => new FileScanDocumentDto(item));
                        self.documentsLoaded = true;
                        self.$nextTick(() => {
                            if(_.isEmpty(result)) return;
                            self.focusDocSelectBox();
                        });
                        return self.documents;
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error("Error loading uploaded documents.");
                    });
            },

            getDocumentUrl(document) {
                const self = this;
                let urlInfo = {
                    text: document?.description || "",
                    url: document?.shareUrl || null
                };
                if(!_.isNil(urlInfo.url)) {
                    return Promise.resolve(urlInfo);
                }
                return self.$api.FileScanApi.createShareUrl(document.fileScanDocumentID)
                    .then(document => {
                        self.selectedDocument = document;
                        _.forEach(self.documents, doc => {
                            if(doc.fileScanDocumentID !== document.fileScanDocumentID) return;
                            doc.shareUrl = document.shareUrl;
                        })
                        urlInfo.url = document.shareUrl;
                        return urlInfo;
                    });
            },

            focusDocSelectBox() {
                _.invoke(this, "$refs.docSelectBox.instance.focus");
            },

            onDocumentSelectionChanged(e) {
                if(_.isEmpty(e.selectedItem)) {
                    this.setUrlInfo();
                    return;
                }
                this.setSelectedDocument(e.selectedItem);
            },

            onBrowseDocuments() {
                const self = this;
                const onOk = e => {
                    self.selectedDocumentId = e.component.selectedId;
                    return true;
                };
                const onComponentAction = e => {
                    let action = e.originalEvent;
                    if (action.name !== "double-click" || !action.data) return false;
                    self.selectedDocumentId = action.data.key;
                    return true;
                };
                self.$dialog.open({
                    title: "Published Document Selection",
                    height: 600,
                    width: 800,
                    component: DocumentSelection,
                    props: {
                        documents: self.documents,
                        singleSelection: true
                    },
                    okTitle: "Select",
                    onOk,
                    onComponentAction
                });
            }
        }
    }
</script>