import DocumentsMain from "./DocumentsMain";
import DocumentViewer from "./views/DocumentViewer";
import DocumentGeneration from "./views/DocumentGeneration";
import ViewActions from "@/router/actions/documents";

export default {
    name: "docs",
    path: "documents",
    redirect: "generate",
    component: DocumentsMain,
    meta: {
        entity: "Document",
        label: "Documents",
        include: false
    },
    children: [{
            name: "docs:viewer",
            path: "viewer",
            component: DocumentViewer,
            meta: {
                entity: "Document",
                label: "Preview Documents",
                actions: ViewActions.DOC_VIEWER,
                accessKey: "Documents_ScreenAccess",
                skipSaveOnLeave: true
            }
        },
        {
            name: "docs:generate",
            path: "generate",
            component: DocumentGeneration,
            meta: {
                entity: "Document",
                actions: ViewActions.DOC_GEN,
                label: "Generate Documents",
                accessKey: "Documents_ScreenAccess",
                skipSaveOnLeave: true
            }
        }
    ]
};
