<template>
    <div class="content-wrapper">
        <template v-if="isGenDocs">
            <order-summary />
            <rq-scroll-container scroll-mode="horizontal">
                <router-view></router-view>
            </rq-scroll-container>
        </template>
        <router-view v-else></router-view>
    </div>
</template>

<script>
    import OrderSummary from "@file-shared/components/OrderSummary";
    export default {
        name: "DocumentsMain",
        components: { OrderSummary },
        computed: {
            isGenDocs() { return this.$route.name === "docs:generate"; }
        }
    }
</script>