import { RoleType } from "@/shared/models/enums";
import { TreeViewItemModel } from "@/shared/models/rq-ui";
import { PromptDataType, PromptLookupOptions, PromptDataFormat } from "./models";
export class StatementHelpers {
    static buildPromptValueStatement(prompt) {
        if(!prompt) return "";
        let promptDataType = _.find(PromptLookupOptions.dataType, o => o.id === prompt.dataType);
        let format = "";
        if(prompt.dataType === PromptDataType.Money || prompt.dataType === PromptDataType.Date){
            let option = _.find(PromptLookupOptions.dataFormat, o => o.id === prompt.format);
            if(option){
                format = `~Format=${option.value}`;
            }
        }
        let customFormat = "";
        if(prompt.dataType === PromptDataType.Date && prompt.format === PromptDataFormat.Custom){
            customFormat = `~CustomFormat=${prompt.formatString}`;
        }

        return `[PROMPTVALUE ${prompt.name}~DataType=${promptDataType.value}${format}${customFormat}]`;
    }

    static mapPromptTreeItems(prompts) {
        let globalItem = new TreeViewItemModel({ key:"global", name:"Global" });
        let documentItem = new TreeViewItemModel({ key:"document", name:"Current Document" });

        _.forEach(prompts, prompt => {
            let listItem = new TreeViewItemModel({ name:prompt.name });
            if(prompt.level === 0)
                globalItem.children.push(listItem);
            else
                documentItem.children.push(listItem);
        });

        globalItem.hasChildren = globalItem.children.length > 0;
        globalItem.isExpanded = globalItem.hasChildren;
        documentItem.hasChildren = documentItem.children.length > 0;
        documentItem.isExpanded = documentItem.hasChildren;

        return [ globalItem, documentItem ];
    }

    static getInstanceTypes(fieldItem, allRoles) {
        let instanceTypes = ["Any"];
        if(_.isNil(fieldItem)) return instanceTypes;
        let includeRoles =
            _.startsWith(fieldItem.objectPath, "Order.OrderCompanies") ||
            _.startsWith(fieldItem.objectPath, "Order.OrderContacts");
        if(!includeRoles) return instanceTypes;

        let roles = _.filter(allRoles, r => r.id !== RoleType.Buyer && r.id !== RoleType.Seller)
        let roleNames =  _.map(roles, r => r.name);
        instanceTypes.push(...roleNames);
        return instanceTypes;
    }
}