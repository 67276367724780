import { TreeViewItemModel } from "@/shared/models/rq-ui";

export class MergeFieldTreeItem extends TreeViewItemModel {
    constructor(options) {
        super(options);
        options = options || {};
        this._objectPath = options.objectPath || options.name;
        this.key = this._objectPath;
        this.objectType = options.objectType || "";
        this.objectTypeName = options.objectTypeName || "";
        this.allowMergeLoop = _.parseBool(options.allowMergeLoop);
        this.instanceType = "Any";
        this.instanceIndex = 0;
    }

    get parentKey() {
        return this.key.substr(0, this.key.lastIndexOf(".")) || null;
    }

    get displayName() {
        if(this.instanceIndex < 1 || (this.instanceIndex === 1 && this.instanceType === "Any")) return this.name;
        return `${this.name}<${this.instanceType},${this.instanceIndex}>`;
    }

    get objectPath() {
        if(this.instanceIndex < 1 || (this.instanceIndex === 1 && this.instanceType === "Any")) return this._objectPath;
        return `${this._objectPath}<${this.instanceType},${this.instanceIndex}>`;
    }

    get mergeable() { return !_.isEmpty(this.dataType) || this.allowMergeLoop; }

    get isCustomData() { return _.includes(this.objectPath, "CustomData."); }

    get dataType() {
        let dateExplicit = [
            "CloseDate",
            "EffectiveDate",
            "IssueDate",
            "OwnerPolicyDate",
            "MortgatePolicyDate"
        ];
        let timeExplicit = [
            "CloseTime",
            "EffectiveTime",
            "IssueTime",
            "OwnerPolicyTime",
            "MortgatePolicyTime"
        ];
        if(_.includes(dateExplicit, this.name)) return "Date";
        if(_.includes(timeExplicit, this.name)) return "Time";
        if(this.name === "SignatureBlock") return "Signature Block"; //ShowBulletControls(true);
        switch(this.objectTypeName) {
            case "String":
                return "String";
            case "Int16":
            case "Int32":
            case "Int64":
                return "Integer";
            case "StaticDate":
                return "Date";
            case "DateTime":
            case "DateTimeOffset":
                return "DateTime";
            case "Decimal":
            case "Double":
                return "Decimal";
            case "Boolean":
                return "Boolean";
            case "Byte[]":
            case "Image":
            case "Binary":
                return "Image";
        }
        if(this.allowMergeLoop) return "Collection";
        if(this.isCustomData && !_.isEmpty(this.children)) return "String";
        return "";
    }

    toDataObject() {
        return _.pick(this, ["name", "objectPath", "objectType", "allowMergeLoop", "hasChildren"]);
    }

    static toDataObject(obj) {
        return _.pick(obj, ["name", "objectPath", "objectType", "allowMergeLoop", "hasChildren"]);
    }

    mapChildren(items=[]) {
        this.children = _.isEmpty(items) ? [] : _.map(items, c => new MergeFieldTreeItem(c));
    }

    updateObjectPath(parentPath, resetToDefault=false) {
        if(!_.isEmpty(parentPath)) {
            this._objectPath = `${parentPath}.${this.name}`;
        }
        if(resetToDefault) {
            this.instanceType = "Any";
            this.instanceIndex = 0;
        }
        if(_.isEmpty(this.children)) return;
        _.forEach(this.children, child => child.updateObjectPath(this.objectPath, resetToDefault));
    }

    reset(parentPath) {
        this.updateObjectPath(parentPath, true);
    }
}

export class DocumentWatermarksModel{
    constructor(options){
        this.watermark = new DocumentWatermarkModel(options.watermark);
        this.textItems = _.map(options.textItems, ti => {return new DocumentWatermarkTextModel(ti);});
        this.fonts = options.fonts || [];
        this.sizes = options.sizes || [];
        this.deletedTextItems = [];
    }
}

export class DocumentWatermarkModel{
    constructor(options){
        this.documentWatermarkID = _.parseNumber(options.documentWatermarkID, 0);
        this.documentTemplateID = _.parseNumber(options.documentTemplateID, null);
        this.font = options.font || "Arial";
        this.size = options.size || "Auto";
        this.color = options.color || "#D3D3D3";
        this.horizontal = _.parseBool(options.horizontal, false);
    }
}

export class DocumentWatermarkTextModel{
    constructor(options){
        this.clientKey = _.uniqueId("document-watermark-text-");
        this.documentWatermarkTextID = _.parseNumber(options.documentWatermarkTextID, 0);
        this.documentTemplateID = _.parseNumber(options.documentTemplateID, null);
        this.watermarkText = options.watermarkText || null;
        this.isDefault = _.parseBool(options.isDefault, false);
    }
}

export class ClientDropDownListItem{
    constructor(options){
        this.clientKey = _.uniqueId("client-drop-down-list-item-");
        this.value = options.value || null;
    }
}

export class PromptLookupOptions{
    static get dataType() {
        return [
            {id: 0, name: "String", value: "String"},
            {id: 1, name: "Money", value: "Money"},
            {id: 2, name: "Date", value: "Date"},
            {id: 3, name: "Memo", value: "Memo"},
            {id: 4, name: "Yes/No", value: "YesNo"},
            {id: 5, name: "Drop-Down", value: "DropDown"}
        ];
    }
    static get level(){
        return [
            {id: 0, name: "Global", value: "Global"},
            {id: 1, name: "This Document", value: "ThisDocument"}
        ];
    }
    static get saveOption(){
        return [
            {id: 0, name: "Save Always", value: "SaveAlways"},
            {id: 1, name: "Only If Changes Made", value: "OnlyIfChangesMade"},
            {id: 2, name: "Don't Save", value: "NoSave"},
            {id: 3, name: "Including Blanks", value: "IncludingBlanks"},
            {id: 4, name: "Including Defaults", value: "IncludingDefaults"},
        ];
    }
    static get moneyFormat() {
        let results = [];
        _.forEach(this.dataFormat, f => {
            switch(f.id){
                case 0:
                case 1:
                case 2:
                case 6:
                case 7:
                case 8:
                case 20:
                case 21:
                case 22:
                case 23:
                    results.push(f);
                    break;
                default:
                    break;
            }
        });

        return results;

    }
    static get dateFormat(){
        let results = [];
        _.forEach(this.dataFormat, f => {
            switch(f.id){
                case 3:
                case 4:
                case 5:
                case 6:
                    results.push(f);
                    break;
                default:
                    break;
            }
        });
        return results;
    }
    static get stringFormat() {
        return _.filter(this.dataFormat, f => _.includes([0,25], f.id));
    }
    static get dataFormat(){
        return [
            {id: -2147483648, name: "Unkown", value: "Unkown"},
            {id: 0, name: "None", value: "None"},
            {id: 1, name: "Money-Numeric", value: "MoneyNumeric"},
            {id: 2, name: "Money-Words", value: "MoneyWords"},
            {id: 3, name: "Numeric", value: "Numeric"},
            {id: 4, name: "Words", value: "Words"},
            {id: 5, name: "Words-Formal", value: "WordsFormal"},
            {id: 6, name: "Custom", value: "Custom"},
            {id: 7, name: "Numeric-Words", value: "NumericWords"},
            {id: 8, name: "Words-Numeric", value: "WordsNumeric"},
            {id: 9, name: "Sequence-No", value: "SequenceNo"},
            {id: 10, name: "Money-Numeric-TrailingZero", value: "MoneyNumericTrailingZero"},
            {id: 11, name: "Money-Numeric-BlankIfZero", value: "MoneyNumericBlankIfZero"},
            {id: 12, name: "Numeric-BlankIfZero", value: "NumericBlankIfZero"},
            {id: 13, name: "Numeric-BlankWithUnderscoreIfZero", value: "NumericBlankWithUnderscoreIfZero"},
            {id: 14, name: "Money-Numeric-BlankWithUnderscoreIfZero", value: "MoneyNumericBlankWithUnderscoreIfZero"},
            {id: 15, name: "Percent-Number-BlankWithUnderscoreIfZero", value: "PercentNumberBlankWithUnderscoreIfZero"},
            {id: 16, name: "Money-Numeric-BlankWithWhiteSpaceIfZero", value: "MoneyNumericBlankWithWhiteSpaceIfZero"},
            {id: 17, name: "Percent-Number", value: "PercentNumber"},
            {id: 18, name: "Money-Numeric-ForCDF", value: "MoneyNumericForCDF"},
            {id: 19, name: "Money-Contains-One-Zero", value: "MoneyContainsOneZero"},
            {id: 20, name: "Numeric-No-Decimal", value: "NumericFormatted"},
            {id: 21, name: "Numeric-One-Decimal", value: "NumericFormattedOptionA"},
            {id: 22, name: "Numeric-Two-Decimal", value: "NumericFormattedOptionB"},
            {id: 23, name: "Numeric-Three-Decimal", value: "NumericFormattedOptionC"},
            {id: 24, name: "Barcode", value: "Barcode"},
            {id: 25, name: "Text-Image", value: "TextImage"},
        ];
    }
}

const promptDataType = { String: 0, Money: 1, Date: 2, Memo: 3, YesNo: 4, DropDown: 5 };
export class PromptDataType {
    static get String () {return promptDataType.String;}
    static get Money () {return promptDataType.Money;}
    static get Date () {return promptDataType.Date;}
    static get Memo () {return promptDataType.Memo;}
    static get YesNo () {return promptDataType.YesNo;}
    static get DropDown () {return promptDataType.DropDown;}
}

const promptDataFormat = {
    Unkown: -2147483648,
    None: 0,
    MoneyNumeric: 1,
    MoneyWords: 2,
    Numeric: 3,
    Words: 4,
    WordsFormal: 5,
    Custom: 6,
    NumericWords: 7,
    WordsNumeric: 8,
    SequenceNo: 9,
    MoneyNumericTrailingZero: 10,
    MoneyNumericBlankIfZero: 11,
    NumericBlankIfZero: 12,
    NumericBlankWithUnderscoreIfZero: 13,
    MoneyNumericBlankWithUnderscoreIfZero: 14,
    PercentNumberBlankWithUnderscoreIfZero: 15,
    MoneyNumericBlankWithWhiteSpaceIfZero: 16,
    PercentNumber: 17,
    MoneyNumericForCDF: 18,
    MoneyContainsOneZero: 19,
    NumericFormatted: 20,
    NumericFormattedOptionA: 21,
    NumericFormattedOptionB: 22,
    NumericFormattedOptionC: 23,
    Barcode: 24,
    TextImage: 25
}

export class PromptDataFormat{
    static get Unkown(){return promptDataFormat.Unkown;}
    static get None(){return promptDataFormat.None;}
    static get MoneyNumeric(){return promptDataFormat.MoneyNumeric;}
    static get MoneyWords(){return promptDataFormat.MoneyWords;}
    static get Numeric(){return promptDataFormat.Numeric;}
    static get Words(){return promptDataFormat.Words;}
    static get WordsFormal(){return promptDataFormat.WordsFormal;}
    static get Custom(){return promptDataFormat.Custom;}
    static get NumericWords(){return promptDataFormat.NumericWords;}
    static get WordsNumeric(){return promptDataFormat.WordsNumeric;}
    static get SequenceNo(){return promptDataFormat.SequenceNo;}
    static get MoneyNumericTrailingZero(){return promptDataFormat.MoneyNumericTrailingZero;}
    static get MoneyNumericBlankIfZero(){return promptDataFormat.MoneyNumericBlankIfZero;}
    static get NumericBlankIfZero(){return promptDataFormat.NumericBlankIfZero;}
    static get NumericBlankWithUnderscoreIfZero(){return promptDataFormat.NumericBlankWithUnderscoreIfZero;}
    static get MoneyNumericBlankWithUnderscoreIfZero(){return promptDataFormat.MoneyNumericBlankWithUnderscoreIfZero;}
    static get PercentNumberBlankWithUnderscoreIfZero(){return promptDataFormat.PercentNumberBlankWithUnderscoreIfZero;}
    static get MoneyNumericBlankWithWhiteSpaceIfZero(){return promptDataFormat.MoneyNumericBlankWithWhiteSpaceIfZero;}
    static get PercentNumber(){return promptDataFormat.PercentNumber;}
    static get MoneyNumericForCDF(){return promptDataFormat.MoneyNumericForCDF;}
    static get MoneyContainsOneZero(){return promptDataFormat.MoneyContainsOneZero;}
    static get NumericFormatted(){return promptDataFormat.NumericFormatted;}
    static get NumericFormattedOptionA(){return promptDataFormat.NumericFormattedOptionA;}
    static get NumericFormattedOptionB(){return promptDataFormat.NumericFormattedOptionB;}
    static get NumericFormattedOptionC(){return promptDataFormat.NumericFormattedOptionC;}
    static get Barcode(){return promptDataFormat.Barcode;}
    static get TextImage(){return promptDataFormat.TextImage;}
}

export class StoredProcedureInfoModel{
    constructor(options){
        const self = this;
        self.clientKey = _.uniqueId("stored-procedure-info-model-");
        self.name = options.name || null;
        self.alias = options.alias || null;
        self.parameters = [];
        self.resultColumns = [];
        self.isHydrated = false;

        if(options.parameters){
            _.forEach(options.parameters, p => {
                self.parameters.push(new StoredProcedureDataModel(p));
            });
        }

        if(options.resultColumns){
            _.forEach(options.resultColumns, rc => {
                self.resultColumns.push(new StoredProcedureDataModel(rc));
            });
        }
    }
}

export class StoredProcedureDataModel{
    constructor(options){
        this.clientKey = _.uniqueId("stored-procedure-data-model-");
        this.name = options.name || null;
        this.dataType = options.dataType || null;
    }
}

const variableType = {
    Constant: 0,
    MergeField: 1,
    Prompt: 2,
    StoredProcedureValue: 3
};
export class VariableType {
    static get Constant () {return variableType.Constant;}
    static get MergeField () {return variableType.MergeField;}
    static get Prompt () {return variableType.Prompt;}

    static get lookupItems () {
        let allItems =_.map(variableType, a => ({ id: a, name: VariableType.displayValue(a) }));
        return _.filter(allItems, item => item.id !== 3);
    }

    static displayValue (id) {
        let enumKey = _.findKey(variableType, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}