<template>
    <rq-tree-view
        ref="fieldTreeView"
        :id="id"
        :automation_id="automation_id"
        :title="title"
        :data-source="treeDataSource"
        :show-border="showBorder"
        :show-title-border="showTitleBorder"
        v-model:scroll-position="scrollPositionValue"
        v-model="selectedItemKey"
        @item-selected="onItemSelected">
        <template #item-label="{ item }">
            {{item.displayName}}
            <FontAwesomeIcon v-if="item.allowMergeLoop" icon="fad fa-repeat" />
            <span v-if="isMarkedItem(item.key)" class="badge badge-success badge-pill">{{markedItemLabel(item.key)}}</span>
        </template>
    </rq-tree-view>
</template>

<script>
    import { mapGetters } from "vuex";
    import { MergeFieldTreeItem } from "./editor-dialogs/models.js";

    export default {
        name:"MergeFieldTree",
        props: {
            id: { default:null },
            automation_id: { type: String, default: "" },
            markedItems: { type: Array, default: () => [] },
            title: { type: String, default: null },
            items: { type: Array, default: () => [] },
            showBorder: { type: Boolean, default: false },
            showTitleBorder: { type: Boolean, default: false },
            resetItemState: { type: Boolean, default: false },
            scrollPosition: { type: Number, default: 0 },
            modelValue: { type: String, default: null }
        },
        data () {
            const self = this;
            return {
                treeItems: [],
                selectedItemKey: null,
                selectedItem: null
            };
        },

        computed: {
            ...mapGetters([
                "lookupHelpers"
            ]),
            scrollPositionValue: {
                get() { return this.scrollPosition; },
                set(val) { this.$emit("update:scrollPosition", val); }
            },
        },

        watch:{
            items: {
                handler(newValue, oldValue) {
                    this.treeItems = newValue;
                },
                immediate: true
            },
            modelValue(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.selectedItemKey) return;
                this.selectedItemKey = newValue;
            },
            selectedItemKey(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.modelValue) return;
                this.$emit("update:modelValue", newValue);
            },
            "selectedItem.instanceIndex": {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || (_.parseNumber(oldValue, 0) < 1 && _.parseNumber(newValue, 0) < 1)) return;
                    this.selectedItem.updateObjectPath();
                },
                immediate: true
            },
            "selectedItem.instanceType": {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || _.parseNumber(this.selectedItem?.instanceIndex, 0) < 1) return;
                    this.selectedItem.updateObjectPath();
                },
                immediate: true
            }
        },

        created() {
            const self = this;
            self.treeDataSource = { load: self.fetchChildData };
        },

        methods: {
            fetchChildData(parent) {
                const self = this;
                let parentData = null;
                if(!_.isNil(parent)) {
                    parentData = parent.toDataObject();
                }
                else if(!_.isEmpty(self.treeItems)) {
                    if(self.resetItemState) { self.resetItems(); }
                    return Promise.resolve(self.treeItems);
                }
                return self.$api.DocumentsApi.getMergeFieldTree(parentData)
                    .then(result => {
                        let items = _.map(result, item => new MergeFieldTreeItem(item));
                        if(_.isNil(parent)) items[0].isExpanded = true;
                        return items;
                    });
            },

            isMarkedItem(key) {
                return _.isEmpty(this.markedItems)
                    ? false
                    : _.some(this.markedItems, item => item.key === key);
            },

            markedItemLabel(key) {
                let markedItem = _.find(this.markedItems, item => item.key === key);
                return _.get(markedItem, "label", "");
            },

            getFieldTreeItems() {
                const self = this;
                return _.map(
                    _.get(self, "$refs.fieldTreeView.treeItems", null) || [],
                    item => new MergeFieldTreeItem(item)
                );
            },

            resetItems() {
                const self = this;
                _.forEach(self.treeItems, item => item.reset());
            },

            onItemSelected(e) {
                this.selectedItem = e.selectedItem;
                this.$emit("item-selected", e);
            }
        }
    };
</script>