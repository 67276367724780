<template>
    <nav class="doc-nav">
        <perfect-scrollbar class="scroll-area" :options="psOptions">

            <draggable v-if="canSort"
                ref="navElements"
                tag="ul"
                class="nav flex-column"
                handle=".doc-nav-handle"
                draggable=".doc-nav-item"
                :scroll="true"
                v-model="navItems"
                @change="onNavOrderChange">
                <template #item="{ element }">
                    <document-nav-item
                        :active="selectedItemKey === element.key"
                        :item="element"
                        :closeable="canClose"
                        :sortable="canSort"
                        @select="onNavSelect"
                        @close="onNavClose"
                    />
                </template>
            </draggable>

            <ul v-else class="nav flex-column">
                <document-nav-item v-for="item in navItems"
                    :key="item.key"
                    :active="selectedItemKey === item.key"
                    :item="item"
                    :closeable="canClose"
                    :sortable="canSort"
                    @select="onNavSelect"
                    @close="onNavClose"
                />
            </ul>

        </perfect-scrollbar>
    </nav>
</template>
<script>
    import draggable from 'vuedraggable';
    import { DocNavItemModel } from "../models";
    import DocumentNavItem from "./DocumentNavItem"

    export default {
        name: "DocumentNav",

        components: { draggable, DocumentNavItem },

        props: {
            id: { type: String, default:null },
            automation_id: { type: String, default: "" },
            items: { default: () => [] },
            keyAttr: { type: String, default: null },
            labelAttr: { type: String, default: null },
            selectedIndex: { type: Number, default: 0 },
            sortable: { type: Boolean, default: false },
            closeable: { type: Boolean, default: false }
        },

        data() {
            return {
                navItems: [],
                selectedItemKey: ""
            };
        },

        computed: {
            automationId() { return this.automation_id || this.elementIdAttr; },
            selectedIndexValue: {
                get() { return this.selectedIndex; },
                set(val) { this.$emit("update:selectedIndex", val); }
            },
            psOptions() {
                return {
                    maxScrollbarLength: 200,
                    minScrollbarLength: 40,
                    suppressScrollX: true,
                    wheelPropagation: false,
                    interceptRailY: styles => ({ ...styles, height: 0 })
                }
            },
            canSort() { return this.sortable && this.items.length > 1; },
            canClose() { return this.closeable && this.items.length > 1; }
        },

        watch: {
            items: {
                handler(newValue, oldValue){
                    if (_.isEmpty(newValue)) {
                        this.navItems = [];
                        return;
                    }
                    this.navItems = _.map(newValue, (item, index) => {
                        if(_.isNil(this.labelAttr) && _.isNil(this.keyAttr)) return new DocNavItemModel(item);
                        return new DocNavItemModel({
                            key: _.isNil(this.keyAttr) ? null : item[this.keyAttr],
                            label: _.isNil(this.labelAttr) ? null : item[this.labelAttr],
                            sequence: index + 1
                        });
                    });
                    if(!this.selectedItemKey){
                        if(this.selectedIndex < 0 || this.selectedIndex >= this.navItems.length) return;
                        this.selectedItemKey = this.navItems[this.selectedIndex].key;
                    }
                    else {
                        let newIndex = _.findIndex(this.navItems, item => item.key === this.selectedItemKey);
                        if(newIndex < 0) return;
                        this.selectedIndexValue = newIndex;
                    }
                },
                immediate: true,
                deep: true
            },
            selectedIndex(newValue, oldValue) {
                if(this.navItems[newValue].key === this.selectedItemKey) return;
                this.selectedItemKey = this.navItems[newValue].key;
            },
            selectedItemKey(newValue, oldValue) {
                if(newValue === oldValue) return;
                let index = _.findIndex(this.navItems, item => { return item.key === newValue; });
                this.selectedIndexValue = index < 0 ? 0 : index;
            }
        },

        methods: {
            onNavSelect(selectedItem) {
                if(selectedItem.key === this.selectedItemKey && !selectedItem.hasError) return;

                let index = _.findIndex(this.navItems, v => { return v.key === selectedItem.key; });
                let cancelChange = false;
                let eventObj = {
                    item: _.cloneDeep(selectedItem),
                    previousIndex: this.selectedIndexValue,
                    index,
                    cancel: function () { cancelChange = true; }
                }

                this.$emit("change", eventObj);

                if(cancelChange) return;

                this.selectedItemKey = selectedItem.key;
            },

            onNavClose(closingItem) {
                const self = this;
                let cancelClose = false;
                let index = _.findIndex(this.navItems, v => { return v.key === closingItem.key; });
                let navItems = _.cloneDeep(self.navItems);
                let currentItem = _.cloneDeep(_.pullAt(navItems, [index]));
                let cancel = () => { cancelClose = true; };

                self.$emit("closeItem", { index, currentItem, navItems, cancel });

                if (cancelClose) return;

                if(closingItem.key === self.selectedItemKey)
                    self.selectedItemKey = navItems[0].key;

                self.$emit("update:items", navItems);
            },

            onNavOrderChange(e) {
                let items = _.cloneDeep(this.navItems);
                this.$emit("update:items", items);
                this.$emit("orderChange", { items });
            }
        }
    };
</script>

<style lang="scss">
    //Component-specific styles found in file, "~scss/rqo/app/documents/_doc-nav.scss"
</style>