<template>
    <div class="rq-doc-dialog prompt-form">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
        />
        <rq-scroll-container>
            <rq-page-section title="Prompt Settings" :borderless="!isDropDown">
                <fieldset>
                    <div class="row">
                        <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group form-required" :class="{ 'has-error': v$.name.$error }">
                            <label for="txt_prompt_name">Name</label>
                            <input automation_id="txt_prompt_name" v-model="name" type="text" @keydown.space="(event) => event.preventDefault()"  @paste="removeEventSpaces" class="form-control" id="txt_prompt_name"/>
                            <rq-validation-feedback
                                :container="`#${dialogId}`"
                                :boundary="`#${dialogId}`"
                                :messages="{
                                    'Prompt Name is required.': !v$.name.$required,
                                    'Prompt Name does not allow spaces.': !v$.name.validFormat
                                }"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-12 col-sm-12 col-md-12 col-lg-12 form-group form-required" :class="{ 'has-error': v$.promptText.$error }">
                            <label for="txt_prompt_text">Prompt Text</label>
                            <input automation_id="txt_prompt_text" v-model="promptText" type="text" class="form-control" id="txt_prompt_text"/>
                            <rq-validation-feedback
                                :container="`#${dialogId}`"
                                :boundary="`#${dialogId}`"
                                message="Prompt Text is required."
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-4 col-sm-12 col-md-4 col-lg-4 form-group">
                            <label for="drp_data_type">Data Type</label>
                            <rq-select-box
                                :items="dataTypeOptions"
                                :default-item-enabled="false"
                                v-model="dataType"
                            />
                        </div>
                        <div class="col col-4 col-sm-12 col-md-4 col-lg-4 form-group">
                            <label for="drp_level">Level</label>
                            <rq-select-box
                                :items="levelOptions"
                                :default-item-enabled="false"
                                v-model="level"
                            />
                        </div>
                        <div class="col col-4 col-sm-12 col-md-4 col-lg-4 form-group">
                            <label for="drp_level">Save Options</label>
                            <rq-select-box
                                :items="saveOptions"
                                :default-item-enabled="false"
                                v-model="saveOption"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-4 col-sm-12 col-md-4 col-lg-4 form-group">
                            <label for="txt_default_value">Default Value</label>
                            <input v-if="isDefaultValueString" automation_id="txt_default_value" v-model="defaultStringValue" type="text" class="form-control" id="txt_default_value"/>
                            <rqdx-date-box v-if="isDefaultValueDate"
                                id="dtp_default_value"
                                v-model="defaultDateValue"
                            />
                            <div v-if="isDefaultValueMoney" class="input-group">
                                <span class="input-group-text">$</span>
                                <rqInputNumber
                                    automation_id="txt_default_money_value"
                                    defaultValue="0"
                                    decimals="2"
                                    cssClass="form-control"
                                    v-model="defaultMoneyValue">
                                </rqInputNumber>
                            </div>
                        </div>
                        <div v-if="isDefaultValueMoney || isDefaultValueDate" class="col col-4 col-sm-12 col-md-4 col-lg-4 form-group">
                            <label>Format</label>
                            <rq-select-box v-if="isDefaultValueMoney"
                                :items="moneyFormatOptions"
                                :default-item-enabled="false"
                                v-model="moneyFormat"
                            />
                            <rq-select-box v-if="isDefaultValueDate"
                                :items="dateFormatOptions"
                                :default-item-enabled="false"
                                v-model="dateFormat"
                            />
                        </div>
                        <div v-if="showCustomFormat" class="col col-4 col-sm-12 col-md-4 col-lg-4 form-group">
                            <label>Format String</label>
                            <input automation_id="txt_custom_date_format" v-model="customDateFormat" type="text" class="form-control" id="txt_custom_date_format"/>
                        </div>
                        <div class="col col-12 col-sm-12 col-md-6 col-lg-4 form-group">
                            <label></label>
                            <b-form-checkbox
                                automation_id="chk_required"
                                id="chk_required"
                                v-model="isRequired">Prompt Value Required</b-form-checkbox>
                        </div>
                        <div class="col col-4 col-sm-12 col-md-6 col-lg-4 form-group" v-if="isDefaultValueMoney || isDefaultValueDate">
                            <label for="txt_sample">Example</label>
                            <input automation_id="txt_sample" v-model="formatSample" type="text" class="form-control" id="txt_sample" :readonly="true"/>
                        </div>
                    </div>
                </fieldset>
            </rq-page-section>
            <rq-page-section title="Drop Down List Items" v-show="isDropDown" borderless>
                <template #header-actions>
                    <ul class="nav">
                        <li class="nav-item">
                            <b-btn automation_id="btn_add_item" variant="theme" @click="onAddListItem">Add Item</b-btn>
                        </li>
                    </ul>
                </template>
                <rqdx-action-data-grid
                    ref="dataGrid"
                    automation_id="tbl_drop_down_items"
                    :actions="selectionActions"
                    :data-source="gridDataSource"
                    :config="gridConfig"
                    export-file-name="drop-down-item-data"
                    @delete="onDeleteAction"
                    hide-search
                    rq-editable
                    hide-show-column-chooser
                />

            </rq-page-section>
        </rq-scroll-container>
        <statement-preview :statement="currentStatement"/>
    </div>
</template>
<script>
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";
    import StatementPreview from "./StatementPreview";
    import { ClientDropDownListItem, PromptLookupOptions, PromptDataType, PromptDataFormat } from "./models";
import { DateTimeHelper } from '@/shared/utilities';
    export default {
        name: "PromptForm",
        components: {StatementPreview},
        inject: ["dialogId"],
        setup: () => ({ v$: useVuelidate() }),
        data(){
            return {
                name: null,
                promptText: null,
                dataType: 0,
                level: 1,
                defaultStringValue: null,
                defaultMoneyValue: 0,
                defaultDateValue: null,
                moneyFormat: 0,
                dateFormat: 3,
                customDateFormat: "",
                saveOption: 2,
                isRequired: false,
                dropDownValues: [],
                formatSample: "",
                showBanner: false,
            }
        },
        created(){
            this.loadGridConfig();
        },
        computed:{
            dataGrid(){ return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            dataTypeOptions(){
                return PromptLookupOptions.dataType;
            },
            levelOptions(){
                return PromptLookupOptions.level;
            },
            saveOptions(){
                return PromptLookupOptions.saveOption;
            },
            moneyFormatOptions(){
                return PromptLookupOptions.moneyFormat;
            },
            dateFormatOptions(){
                return PromptLookupOptions.dateFormat;
            },
            isDefaultValueString(){
                return this.dataType !== PromptDataType.Money && this.dataType !== PromptDataType.Date;
            },
            isDefaultValueMoney(){
                return this.dataType === PromptDataType.Money;
            },
            isDefaultValueDate(){
                return this.dataType === PromptDataType.Date;
            },
            isDropDown(){
                return this.dataType === PromptDataType.DropDown;
            },
            showCustomFormat(){
                return this.dataType === PromptDataType.Date && this.dateFormat === PromptDataFormat.Custom;
            },
            currentInfo() {
                const self = this;
                if(_.isEmpty(self.name)) return {};

                let result = {
                    dataType: self.dataType,
                    defaultValue: null,
                    dropDownValues: self.dropDownValues,
                    format: null,
                    formatString: null,
                    isRequired: self.isRequired,
                    level: self.level,
                    name: self.name,
                    promptText: self.promptText,
                    saveOption: self.saveOption,
                    isLocal: true,
                    localDateCreated: DateTimeHelper.now()
                };

                switch(self.dataType){
                    case PromptDataType.Money:
                        result.format = self.moneyFormat;
                        result.defaultValue = self.defaultMoneyValue;
                        break;
                    case PromptDataType.Date:
                        result.format = self.dateFormat;
                        result.formatString = self.customDateFormat;
                        result.defaultValue = DateTimeHelper.getDateFromString(self.defaultDateValue).toFormat("MM/dd/yyyy");
                        break;
                    default:
                        result.defaultValue = self.defaultStringValue;
                        break;
                }

                return result;
            },
            currentStatement(){
                const self = this;
                if(!self.name) return "";

                let selectedDataType = _.find(self.dataTypeOptions, dt => { return dt.id === self.dataType;});
                let selectedLevel = _.find(self.levelOptions, l => { return l.id === self.level;});
                let selectedSaveOption = _.find(self.saveOptions, so => { return so.id === self.saveOption;});
                let promptDefaultValue = "";
                let selectedDate;
                if(self.defaultDateValue){
                    selectedDate = DateTimeHelper.getDateFromString(self.defaultDateValue);
                }
                switch(self.dataType){
                    case 1:
                        promptDefaultValue = self.defaultMoneyValue ? `~Default=${self.defaultMoneyValue}` : "";
                        break;
                    case 2:
                        promptDefaultValue = self.defaultDateValue ? `~Default=${selectedDate.toFormat("MM/dd/yyyy")}` : "";
                        break;
                    default:
                        promptDefaultValue = self.defaultStringValue ? `~Default=${self.defaultStringValue}` : "";
                        break;
                }
                let dataFormat = "";
                let promptCustomFormat = "";

                if(self.dataType === PromptDataType.Money){
                    let selectedMoneyFormat = _.find(self.moneyFormatOptions, mf => { return mf.id === self.moneyFormat;});
                    dataFormat = `~Format=${selectedMoneyFormat.value}`;
                }

                if(self.dataType === PromptDataType.Date){
                    let selecteDateFormat = _.find(self.dateFormatOptions, df => { return df.id === self.dateFormat;});
                    dataFormat = `~Format=${selecteDateFormat.value}`;

                    if(self.dateFormat === PromptDataFormat.Custom){
                        promptCustomFormat = `~PromptCustomFormat=${self.customDateFormat}`;
                    }
                }

                let ddValues = "";

                if(self.dataType === PromptDataType.DropDown){
                    let values = [];
                    _.forEach(self.dropDownValues, dv => { values.push(dv.value);});
                    ddValues = `~DDValues=${values.join("|")}`;
                }

                let requiredValue = self.isRequired ? "~IsRequired=True" : "";

                return `[PROMPT @${self.name} "${self.promptText || ''}"~DataType=${selectedDataType.value}${dataFormat}${promptCustomFormat}~Level=${selectedLevel.value}~SaveOption=${selectedSaveOption.value}${ddValues}${promptDefaultValue}${requiredValue}]`;
            },


        },
        watch:{
            dataType(newValue){
                const self = this;
                if(newValue === 1 || newValue === 2){
                    self.generateFormatSample();
                }
            },
            defaultMoneyValue(newValue){
                const self = this;
                self.generateFormatSample();
            },
            defaultDateValue(newValue){
                const self = this;
                self.generateFormatSample();
            },
            moneyFormat(newValue){
                const self = this;
                self.generateFormatSample();
            },
            dateFormat(newValue){
                const self = this;
                self.generateFormatSample();
            },
            customDateFormat(newValue){
                const self = this;
                self.generateFormatSample();
            }
        },

        validations: () => ({
            name: {
                required,
                validFormat: val => _.isEmpty(val) || !(/\s/g).test(val)
            },
            promptText: { required }
        }),
        methods:{
            loadGridConfig(){
                const self = this;
                self.selectionActions = [
                    { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true }
                ];
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "value",
                            caption: "Value",
                            dataType: "value",
                            allowEditing: true,
                        },
                    ],
                }
                self.gridDataSource = {
                    key: "clientKey",
                    load (loadOptions) {
                        return Promise.resolve(self.dropDownValues);
                    },
                    update: self.onGridUpdate,
                    insert: self.onGridInsert
                }
            },
            onGridUpdate(key, values) {
                const self = this;
                let item = _.find(self.dropDownValues, dv => dv.clientKey === key);
                _.assign(item, values);

                return Promise.resolve(item);
            },

            onGridInsert(values){
                const self = this;
                let newItem = new ClientDropDownListItem({value: ""});

                _.assign(newItem, values);
                self.dropDownValues.push(newItem);
                return Promise.resolve(newItem);
            },

            onAddListItem(){
                this.dataGrid.addRow();
            },

            onDeleteAction(e){
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;

                _.forEach(items, i => {
                    let index = _.findIndex(self.dropDownValues, dv => {
                        return dv.clientKey === i.clientKey;
                    });

                    self.dropDownValues.splice(index, 1);
                });
            },
            removeEventSpaces(e){
                e.preventDefault();
                const self = this;
                let left    = e.target.value.substring(0, e.target.selectionStart);
                let right   = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
                let pasted  = (e.dataTransfer || e.clipboardData).getData('text').replace(/ /g, '');
                self.name = left + pasted + right;
            },
            validate(){
                const self = this;
                self.v$.$touch();
                self.showBanner = true;
                return !self.v$.$error;
            },

            generateFormatSample:_.debounce(function(){
                const self = this;
                self.showBanner = false;
                if(!self.isDefaultValueMoney && !self.isDefaultValueDate) return;
                let request = {};
                if(self.isDefaultValueMoney){
                    request.isNumeric = true;
                    request.format = self.moneyFormat;
                    request.sampleNumericValue = self.defaultMoneyValue;
                }
                else{
                    request.isNumeric = false;
                    request.format = self.dateFormat;
                    request.sampleDateValue = self.defaultDateValue;
                    request.customFormatString = self.customDateFormat;
                }

                let promise = self.$api.DocumentTemplatesApi.generateFormatSample(request).then(
                    result => {
                        self.formatSample = result;
                    }
                ).catch(error => {
                    var msg = error.errorMessage || error.message;
                    self.$toast.error(msg);
                });

                return promise;
            }, 200)
        }
    }
</script>