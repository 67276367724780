<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        automation_id="dg_printDocs_printQueue"
        title="Document Queue"
        title-size="sm"
        :data-source="documents"
        :config="gridConfig"
        hide-show-column-chooser
        hide-clear-filters
        hide-search
        hide-export>
        <template #toolbar>
            <slot name="toolbar"></slot>
        </template>
    </rqdx-action-data-grid>
</template>

<script setup>
    import { ref, computed, watch, nextTick } from "vue";
    import { useStore } from "vuex";
    import { DOCUMENT_ACTIONS } from "@/store/actions";
    import { DOCUMENT_MUTATIONS } from "@/store/mutations";
    import { useVModel } from "@vueuse/core";
    import { DocumentModel } from '../../models';
    import { useGridInvokerMethods } from "@/shared/composables/";

    const props = defineProps({
        selectedIndexes: { type: Array, default: () => [] }
    });
    const emit = defineEmits(["update:selectedIndexes"]);

    const store = useStore();
    const {
        dataGrid,
        invokeGridMethod
    } = useGridInvokerMethods();

    const localSelectedIndexes = useVModel(props, "selectedIndexes", emit);

    const gridConfig = ref({
        keyExpr: "clientKey",
        columns: [
            { dataField: "sequence", visible: false, showInColumnChooser:false, sortIndex: 0 },
            { dataField: "description" },
            { dataField: "documentSourceName", caption:"Source", width: 75 }
        ],
        summary: {
            totalItems: [
                { name: "TotalDocsLabel", column: 0, alignment: "left", displayFormat: "DOCUMENTS IN QUEUE" },
                { name: "TotalDocs", column: "documentSourceName", alignment: "right", displayFormat: "{0}", summaryType: "count" }
            ]
        },
        allowColumnResizing: false,
        wordWrapEnabled: false,
        columnFixing: { enabled: false },
        selection: { mode: "multiple", showCheckBoxesMode: "never" },
        noDataText: "No documents added to generate",
        sorting: { mode: "none" },
        remoteOperations: false,
        paging: { enabled: false },
        onRowDblClick(e) {
            store.commit(DOCUMENT_MUTATIONS.REMOVE_DOC_FROM_QUEUE_AT_INDEX, e.dataIndex);
        },
        onSelectionChanged(e) {
            localSelectedIndexes.value = _.map(e.selectedRowKeys, e.component.getRowIndexByKey);
        },
        onContentReady(e) {
            localSelectedIndexes.value = _.map(e.component.getSelectedRowKeys(), e.component.getRowIndexByKey);
        }
    });

    const orderId = computed(() => store.state.orders.orderId);
    const documents = computed(() => _.map(store.state.documents.queuedDocuments, d => new DocumentModel(d)));

    watch(orderId, (newVal, oldVal) => {
        if(newVal === oldVal) return;
        reset();
    });

    const getSelectedData = () => invokeGridMethod("getSelectedRowsData");
    const reorder = diff => store.dispatch(DOCUMENT_ACTIONS.REORDER_QUEUE, { diff, indexes: localSelectedIndexes.value });

    async function refreshSelectedIndexes() {
        await nextTick();
        let keys = invokeGridMethod("getSelectedRowKeys");
        localSelectedIndexes.value = _.isEmpty(keys) ? [] : _.map(keys, key => invokeGridMethod("getRowIndexByKey", key));
    }

    function removeSelected() {
        let selectedDocuments = getSelectedData();
        if(_.isEmpty(selectedDocuments)) return;
        store.dispatch(DOCUMENT_ACTIONS.REMOVE_FROM_QUEUE, selectedDocuments);
    }

    function reset() {
        localSelectedIndexes.value = [];
        store.commit(DOCUMENT_MUTATIONS.SET_QUEUED_DOCUMENTS, []);
    }

    reset();

    defineExpose({
        getSelectedData,
        refreshSelectedIndexes,
        removeSelected,
        reorder,
        reset
    })
</script>