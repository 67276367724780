<template>
    <div class="rq-doc-dialog merge-condition">
        <div class="row m-0">
            <div :class="formColClassAttr">
                <rq-scroll-container>
                    <rq-page-section :borderless="!showInstanceOptions" no-header>
                        <merge-statement-variable
                            :index="0"
                            v-model:variable-key="variables[0].key"
                            v-model:variable-type="variables[0].type"
                            v-model:variable-value="variables[0].value"
                            :selected-field-item="selectedFieldItem"
                            :selected-prompt-item="selectedPromptItem"
                            :selected-prompt-data="selectedPromptData"
                            :inline="!showFieldTree || !showPromptTree"
                        />
                        <div class="row">
                            <div :class="{ 'col col-12 form-group': true, 'col-lg-12': showFieldTree && showPromptTree, 'col-lg-4': !showFieldTree || !showPromptTree }">
                                <label>Operator:</label>
                                <rq-select-box
                                    automation_id="drp_operator"
                                    :items="operators"
                                    :default-item-enabled="false"
                                    v-model="selectedOperatorId"
                                />
                            </div>
                        </div>
                        <merge-statement-variable
                            :index="1"
                            v-model:variable-key="variables[1].key"
                            v-model:variable-type="variables[1].type"
                            v-model:variable-value="variables[1].value"
                            :selected-field-item="selectedFieldItem"
                            :selected-prompt-item="selectedPromptItem"
                            :selected-prompt-data="selectedPromptData"
                            :inline="!showFieldTree || !showPromptTree"
                        />
                    </rq-page-section>
                    <rq-page-section v-if="showInstanceOptions"
                        title="Instance Options"
                        header-size="sm"
                        borderless>
                        <div class="row">
                            <div class="col-6 form-group">
                                <label>Type:</label>
                                <rq-select-box
                                    :items="instanceTypeItems"
                                    :default-item-enabled="false"
                                    v-model="selectedFieldItem.instanceType"
                                    @change="onInstanceTypeChange"
                                />
                            </div>
                            <div class="col-6 form-group">
                                <label>Index:</label>
                                <rq-input-number
                                    default-value="0"
                                    min-value="0"
                                    value-event="input"
                                    v-model="selectedFieldItem.instanceIndex"
                                />
                            </div>
                        </div>
                    </rq-page-section>
                </rq-scroll-container>
            </div>
            <div v-show="showFieldTree" :class="fieldsColClassAttr">
                <merge-field-tree
                    ref="fieldTreeView"
                    id="rq-mf-tree-view"
                    title="Fields"
                    :items="fields"
                    :marked-items="markedFieldItems"
                    v-model="selectedFieldItemKey"
                    @item-selected="onFieldItemSelected"
                    reset-item-state
                    show-title-border
                />
            </div>
            <div v-show="showPromptTree" :class="promptsColClassAttr">
                <rq-tree-view
                    ref="promptTreeView"
                    id="rq-prompt-tree-view"
                    title="Prompts"
                    :items="promptTreeItems"
                    v-model="selectedPromptItemKey"
                    @item-selected="onPromptItemSelected"
                    show-title-border>
                    <template #item-label="{ item }">
                        {{item.displayName}}
                        <span v-if="variables[0].key === item.key" class="badge badge-success badge-pill">VALUE 1</span>
                        <span v-if="variables[1].key === item.key" class="badge badge-success badge-pill">VALUE 2</span>
                    </template>
                </rq-tree-view>
            </div>
        </div>
        <statement-preview :statement="currentStatement"/>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import MergeFieldTree from "../MergeFieldTree";
    import MergeStatementVariable from "./MergeStatementVariable";
    import StatementPreview from "./StatementPreview";
    import { TreeViewItemModel } from "@/shared/models/rq-ui";
    import { StatementHelpers } from "./helpers";
    import { MergeFieldTreeItem, VariableType } from "./models.js";

    export default {
        name:"MergeConditionForm",
        components: { MergeStatementVariable, MergeFieldTree, StatementPreview },
        props: {
            fields: { type: Array, default: () => [] },
            prompts: { type: Array, default: () => [] }
        },
        data () {
            const self = this;
            return {
                variables: [
                    { key: null, type: VariableType.Constant, value: null },
                    { key: null, type: VariableType.Constant, value: null }
                ],
                allRoles: [],
                selectedOperatorId: 1,
                selectedFieldItemKey: null,
                selectedPromptItemKey: null,
                selectedFieldItem: null,
                selectedPromptItem: null,
                selectedPromptData: null,
                fieldStatement: "",
                fieldTreeItems: self.fields.slice(),
                promptTreeItems: [],
                variableTypes: VariableType.lookupItems,
                operators: [
                    { id: 1, name: "Equals", statementValue: "=" },
                    { id: 2, name: "Not Equals", statementValue: "<>" },
                    { id: 3, name: "Greater Than", statementValue: ">" },
                    { id: 4, name: "Greater Than or Equals", statementValue: ">=" },
                    { id: 5, name: "Less Than", statementValue: "<" },
                    { id: 6, name: "Less Than or Equals", statementValue: "<=" },
                    { id: 7, name: "In", statementValue: "IN" }
                ]
            };
        },

        computed: {
            ...mapGetters([
                "lookupHelpers"
            ]),
            instanceTypeItems() { return StatementHelpers.getInstanceTypes(this.selectedFieldItem, this.allRoles); },
            formColClassAttr() { return { "col col-12 p-0": true, "col-md-9": this.showPromptTree !== this.showFieldTree, "col-md-6": this.showPromptTree && this.showFieldTree }; },
            fieldsColClassAttr() { return { "col p-0": true, "col-3": this.showPromptTree }; },
            promptsColClassAttr() { return { "col p-0": true, "col-3": this.showFieldTree }; },
            showFieldTree() { return _.some(this.variables, v => v.type === VariableType.MergeField); },
            showInstanceOptions() { return this.showFieldTree && _.getBool(this, "selectedFieldItem.allowMergeLoop"); },
            showPromptTree() { return _.some(this.variables, v => v.type === VariableType.Prompt); },
            selectedOperator() { return _.find(this.operators, o => o.id === this.selectedOperatorId); },
            currentStatement() {
                let exprLeft = this.getVariableStatementValue(0);
                let operatorVal = this.selectedOperator.statementValue;
                let exprRight = this.getVariableStatementValue(1);
                
                if(_.isEmpty(exprRight))
                    exprRight = `""`;

                if(_.isEmpty(exprLeft)) return "";
                return `[IF ${exprLeft} ${operatorVal} ${exprRight}]{True statement}[ELSE]{False statement}[ENDIF]`
            },
            markedFieldItems() {
                if(!this.showFieldTree) return [];
                let markedItems = [];
                _.forEach(this.variables, (item,index) => {
                    if(item.type !== VariableType.MergeField) return;
                    markedItems.push({
                        key: item.key,
                        label: `VALUE ${index+1}`
                    });
                });
                return markedItems;
            }
        },

        created() {
            const self = this;
            self.allRoles = self.lookupHelpers.getRoles();
            self.fieldTreeDataSource = { load: self.fetchChildData };
            self.promptTreeItems = StatementHelpers.mapPromptTreeItems(self.prompts);
        },

        methods: {

            fetchChildData(parent) {
                const self = this;
                let parentData = null;
                if(!_.isNil(parent)) {
                    parentData = parent.toDataObject();
                }
                else if(!_.isEmpty(self.fieldTreeItems)) {
                    _.forEach(self.fieldTreeItems, item => item.reset());
                    return Promise.resolve(self.fieldTreeItems);
                }
                return self.$api.DocumentsApi.getMergeFieldTree(parentData)
                    .then(result => {
                        let items = _.map(result, item => new MergeFieldTreeItem(item));
                        if(_.isNil(parent)) items[0].isExpanded = true;
                        return items;
                    });
            },

            onFieldItemSelected(e) {
                this.selectedFieldItem = e.selectedItem;
            },

            onPromptItemSelected(e) {
                this.selectedPromptItem = e.selectedItem;
                this.selectedPromptData = _.find(this.prompts, p => p.name === e.selectedItem.name);
            },

            onInstanceTypeChange(e) {
                if(e.selectedValue === "Any" || this.selectedFieldItem.instanceIndex > 0) return;
                this.selectedFieldItem.instanceIndex = 1;
            },

            getVariableStatementValue(i) {
                let v = this.variables[i];
                return v.type === VariableType.Constant && !_.isEmpty(v.value)
                    ? `"${v.value}"`
                    : v.value;
            },

            getFieldTreeItems() {
                const self = this;
                return _.map(
                    _.get(self, "$refs.fieldTreeView.treeItems", null) || [],
                    item => new MergeFieldTreeItem(item)
                );
            }
        }
    };
</script>