<template>
    <div class="row">
        <div class="col col-3 form-group">
            <b-form-checkbox automation_id="chk_barcode_format" v-model="barcodeFormat">Barcode Format</b-form-checkbox>
        </div>
        <div class="col col-3 form-group">
            <b-form-checkbox automation_id="chk_keep_doc_font" v-model="keepDocFont">Keep Doc Font</b-form-checkbox>
        </div>
        <div class="col col-3 form-group">
            <b-form-checkbox automation_id="chk_bullets" v-model="bullets">Bullets</b-form-checkbox>
        </div>
        <div class="col col-12">
            <fieldset :disabled="!bullets">
                <div class="content-group">
                    <b-nav class="rq-nav-tabs">
                        <b-nav-item
                            v-for="(lvl, index) in levels"
                            :key="`level_${lvl.num}_tab`"
                            :automation_id="`level_${lvl.num}`"
                            :active="levelIndex === index"
                            :disabled="!bullets"
                            @click="levelIndex = index">Level {{lvl.num}}</b-nav-item>
                    </b-nav>
                </div>
                <div class="row">
                    <div class="col-8 form-group">
                        <label>Bullet Style:</label>
                        <rq-select-box
                            id="drp_bullet_styles"
                            :items="bulletStyles"
                            :default-item-enabled="false"
                            v-model="levelOptions.bulletStyle"
                        />
                    </div>
                    <div class="col-4 form-group">
                        <label></label>
                        <b-form-checkbox automation_id="chk_restart_numbering" v-model="levelOptions.restartNumbering">Restart Numbering</b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 form-group">
                        <label>Left Indent:</label>
                        <input automation_id="txt_left_indent" type="text" class="form-control" v-model="levelOptions.leftIndent">
                    </div>
                    <div class="col-6 form-group">
                        <label>Hanging Indent:</label>
                        <input automation_id="txt_hanging_indent" type="text" class="form-control" v-model="levelOptions.hangingIndent">
                    </div>
                    <div class="col-6 form-group">
                        <label>Text Before Bullet:</label>
                        <input automation_id="txt_text_before" type="text" class="form-control" v-model="levelOptions.textBeforeBullet">
                    </div>
                    <div class="col-6 form-group">
                        <label>Text After Bullet:</label>
                        <input automation_id="txt_text_after" type="text" class="form-control" v-model="levelOptions.textAfterBullet">
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
</template>

<script>
    export default {
        name:"MergeFieldStringForm",
        props: {
            field: { type: Object, default: () => ({}) },
            modelValue: { type: String, default: "" }
        },
        data () {
            return {
                barcodeFormat: false,
                keepDocFont: false,
                bullets: false,
                restartNumbering: false,
                levelIndex: 0,
                levels: [
                    {
                        num: 1,
                        bulletStyle: 0,
                        leftIndent: 0,
                        hangingIndent: 360,
                        textBeforeBullet: "",
                        textAfterBullet: ".",
                    },
                    {
                        num: 2,
                        bulletStyle: 0,
                        leftIndent: 360,
                        hangingIndent: 360,
                        textBeforeBullet: "",
                        textAfterBullet: "."
                    }
                ],
                bulletStyles: [
                    { id:-1, name:"None" },
                    { id:0, name:"Numeric" },
                    { id:1, name:"Uppercase Alpha" },
                    { id:2, name:"Lowercase Alpha" },
                    { id:3, name:"Uppercase Roman" },
                    { id:4, name:"Lowercase Roman" },
                    { id:5, name:"Bullet" }
                ]
            };
        },

        computed: {
            levelOptions() { return this.levels[this.levelIndex]; },
            statementString() {
                let stmtParts = [];
                if(this.keepDocFont) {
                    stmtParts.push("~KeepDocFont=True");
                }
                if(this.barcodeFormat) {
                    stmtParts.push("~Format=Barcode");
                }
                if(this.bullets) {
                    stmtParts.push(this.getBulletValueString());
                }
                let optionString = stmtParts.join("");
                return `[MF:${this.field.objectPath}${optionString}]`
            }
        },

        watch: {
            statementString: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.$emit("update:modelValue", newValue);
                },
                immediate: true
            }
        },

        methods: {
            getBulletValueString() {
                let levelParts = [];
                _.forEach(this.levels, lvl => {
                    let valParts = [
                        lvl.bulletStyle,
                        lvl.textBeforeBullet,
                        lvl.textAfterBullet,
                        lvl.hangingIndent,
                        "1",
                        lvl.leftIndent,
                        "1",
                        lvl.restartNumbering ? "3" : "2"
                    ];
                    levelParts.push(valParts.join(";"));
                });
                return `~Bullet=${levelParts.join("|")}`;
            }
        }
    };
</script>