<template>
    <div class="rq-doc-dialog stored-procedure-form">
        <div class="row stored-procedure-info">
            <div class="col col-12 col-lg-6">
                <rqdx-action-data-grid
                    ref="procDataGrid"
                    title="Stored Procedures"
                    title-size="sm"
                    automation_id="tbl_procedures"
                    :data-source="procDataSource"
                    :config="procGridConfig"
                    export-file-name="procedures-data"
                    class="grid-content-container"
                    hide-search
                    hide-show-column-chooser
                    @selectionChanged="procGridSelectionChanged"
                />
            </div>
            <div class="col col-12 col-lg-6" v-if="showDetails">
                <rq-scroll-container>
                    <rqValidationSummary :dataSource="validationErrors" />
                    <rq-page-section title="Settings" borderless>
                        <div class="row">
                            <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                                <label for="txt_alias">Alias</label>
                                <input automation_id="txt_alias" v-model="selectedStoredProcedure.alias" type="text" class="form-control" id="txt_alias"/>
                            </div>
                            <div class="col col-4 col-sm-12 col-md-4 col-lg-4 form-group">
                                <label for="txt_alias">Results</label>
                                <b-form-checkbox
                                    automation_id="chk_loop"
                                    id="chk_loop"
                                    v-model="isLoop">Loop Results</b-form-checkbox>
                            </div>
                        </div>
                    </rq-page-section>
                    <rqdx-action-data-grid
                        ref="paramsDataGrid"
                        title="Parameters"
                        title-size="sm"
                        automation_id="tbl_parameters"
                        :data-source="paramDataSource"
                        :config="paramGridConfig"
                        export-file-name="parameter-data"
                        hide-search
                        hide-show-column-chooser
                    />
                    <rqdx-action-data-grid
                        ref="fieldsDataGrid"
                        title="Output Fields"
                        title-size="sm"
                        automation_id="tbl_fields"
                        :data-source="fieldsDataSource"
                        :config="fieldsGridConfig"
                        export-file-name="field-data"
                        hide-search
                        hide-show-column-chooser
                    />
                </rq-scroll-container>
            </div>
        </div>
        <statement-preview :statement="currentStatement"/>
    </div>
</template>
<script>

    import StatementPreview from "./StatementPreview";
    import { StoredProcedureInfoModel, StoredProcedureDataModel } from "./models";
    import { DateTimeHelper } from '@/shared/utilities';

    export default {
        name: "StoredProcedureForm",
        components: {StatementPreview},
        props:{
            storedProcedures:{type: Array, default: () => []}
        },
        data(){
            return {
                selectedStoredProcedure: null,
                availableStoredProcedures: [],
                isLoop: false,
                validationErrors: []
            }
        },
        created(){
            this.initializeData();
            this.loadGridConfig();
        },
        computed:{
            procDataGrid(){ return _.get(this, "$refs.procDataGrid.gridInstance", null) || {}; },
            paramsDataGrid(){ return _.get(this, "$refs.paramsDataGrid.gridInstance", null) || {}; },
            fieldsDataGrid(){ return _.get(this, "$refs.fieldsDataGrid.gridInstance", null) || {}; },

            currentInfo() {
                const self = this;
                if(!self.selectedStoredProcedure) return null;
                return {
                    ...self.selectedStoredProcedure,
                    isLocal: true,
                    localDateCreated: DateTimeHelper.now()
                };
            },

            currentStatement(){
                const self = this;
                if(!self.selectedStoredProcedure) return "";

                let parameters = []
                _.forEach(self.selectedStoredProcedure.parameters, p => {
                    parameters.push(`${p.name}=`);
                })
                let parameterString = parameters.join(", ");

                let tagParameters = `:Name=${self.selectedStoredProcedure.name} ${parameterString}~Alias=${self.selectedStoredProcedure.alias}`;

                return self.isLoop ? `[SPL${tagParameters}][ENDMERGE]` : `[SP${tagParameters}]`;
            },

            showDetails(){
                return !_.isNil(this.selectedStoredProcedure);
            }

        },
        watch:{
            selectedStoredProcedure(newValue){
                if(!this.selectedStoredProcedure.isHydrated)
                    this.hydrateSelected();
                else
                    this.refreshSettingsGrids();
            }
        },
        methods:{

            initializeData(){
                const self = this;
                _.forEach(self.storedProcedures, sp => {
                    self.availableStoredProcedures.push(new StoredProcedureInfoModel(sp));
                });
            },

            loadGridConfig(){
                const self = this;

                self.procGridConfig = {
                    selection: {
                        mode: "single"
                    },
                    columns: [
                        {
                            dataField: "name",
                            caption: "Stored Procedure"
                        },
                    ],
                }
                self.procDataSource = {
                    key: "clientKey",
                    load (loadOptions) {
                        return Promise.resolve(self.availableStoredProcedures);
                    },
                }
                self.paramGridConfig = {
                    selection: {
                        mode: "single"
                    },
                    columns: [
                        {
                            dataField: "name",
                            caption: "Parameter"
                        },
                        {
                            dataField: "dataType",
                            caption: "Data Type"
                        },
                    ],
                    height: "auto"
                }
                self.paramDataSource = {
                    key: "clientKey",
                    load (loadOptions) {
                        return Promise.resolve(self.selectedStoredProcedure.parameters);
                    },
                }
                self.fieldsGridConfig = {
                    selection: {
                        mode: "single"
                    },
                    columns: [
                        {
                            dataField: "name",
                            caption: "Field"
                        },
                        {
                            dataField: "dataType",
                            caption: "Data Type"
                        },
                    ],
                    height: "auto"
                }
                self.fieldsDataSource = {
                    key: "clientKey",
                    load (loadOptions) {
                        return Promise.resolve(self.selectedStoredProcedure.resultColumns);
                    },
                }
            },

            procGridSelectionChanged(e){
                const self = this;
                if(e.selectedRowsData.length === 0) return;
                let selectedItem = e.selectedRowsData[0];
                self.selectedStoredProcedure = selectedItem;
            },

            hydrateSelected(){
                const self = this;
                if(_.isNil(self.selectedStoredProcedure)) return;

                let promise = self.$api.DocumentTemplatesApi.fillStoredProcedure(self.selectedStoredProcedure).then(result => {
                    self.selectedStoredProcedure.isHydrated = true;
                    _.forEach(result.parameters, p => {
                        self.selectedStoredProcedure.parameters.push(new StoredProcedureDataModel(p));
                    });
                    _.forEach(result.resultColumns, c => {
                        self.selectedStoredProcedure.resultColumns.push(new StoredProcedureDataModel(c));
                    });
                    self.refreshSettingsGrids();
                }).catch(error => {
                    let msg = error.errorMessage || error.message;
                    self.$toast.error(msg);
                    self.refreshSettingsGrids();
                });

                self.$rqBusy.wait(promise);
            },

            refreshSettingsGrids(){
                const self = this;
                self.paramsDataGrid.refresh();
                self.fieldsDataGrid.refresh();
            }
        }
    }
</script>