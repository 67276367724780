<template>
    <div class="row prompt-field">
        <div class="col-6 prompt-labels">
            <div class="row">
                <div class="col col-sm-12 col-lg-4"><span class="prompt-name">{{prompt.promptName}}</span></div>
                <div class="col col-sm-12 col-lg-8"><span class="prompt-text">{{prompt.promptText}}</span></div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="row">
                <div class="col" v-rq-tooltip.hover.top :title="tooltipText">
                    <prompt-field :prompt="prompt" event-prefix="saved-prompts" :disabled="readOnly" />
                </div>
                <div v-if="!readOnly" class="col col-auto">
                    <rq-icon-button icon="fas fa-times-circle" tooltip-text="Delete prompt value" @click="onDeletePromptClick" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { DocumentPrompt } from "../../models";
    import PromptField from "./PromptField";
    export default {
        props: {
            prompt: { default: () => new DocumentPrompt() }
        },

        components: { PromptField },

        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                fileReadOnly: state => state.isPageReadOnly || state.orders.orderSummary.isLocked,
                currentUser: state => state.authentication.session.user
            }),
            localSecurity(){
                return this.securitySettings.findValues([
                    "AllowEditPromptsFilledByOthers",
                    ]);
            },
            userReadOnly() {
                return !this.localSecurity.AllowEditPromptsFilledByOthers && _.parseNumber(this.prompt.usersID, 0) !== _.parseNumber(this.currentUser.usersID, 0);
            },
            readOnly() { return this.fileReadOnly || this.userReadOnly; },
            tooltipText() {
                return this.userReadOnly
                    ? "This prompt was saved by another user"
                    : this.fileReadOnly
                        ? "Access resstricted on this file"
                        : "";
            }
        },

        methods: {
            onDeletePromptClick (e) {
                if(!this.readOnly)
                    this.$emit('delete', this.prompt);
            }
        }
    };
</script>
