<template>
    <div class="rq-doc-dialog">
        <div class="rq-container">
            <div class="row">
                <div class="col col-12 form-group">
                    <label>Format:</label>
                    <rq-select-box
                        id="drp_"
                        :items="formats"
                        :default-item-enabled="false"
                        v-model="selectedFormatId"
                    />
                </div>
                <div v-if="customFormatSelected" class="col col-12 form-group">
                    <label>Format String:</label>
                    <input automation_id="txt_format_string" class="form-control" v-model="customFormatString">
                </div>
            </div>
        </div>
        <statement-preview :statement="currentStatement"/>
    </div>
</template>

<script>
    import StatementPreview from "./StatementPreview";
    export default {
        name:"MergeDateTimeForm",
        components: { StatementPreview },
        data () {
            return {
                selectedFormatId: 1,
                customFormatString: "",
                formats: [
                    { id: 1, name: "Numeric", statementValue: "Numeric", example: "" },
                    { id: 2, name: "Words", statementValue: "Words", example: "" },
                    { id: 3, name: "Words-Formal", statementValue: "WordsFormal", example: "" },
                    { id: 4, name: "Custom", statementValue: "Custom", example: "" }
                ]
            };
        },

        computed: {
            customFormatSelected() { return this.selectedFormatId === 4; },
            selectedFormatIndex() { return _.findIndex(this.formats, { id: this.selectedFormatId }); },
            selectedStatementValue() { return this.formats[this.selectedFormatIndex].statementValue; },
            currentStatement() {
                let customFormat = "";
                if(this.customFormatSelected && !_.isEmpty(this.customFormatString)) {
                    customFormat = `~CustomFormat=${this.customFormatString}`;
                }
                return `[DT:~Format=${this.selectedStatementValue}${customFormat}]`;
            }
        }
    };
</script>