<template>
    <div class="row">
        <div class="col col-12 form-group">
            <label>Format:</label>
            <rq-select-box
                :items="formats"
                :default-item-enabled="false"
                v-model="selectedFormatId"
            />
        </div>
        <div v-if="selectedFormatExample" class="col col-12">
            <span class="lead"><strong>Example:</strong> {{selectedFormatExample}}</span>
        </div>
        <div v-if="customFormatSelected" class="col col-12 form-group">
            <label>Format String:</label>
            <input class="form-control" v-model="formatString">
        </div>
    </div>
</template>

<script>
    export default {
        name:"MergeFieldFormatForm",
        props: {
            field: { type: Object, default: () => ({}) },
            formats: { type: Array, default: () => [] },
            customFormatValue: { type: Number, default: -1 },
            modelValue: { type: String, default: "" }
        },
        data () {
            return {
                selectedFormatId: 1,
                customFormatString: "",
                formatString: ""
            };
        },

        computed: {
            customFormatSelected() { return this.selectedFormatId === this.customFormatValue; },
            selectedFormatIndex() { return _.findIndex(this.formats, { id: this.selectedFormatId }); },
            selectedStatementValue() { return this.formats[this.selectedFormatIndex].statementValue; },
            selectedFormatExample() { return this.formats[this.selectedFormatIndex].example; },
            statementString() {
                let custFmtStmt = "";
                if(this.customFormatSelected) {
                    custFmtStmt = `~CustomFormat=${this.formatString}`;
                }
                return `[MF:${this.field.objectPath}~Format=${this.selectedStatementValue}${custFmtStmt}]`;
            }
        },

        watch: {
            statementString: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.$emit("update:modelValue", newValue);
                },
                immediate: true
            }
        }
    };
</script>