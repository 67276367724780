<template>
    <div :class="{ 'document-generation content-wrapper': true, 'document-generation-dialog':!isFullPageView }">
        <rq-page-section v-if="isFullPageView" title="Generate Documents" header-size="lg" borderless header-only>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-btn variant="theme" @click="launchSavedPrompts">View Saved Prompts</b-btn>
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <rq-list-selection-layout
            automation-id-append="document-selection"
            class="document-selection rq-ls-60-40 rq-ls-pc-50-50"
            :actions="listActions"
            @action="onDocumentListTransferAction"
            sequence-enabled>
            <template #left-section>
                <available-documents
                    ref="availableDocumentsComponent"
                    v-model:has-documents="hasAvailableItems"
                    v-model:file-documents-only="fileDocumentsOnly"
                    v-model:packages-only="packagesOnly"
                    v-model:has-selections="hasAvailableItemsSelected"
                />
            </template>
            <template #right-section>
                <document-queue
                    ref="documentQueueComponent"
                    v-model:selected-indexes="selectedQueueIndexes">
                    <!-- <template #toolbar>
                        <ul class="nav ms-auto me-3">
                            <li :class="{ 'nav-item': true, 'font-weight-bold': devexEnabled }">
                                <b-form-checkbox switch v-model="devexEnabled">DevEx Viewer Enabled</b-form-checkbox>
                            </li>
                        </ul>
                    </template> -->
                </document-queue>
            </template>
        </rq-list-selection-layout>
        <div class="merge-option-container">
            <div class="row">
                <div :class="{ 'col col-auto': true, ' me-auto ms-0 pe-0 me-pc-3 ms-pc-auto': isFullPageView, 'ms-auto': !isFullPageView }">
                    <doc-gen-merge-options
                        v-model:invoice-id="invoiceId"
                        v-model:css-main-id="cssMainId"
                        v-model:loan-id="loanId"
                    />
                </div>
                <div v-if="isFullPageView" class="col col-4 mx-2 px-0 pt-3 col-pc-auto">
                    <div v-rq-tooltip.hover.top :title="queueIsEmpty ? emptyQueueTooltip : ''">
                        <b-btn
                            variant="theme"
                            :disabled="queueIsEmpty"
                            size="lg"
                            @click="onGenerateDocumentsClick"
                            block>Generate</b-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { ORDER_ACTIONS, DOCUMENT_ACTIONS } from "@/store/actions";
    import { DOCUMENT_MUTATIONS } from "@/store/mutations";
    import { DocumentModel, DocumentPromptGroup } from "../models";

    /* Components */
    import AvailableDocuments from "../components/selection/AvailableDocuments";
    import DocumentQueue from "../components/selection/DocumentQueue";
    import DocGenMergeOptions from "../components/DocGenMergeOptions";
    import PromptEntry from "../components/prompts/PromptEntry";
    import SavedPrompts from "../components/prompts/SavedPrompts";
    import DocumentViewer from "./DocumentViewer";
    import { SETTLEMENT_TYPE } from '@settlement/models';

    export default {
        name: "DocumentGeneration",
        components: {
            AvailableDocuments,
            DocumentQueue,
            DocGenMergeOptions
        },
        data () {
            const self = this;
            return {
                fileDocumentsOnly: false,
                packagesOnly: false,
                hasAvailableItems: false,
                hasAvailableItemsSelected: false,
                selectedQueueIndexes: [],
                activePromptDocuments: [],
                promptEntryEnabled: false,
                isValid: false,
                errorMessage: "",
                searchText: "",
                documentsGenerated: false,
                loanId: 0,
                cssMainId: 0,
                invoiceId: 0,
                emptyQueueTooltip: "Add selections to the document queue to enable",
                isSettlementMapped: false,
                generateOnMap: false
            };
        },

        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                queuedDocuments: state => _.map(state.documents.queuedDocuments, d => new DocumentModel(d)),
                loans: state => state.orders.loans || [],
                selectedView: state => _.parseNumber(state.orders.orderSummary.settlementStatementType, SETTLEMENT_TYPE.CDF),
                selectedLoanId: state => state.orders.orderSummary.defaultLoanID
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            isFullPageView() { return this.$route.name === "docs:generate"; },
            queueIsEmpty() { return _.isEmpty(this.queuedDocuments); },
            moveUpDisabled() { return _.isEmpty(this.selectedQueueIndexes) || _.includes(this.selectedQueueIndexes, 0); },
            moveDownDisabled() { return _.isEmpty(this.selectedQueueIndexes) || _.includes(this.selectedQueueIndexes, this.queuedDocuments.length - 1); },
            listActions() {
                return [
                    {
                        name: "transfer-all-right",
                        tooltip: () => !this.hasAvailableItems
                            ? "No available documents"
                            : (!this.fileDocumentsOnly && !this.packagesOnly)
                                ? "Only available with<br/>\"File Documents Only\" or<br/>\"Packages Only\" enabled"
                                : "Add All to Queue",
                        disabled: () => !this.hasAvailableItems || (!this.fileDocumentsOnly && !this.packagesOnly)
                    },
                    { name: "transfer-right", tooltip: () => `Add to Queue${this.hasAvailableItemsSelected ? "" : "<br />(none selected)"}`, disabled: () => !this.hasAvailableItemsSelected },
                    { name: "transfer-left", tooltip: () => `Remove From Queue${_.isEmpty(this.selectedQueueIndexes) ? "<br />(none selected)" : ""}`, disabled: () => _.isEmpty(this.selectedQueueIndexes) },
                    { name: "transfer-all-left", tooltip: () => `Remove All From Queue${this.queueIsEmpty ? "<br/>(no queued documents)" : ""}`, disabled: () => this.queueIsEmpty },
                    { name: "move-up", tooltip: () => "Move Selected Up", disabled: () => this.moveUpDisabled },
                    { name: "move-down", tooltip: () => "Move Selected Down", disabled: () => this.moveDownDisabled }
                ];
            }
        },

        watch: {
            queuedDocuments (newVal, oldVal) {
                if(this.isFullPageView) return;
                this.$emit("set-button-state", {
                    index: 2,
                    disabled: _.isEmpty(newVal),
                    tooltip: _.isEmpty(newVal) ? this.emptyQueueTooltip : ""
                });
            },
            orderId(newVal, oldVal) {
                if(newVal === oldVal) return;
                this.init();
            }
        },

        created () {
            const self = this;
            if(!self.isFullPageView)
                self.$emit("set-button-state", { index: 2, disabled: true, tooltip: self.emptyQueueTooltip });
            self.init();
        },
        beforeUnmount() {
            this.clearState();
        },

        methods: {
            init() {
                const self = this;                
                self.clearState();
                if (!self.orderId)
                    self.$store.dispatch(ORDER_ACTIONS.GET_ORDER)

                self.onMapSettlementData();
            },
            onMapSettlementData() {
                const self = this;
                let mapPromise = self.$api.SettlementDashboardApi.mapSettlementData(self.selectedView, self.selectedLoanId);
                return mapPromise.then(() => {
                    self.isSettlementMapped = true;
                    self.$rqBusy.endWait();
                    if (!self.generateOnMap) return;
                    self.generateDocuments();
                })
                .catch(error => {
                    self.isSettlementMapped = true;
                    console.error(error);
                    self.$toast.error({ message: `Error mapping Settlement Statement. Printing Settlement Statement document will not be accurate.` });
                    return error;
                })
            },

            onGenerateDocumentsClick () { this.generateDocuments(); },

            onAddSelected() { _.invoke(this, "$refs.availableDocumentsComponent.addSelectedToQueue"); },

            onAddAll() {
                const self = this;
                self.$dialog.confirm("Confirm Add All", "Are you sure?  This will add all documents to the document queue.", () => {
                    _.invoke(self, "$refs.availableDocumentsComponent.addAllToQueue");
                    return true;
                });
            },

            onRemoveSelectedFromQueue() {
                const self = this;
                let selected = _.invoke(self, "$refs.documentQueueComponent.getSelectedData");
                if(_.isEmpty(selected)) return;
                self.$store.dispatch(DOCUMENT_ACTIONS.REMOVE_FROM_QUEUE, selected);
                _.invoke(self, "$refs.documentQueueComponent.refreshSelectedIndexes");
            },

            onRemoveAllFromQueue() {
                const self = this;
                self.$dialog.confirm("Confirm Remove All", "Are you sure?  This will remove all documents from the document queue.", () => {
                    self.$store.commit(DOCUMENT_MUTATIONS.SET_QUEUED_DOCUMENTS, []);
                    return true;
                });
            },

            //diff: -1 for up, 1 for down
            onReorderQueue(diff) { this.$store.dispatch(DOCUMENT_ACTIONS.REORDER_QUEUE, { diff, indexes: this.selectedQueueIndexes }); },

            onDocumentListTransferAction(e) {
                switch(e.name) {
                    case "transfer-all-right": this.onAddAll(); break;
                    case "transfer-right": this.onAddSelected(); break;
                    case "transfer-left": this.onRemoveSelectedFromQueue(); break;
                    case "transfer-all-left": this.onRemoveAllFromQueue(); break;
                    case "move-up": this.onReorderQueue(-1); break;
                    case "move-down": this.onReorderQueue(1); break;
                }
            },

            addDocumentToQueue (document) { this.$store.commit(DOCUMENT_MUTATIONS.ADD_DOC_TO_QUEUE, document); },

            addListToQueue (docs, pkgs=[]) { this.$store.dispatch(DOCUMENT_ACTIONS.ADD_LIST_TO_QUEUE, { docs, pkgs }); },

            completePromptEntry () {
                const self = this;
                if (self.$refs.promptEntryComponent.validate()) {
                    self.finishAndPreview()
                        .then(result => {
                            if (result) {self.$emit("close-dialog");}
                        });
                }
            },

            cancelPromptEntry () {
                const self = this;
                self.promptEntryEnabled = false;
                self.$refs.promptEntryComponent.reset();
                self.activePromptDocuments = [];
                self.updateDialogOptions({ scrollable: false });
                self.$emit("hide-custom-footer");
            },

            launchSavedPrompts() {
                const self = this;
                self.$dialog.open({
                    title: "Saved Prompts",
                    height: "90%",
                    width: "90%",
                    component: SavedPrompts,
                    okTitle: "Save",
                    onOk: (e) => {
                        return e.component.save();
                    }
                });
                return true;
            },

            launchPromptEntry() {
                const self = this;
                let okHandler = async (e) => {
                    let isValid = await e.component.validate();
                    if(!isValid) return false;
                    self.finishAndPreview();
                    return true;
                };
                self.$dialog.open({
                    title: "Fill Out Document Prompts",
                    height: "90%",
                    width: "90%",
                    props: {
                        promptGroups: self.activePromptDocuments
                    },
                    component: PromptEntry,
                    onOk: okHandler,
                    onCancel(e) {
                        self.activePromptDocuments = [];
                    }
                });
            },

            generateDocuments () {
                const self = this;

                // Check async settlement mapping process is still processing and completed before generating document
                if (!self.isSettlementMapped) {
                    self.generateOnMap = true;
                    self.$rqBusy.startWait();
                    return;
                }
                self.generateOnMap = false; // reset state

                let orderDocumentIds = _.map(self.queuedDocuments, d => d.orderDocumentID);
                self.errorMessage = "";
                self.$store.dispatch(DOCUMENT_ACTIONS.UPDATE_MERGE_OPTIONS, { loanId: self.loanId, cssMainId: self.cssMainId, invoiceId: self.invoiceId });
                let apiPromise = self.$api.DocumentsApi.getUnsavedOrderDocumentPrompts(self.orderId, orderDocumentIds.join(","))
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.activePromptDocuments = _.map(result, p => {
                            p.orderId = self.orderId;
                            return new DocumentPromptGroup(p);
                        });
                        if (_.some(self.activePromptDocuments, d => d.prompts.length > 0)) {
                            self.launchPromptEntry();
                            return false;
                        } else {
                            self.finishAndPreview().then(r => {
                                return r;
                            });
                        }
                    })
                    .catch(err => {
                        self.handleRequestError(err);
                    });
            },

            finishAndPreview () {
                const self = this;
                return self.saveActivePrompts()
                    .then(() => {
                        self.$store.commit(DOCUMENT_MUTATIONS.SET_ACTIVE_PROMPT_DOCUMENTS, self.activePromptDocuments.slice());
                        self.documentsGenerated = true;
                        self.$dialog.open({
                            title: "Preview Documents",
                            height: "95%",
                            width: "95%",
                            resizable: false,
                            component: DocumentViewer,
                            closeOnly: true,
                            closeTitle: "Close",
                            onClose(e) {
                                return e.component.dispose()
                                    .finally(() => {
                                        self.documentsGenerated = false;
                                        _.invoke(self, "$refs.availableDocumentsComponent.gridRefresh");
                                        return true;
                                    });
                            }
                        });
                        return false;
                    });
            },

            saveActivePrompts () {
                const self = this;
                let promptRequests = [];
                _.forEach(self.activePromptDocuments, d => {
                    let doc = _.find(self.queuedDocuments, d2 => d2.orderDocumentID === d.orderDocumentId);
                    _.forEach(d.prompts, p => {
                        if (p.promptLevelType <= 0) return;
                        p.scopeDocumentDescription = doc.description;
                    });

                    d.setOrderId(self.orderId);

                    promptRequests.push(
                        self.saveAndRemovePromptsWithSaveOption(d.prompts, doc.description)
                            .then(pl => {d.prompts = pl;})
                    );
                });
                return Promise.all(promptRequests);
            },

            saveAndRemovePromptsWithSaveOption (promptInfoList, docDescription) {
                const self = this;
                let unsavedPromptInfoList = [];
                return new Promise((resolve, reject) => {
                    let promptRequests = [];
                    _.forEach(promptInfoList, pi => {
                        if(!pi.isVisible) return; 

                        pi.ordersID = self.orderId;
                        if (pi.prompt.requiresSave) {
                            promptRequests.push(self.savePromptValue(pi.prompt));
                        } else {
                            unsavedPromptInfoList.push(pi);
                        }

                        if (pi.promptDataType === 6 && pi.prompt.standardLanguageList.length > 0) {
                            _.forEach(pi.prompt.standardLanguageList, sl => {
                                if (sl.prompts.length === 0) return;

                                const slPrompts = _.cloneDeep(sl.prompts);
                                _.forEach(slPrompts, p => {
                                    if (p.promptLevelType <= 0) return;
                                    p.scopeDocumentDescription = docDescription;
                                });

                                promptRequests.push(
                                    self.saveAndRemovePromptsWithSaveOption(slPrompts, docDescription)
                                        .then(slPromptResult => {
                                            unsavedPromptInfoList.push(...slPromptResult);
                                        })
                                );
                            });
                        }
                    });
                    Promise.all(promptRequests)
                        .then(() => {
                            resolve(unsavedPromptInfoList);
                        });
                });
            },

            savePromptValue (prompt) { return this.$store.dispatch(DOCUMENT_ACTIONS.SAVE_PROMPT_VALUE, prompt); },

            close () { this.$emit("close-dialog"); },

            updateDialogOptions(opts){ this.$emit("update-dialog-options", opts); },

            clearState() { this.$store.dispatch(DOCUMENT_ACTIONS.CLEAR_STATE); },

            handleRequestError (err) {
                console.error(err);
                this.errorMessage = "An error occurred while processing the selected documents.  Please try again.";
                this.$toast.error({ message: this.errorMessage });
            }
        }
    };
</script>
