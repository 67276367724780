import mime from "mime";

export class OrderPrintDocumentSourceType {
    static get Unknown () {return 0;}
    static get Document () {return 1;}
    static get Package () {return 2;}

    static displayValue (id) {
        switch (id) {
            case 1:
                return "Document";
            case 2:
                return "Package";
        }
        return "Unknown";
    }
}

export class OrderPrintDocumentType {
    static get Unknown () {return 0;}
    static get UserSelected () {return 1;}
    static get Endorsement () {return 2;}
    static get Workflow () {return 3;}

    static displayValue (id) {
        switch (id) {
            case 1:
                return "User Selected";
            case 2:
                return "Endorsement";
            case 3:
                return "Workflow";
        }
        return "Unknown";
    }
}

export class PromptSaveOption {
    static get SaveAlways () {return 0;}
    static get SaveChanged () {return 1;}
    static get DontSave () {return 2;}
    static get SaveBlanks () {return 3;}
    static get SaveDefaults () {return 4;}

    static displayValue (id) {
        switch (id) {
            case 1:
                return "Save When Changed";
            case 2:
                return "Don't Save";
            case 3:
                return "Save Including Blanks";
            case 4:
                return "Save Including Defaults";
        }
        return "Save Always";
    }
}

const documentFileTypes = { PDF:1, PDF_COMBINED:100, JPG:2, BMP:3, GIF:4, TIF:5, DOC:6, RTF:7, DOCX:8, PPTX:9, XLSX:10, XLS:11, XML:12, TXT:13, HTML:14, HTM:15, OTHER:16, PNG:17, MSG:18, DOCM:19, ZIP:20, CSV:21, JSON:22, EML: 23, SFDT:101 };
const saveDocumentFileType = { PDF:1, PDF_COMBINED:100, RTF:7, DOC:6, DOCX:8, };
export class DocumentFileType {
    static get PDF () {return documentFileTypes.PDF;}
    static get PDF_COMBINED () {return documentFileTypes.PDF_COMBINED;}
    static get JPG () {return documentFileTypes.JPG;}
    static get BMP () {return documentFileTypes.BMP;}
    static get GIF () {return documentFileTypes.GIF;}
    static get TIF () {return documentFileTypes.TIF;}
    static get TIFF () {return documentFileTypes.TIFF;}
    static get DOC () {return documentFileTypes.DOC;}
    static get RTF () {return documentFileTypes.RTF;}
    static get DOCX () {return documentFileTypes.DOCX;}
    static get PPTX () {return documentFileTypes.PPTX;}
    static get XLSX () {return documentFileTypes.XLSX;}
    static get XLS () {return documentFileTypes.XLS;}
    static get XML () {return documentFileTypes.XML;}
    static get TXT () {return documentFileTypes.TXT;}
    static get HTML () {return documentFileTypes.HTML;}
    static get HTM () {return documentFileTypes.HTM;}
    static get OTHER () {return documentFileTypes.OTHER;}
    static get PNG () {return documentFileTypes.PNG;}
    static get MSG () {return documentFileTypes.MSG;}
    static get DOCM () {return documentFileTypes.DOCM;}
    static get ZIP () {return documentFileTypes.ZIP;}
    static get CSV () {return documentFileTypes.CSV;}
    static get JSON () {return documentFileTypes.JSON;}
    static get SFDT () {return documentFileTypes.SFDT;}
    static get EML () { return documentFileTypes.EML; }

    static get lookupItems () {
        return _.map(documentFileTypes, c => { return { id: c, name: DocumentFileType.displayValue(c) }; });
    }
    static get saveLookupItems () {
        return _.map(saveDocumentFileType, t => ({ id: t, name: DocumentFileType.displayValue(t) }));
    }

    static displayValue (id) {
        switch (id) {
            case 1: return "PDF";
            case 100: return "PDF(Combined)";
            case 2: return "JPG";
            case 3: return "BMP";
            case 4: return "GIF";
            case 5: return "TIF";
            case 6: return "DOC";
            case 7: return "RTF";
            case 8: return "DOCX";
            case 9: return "PPTX";
            case 10: return "XLSX";
            case 11: return "XLS";
            case 12: return "XML";
            case 13: return "TXT";
            case 14: return "HTML";
            case 15: return "HTM";
            case 16: return "OTHER";
            case 17: return "PNG";
            case 18: return "MSG";
            case 19: return "DOCM";
            case 20: return "ZIP";
            case 21: return "CSV";
            case 22: return "JSON";
            case 23: return "EML";
            case 101: return "SFDT";
        }
        return "INVALID TYPE";
    }

    static fileExtension (id) {
        switch (id) {
            case 1:
            case 100: return ".pdf";
            case 2: return ".jpg";
            case 3: return ".bmp";
            case 4: return ".gif";
            case 5: return ".tif";
            case 7: return ".rtf";
            case 6: return ".doc";
            case 8: return ".docx";
            case 9: return ".pptx";
            case 10: return ".xlsx";
            case 11: return ".xls";
            case 12: return ".xml";
            case 13: return ".txt";
            case 14: return ".html";
            case 15: return ".htm";
            case 16: return "";
            case 17: return ".png";
            case 18: return ".msg";
            case 19: return ".docm";
            case 20: return ".zip";
            case 21: return ".csv";
            case 22: return ".json";
            case 23: return ".eml";
            case 101: return ".sfdt";
        }
        return "";
    }

    static contentType (id) {
        switch (id) {
            case 1:
            case 100: return "application/pdf";
            case 2: return "image/jpeg";
            case 3: return "image/bmp";
            case 4: return "image/gif";
            case 5: return "image/tiff";
            case 7: return "application/rtf";
            case 6: return "application/msword";
            case 8: return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            case 9: return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
            case 10: return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            case 11: return "application/vnd.ms-excel";
            case 12: return "text/xml";
            case 13: return "text/plain";
            case 14:
            case 15: return "text/html";
            case 16: return "application/octet-stream";
            case 17: return "image/png";
            case 18: return "application/vnd. ms-outlook";
            case 19: return "application/vnd.ms-word.document";
            case 20: return "application/zip";
            case 21: return "text/csv";
            case 23: return "application/octet-stream";
        }
        return "";
    }

    static mimeType(id) {
        let ext = this.fileExtension(id);
        return mime.getType(ext);
    }

    static fromFileExtension (ext) {
        switch (ext) {
            case ".pdf": return 1;
            case ".jpg": return 2;
            case ".jpeg": return 2;
            case ".bmp": return 3;
            case ".gif": return 4;
            case ".tif": return 5;
            case ".tiff": return 5;
            case ".doc": return 6;
            case ".rtf": return 7;
            case ".docx": return 8;
            case ".pptx": return 9;
            case ".xlsx": return 10;
            case ".xls": return 11;
            case ".xml": return 12;
            case ".txt": return 13;
            case ".html": return 14;
            case ".htm": return 15;
            case "": return 16;
            case ".png": return 17;
            case ".msg": return 18;
            case ".docm": return 19;
            case ".zip": return 20;
            case ".csv": return 21;
            case ".eml": return 23;
            case ".sfdt": return 101;
        }
        return 16;
    }

    static iconSymbol(id) {
        switch (id) {
            case 1:
            case 100: return "rq-far-file-pdf";// .pdf
            case 2: return "rq-far-file-image";// ".jpg"
            case 3: return "rq-far-file-image";// ".bmp"
            case 4: return "rq-far-file-image";// ".gif"
            case 5: return "rq-far-file-image";// ".tif"
            case 7: return "rq-far-file-text";// ".rtf"
            case 6: return "rq-far-file-word";// ".doc"
            case 8: return "rq-far-file-word";// ".docx"
            case 9: return "rq-far-file-powerpoint";// ".pptx"
            case 10: return "rq-far-file-excel";// ".xlsx"
            case 11: return "rq-far-file-excel";// ".xls"
            case 12: return "rq-far-file-code";// ".xml"
            case 13: return "rq-far-file-text";// ".txt"
            case 14: return "rq-far-file-code";// ".html"
            case 15: return "rq-far-file-code";// ".htm"
            case 16: return "rq-far-file";// "other"
            case 17: return "rq-far-file-image";// ".png"
            case 18: return "rq-far-file-text";// ".msg"
            case 19: return "rq-far-file-text";// ".docm"
            case 20: return "rq-far-file-archive";// ".zip"
            case 21: return "rq-far-file-excel";// ".csv"
            case 101: return "rq-far-file-code";// ".sfdt"
        }
        return "rq-far-file";
    }
}

const saveDocumentAction = {
    Abort: 0,
    Replace: 1,
    Rename: 2
};
export class SaveDocumentAction {
    static get Abort () {return saveDocumentAction.Abort;}
    static get Replace () {return saveDocumentAction.Replace;}
    static get Rename () {return saveDocumentAction.Rename;}
    static get lookupItems () {
        return _.map(saveDocumentAction,
            a => {
                return { id: a, name: SaveDocumentAction.displayValue(a) };
            });
    }

    static displayValue (id) {
        switch (id) {
            case saveDocumentAction.Abort:
                return "Do nothing with this document";
            case saveDocumentAction.Replace:
                return "Replace the existing document";
            case saveDocumentAction.Rename:
                return "Create a unique document";
        }
        return "";
    }
}
const saveDocumentLocation = {
    Local: 1,
    DocumentManagement: 2
};
export class SaveDocumentLocation {
    static get Local () {return saveDocumentLocation.Local;}
    static get DocumentManagement () {return saveDocumentLocation.DocumentManagement;}
    static lookupItems (canAttachToDocMgmt) {
        if (canAttachToDocMgmt) {
            return _.map(saveDocumentAction,
                a => {
                    return { id: a, name: SaveDocumentLocation.displayValue(a) };
                });
            } else {
                return { id: 1, name: SaveDocumentLocation.displayValue(1) };
            }
    }

    static displayValue (id) {
        switch (id) {
            case saveDocumentAction.Local:
                return "To My Computer";
            case saveDocumentAction.DocumentManagement:
                return "To Document Management";
        }
        return "";
    }
}

export class ContentDataFormat {
    static get UnencodedString () {return 1;}
    static get Base64String () {return 2;}
    static get Uint8Array () {return 3;} // binary
}

const fileScanDocumentTypes = {Attached: 1, Web: 2, Live: 3, Scanned: 4};
export class FileScanDocumentType {
    static get Attached () {return 1;}
    static get Web () {return 2;}
    static get Live () {return 3;}
    static get Scanned () {return 4;}

    static get lookupItems () {
        return _.map(fileScanDocumentTypes, c => { return { id: c, name: FileScanDocumentType.displayValue(c) }; });
    }

    static displayValue (id) {
        switch (id) {
            case 1:
                return "Attached";
            case 2:
                return "Web";
            case 3:
                return "Live";
            case 4:
                return "Scanned";
        }
        return "";
    }
}

const promptLevelType = {
    Unknown: -2147483648,
    Global: 0,
    ThisDocument: 1
};
export class PromptLevelType {
    static get Unknown () {return promptLevelType.Unknown;}
    static get Global () {return promptLevelType.Global;}
    static get ThisDocument () {return promptLevelType.ThisDocument;}
    static get lookupItems () {
        return _.map(promptLevelType, a => { return { id: a, name: PromptLevelType.displayValue(a) }; });
    }
    static displayValue (id) {
        return _.join(_.words(_.findKey(promptLevelType, v => v === id)), " ");
    }
}

const operatorType = {
    equals: 0,
    notEquals: 1,
    greaterThan: 2,
    greaterThanOrEquals: 3,
    lessThan: 4,
    lessThanOrEquals: 5,
    in: 6
};
export class OperatorType {
    static get Equals () { return operatorType.equals; }
    static get NotEquals () { return operatorType.notEquals; }
    static get GreaterThan () { return operatorType.greaterThan; }
    static get GreaterThanOrEquals () { return operatorType.greaterThanOrEquals; }
    static get LessThan () { return operatorType.lessThan; }
    static get LessThanOrEquals () { return operatorType.lessThanOrEquals; }
    static get In () { return operatorType.in; }

    static evalValue(id) {
        switch(id) {
            case operatorType.equals: return "==";
            case operatorType.notEquals: return "<>";
            case operatorType.greaterThan: return ">";
            case operatorType.greaterThanOrEquals: return ">";
            case operatorType.lessThan: return ">";
            case operatorType.lessThanOrEquals: return ">";
            case operatorType.in: return "in";
        }
        return "";
    }
}
