<template>
    <div :class="rootClassAttr">
        <div class="rq-list-type-variant rq-list-type-none" @click="localValue = TextControlListType.None"><span>None</span></div>
        <div class="rq-list-type-variant rq-list-type-bulleted" @click="localValue = TextControlListType.Bulleted">
            <div class="fake-ul">
                <div v-for="n in 3" :key="n" class="fake-li">
                    <div class="fake-li-label">{{bulletLabel}}</div>
                    <div class="fake-li-content"></div>
                </div>
            </div>
        </div>
        <div class="rq-list-type-variant rq-list-type-numberd" @click="localValue = TextControlListType.Numbered">
            <div class="fake-ol">
                <div v-for="(labelValue, index) in numberLabels" :key="index" class="fake-li">
                    <div class="fake-li-label">{{textBefore}}{{labelValue}}{{textAfter}}</div>
                    <div class="fake-li-content"></div>
                </div>
            </div>
        </div>
        <div class="rq-list-type-variant rq-list-type-structured" @click="localValue = TextControlListType.Structured">
            <div class="fake-ml-ol">
                <div class="fake-li">
                    <div class="fake-li-label">{{textBefore}}{{numberLabels[0]}}{{textAfter}}</div>
                    <div class="fake-li-content"></div>
                    <div class="fake-li">
                        <div class="fake-li-label">{{textBefore}}{{numberLabels[1]}}.1{{textAfter}}</div>
                        <div class="fake-li-content"></div>
                        <div class="fake-li">
                            <div class="fake-li-label">{{textBefore}}{{numberLabels[2]}}.1.1{{textAfter}}</div>
                            <div class="fake-li-content"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { NumberFormat, TextControlListType } from "@/shared/models/enums";

    export default {
        name: "ListStyleSelector",
        props: {
            modelValue: { type: Number, default: 1 },
            bulletCharacter: { type: String, default: String.fromCharCode(183) },
            numberFormat: { type: Number, default: 1 },
            firstNumber: { type: [Number,String], default: 1 },
            textBefore: { type: String, default: "" },
            textAfter: { type: String, default: "" }
        },

        data () {
            return {
                localValue: 1,
                bulletLabel: String.fromCharCode(183),
                numberLabels: [1, 2, 3]
            };
        },

        computed: {
            rootClassAttr () {return `rq-list-type ${this.selectedClassName}`;},
            selectedClassName () {
                switch (this.localValue) {
                    case TextControlListType.Bulleted:
                        return "rq-list-bulleted-selected";
                    case TextControlListType.Numbered:
                        return "rq-list-numberd-selected";
                    case TextControlListType.Structured:
                        return "rq-list-structured-selected";
                }
                return "rq-list-none-selected";
            },
            maxLabelLength () {return _.reduce(this.numberLabels, (a, v) => {return v.toString().length > a ? v.toString().length : a;}, 1);}
        },

        watch: {
            modelValue (newValue, oldValue) {
                if (newValue === oldValue) return;
                this.localValue = newValue || 1;
            },
            localValue (newValue, oldValue) {
                if (newValue === oldValue || newValue === this.modelValue) return;
                this.$emit("update:modelValue", newValue);
            },
            firstNumber (newValue, oldValue) {
                if (newValue === oldValue) return;
                this.buildNumberLabels();
            },
            numberFormat (newValue, oldValue) {
                if (newValue === oldValue) return;
                this.buildNumberLabels();
            },
            bulletCharacter (newValue, oldValue) {
                if (newValue === oldValue) return;
                this.bulletLabel = (newValue) || "•";
            }
        },

        created () {
            const self = this;
            this.TextControlListType = TextControlListType;
            this.localValue = this.modelValue || 1;
            this.bulletLabel = this.bulletCharacter || "•";
            this.buildNumberLabels();
        },

        methods: {
            buildNumberLabels () {
                let result = [];
                let val = this.firstNumber || 1;
                this.numberLabels = [
                    _.toListNumberChar(val, this.numberFormat),
                    _.toListNumberChar(++val, this.numberFormat),
                    _.toListNumberChar(++val, this.numberFormat)
                ];
            },
            getLabelWidth (offset = 0) {
                let maxLabelLength = this.maxLabelLength + offset;
                if (maxLabelLength === 1) return `width: ${offset + 1}em;`;
                let emVal = length * 0.8;
                return `width: ${emVal}em;`;
            },
            getContentWidth (offset = 0) {
                let maxLabelLength = this.maxLabelLength + offset;
                if (maxLabelLength === 1) return `width: calc(100% - ${offset + 1}em);`;
                let emVal = length * 0.7;
                return `width: calc(100% - ${emVal}em);`;
            }
        }
    };
</script>