<template>
    <ul class="nav document-filters ms-auto">
        <li class="nav-item mx-1">
            <b-form-checkbox
                automation_id="chk_order_docs_only"
                size="sm"
                v-model="fileDocumentsOnlyValue">File Docs Only
            </b-form-checkbox>
        </li>
        <li class="nav-item mx-1">
            <b-form-checkbox
                automation_id="chk_packages_only"
                size="sm"
                v-model="packagesOnlyValue">Packages Only
            </b-form-checkbox>
        </li>
        <li class="nav-item me-2">
            <dx-tag-box
                :element-attr="{
                    automation_id: 'tb_state_filter'
                }"
                placeholder="Filter by State..."
                :data-source="usStatesLookup"
                class="form-control form-control-sm"
                :drop-down-options="{
                    wrapperAttr: {
                        class: 'no-select-all'
                    },
                    width: 200
                }"
                display-expr="name"
                value-expr="id"
                width="150"
                :max-displayed-tags="maxDisplayedTags"
                :show-selection-controls="true"
                :show-drop-down-button="true"
                :show-clear-button="true"
                :multiline="false"
                :search-enabled="false"
                :use-item-text-as-title="true"
                apply-value-mode="useButtons"
                v-model:value="selectedStatesValue"
                @multi-tag-preparing="onStateMultiTagPreparing"
            />
        </li>
    </ul>
</template>
<script setup>
    import { ref, computed } from "vue";
    import { useStore } from "vuex";
    import { useVModel } from "@vueuse/core";

    const props = defineProps({
        fileDocumentsOnly: { type: Boolean, default: false },
        packagesOnly: { type: Boolean, default: false },
        selectedStates: { type: Array, default: () => [] }
    });

    const emit = defineEmits([
        "update:fileDocumentsOnly",
        "update:packagesOnly"
    ]);

    const vuexStore = useStore();

    const fileDocumentsOnlyValue = useVModel(props, "fileDocumentsOnly", emit);
    const packagesOnlyValue = useVModel(props, "packagesOnly", emit);
    const selectedStatesValue = useVModel(props, "selectedStates", emit);

    const maxDisplayedTags = ref(1);
    const usStatesLookup = computed(() => vuexStore.getters.usStatesLookup);

    function onStateMultiTagPreparing(e) {
        let items = e.selectedItems || [];
        if(items.length > maxDisplayedTags.value) {
            let selectedStateList = _.join(_.map(items, "name"), ", ");
            e.text = `${items.length} states`;
            e.multiTagElement.attr("title", selectedStateList);
        }
        else {
            e.cancel = true;
        }
    }
</script>