<template>
    <div class="rq-doc-dialog stored-procedure-form">
        <div class="row stored-procedure-info">
            <div class="col col-12 col-lg-6">
                <rqdx-action-data-grid
                    ref="procDataGrid"
                    automation_id="tbl_procedures"
                    title="Stored Procedures"
                    title-size="sm"
                    :data-source="procDataSource"
                    :config="procGridConfig"
                    export-file-name="procedures-data"
                    class="grid-content-container"
                    hide-search
                    hide-show-column-chooser
                    @selectionChanged="procGridSelectionChanged"
                />
            </div>
            <div class="col col-12 col-lg-6" v-if="showDetails">
                <rq-scroll-container class="settings-scroller">
                    <rqdx-action-data-grid
                        ref="fieldsDataGrid"
                        title="Select Output Field"
                        title-size="sm"
                        class="fields-data-grid"
                        automation_id="tbl_fields"
                        :data-source="fieldsDataSource"
                        :config="fieldsGridConfig"
                        export-file-name="field-data"
                        hide-search
                        hide-show-column-chooser
                        @selectionChanged="fieldsGridSelectionChanged"
                    />
                    <rq-page-section title="Settings" class="proc-settings" borderless v-show="hasFieldSelected">
                        <div class="row">
                            <div class="col col-12 col-sm-12 col-md-4 form-group">
                                <label>Format</label>
                                <dx-select-box
                                    v-if="isNumericValue"
                                    :items="moneyFormatOptions"
                                    value-expr="id"
                                    display-expr="name"
                                    v-model="moneyFormat"
                                />
                                <dx-select-box
                                    v-else-if="isDateValue"
                                    :items="dateFormatOptions"
                                    value-expr="id"
                                    display-expr="name"
                                    v-model="dateFormat"
                                />
                                <dx-select-box
                                    v-else
                                    :items="stringFormatOptions"
                                    value-expr="id"
                                    display-expr="name"
                                    v-model="stringFormat"
                                />
                            </div>
                            <div v-if="showCustomFormat" class="col col-12 col-sm-12 col-md-4 form-group">
                                <label>Format String</label>
                                <input automation_id="txt_custom_date_format" v-model="formatString" type="text" class="form-control" id="txt_custom_date_format"/>
                            </div>
                        </div>
                        <div v-if="showTextImageSettings" class="row">
                            <div class="col col-12 col-sm-12 col-md-6 form-group">
                                <label>Font</label>
                                <dx-select-box
                                    :items="fontOptions"
                                    item-template="item"
                                    v-model="textImageSettings.fontName">
                                    <template #item="{ data }">
                                        <div>
                                            <div v-if="data === 'MICR Encoding'">{{data}}</div>
                                            <div v-else :style="{ 'font-family': data }">{{data}}</div>
                                        </div>
                                    </template>
                                </dx-select-box>
                            </div>
                            <div class="col col-12 col-sm-12 col-md-6 form-group">
                                <label>Font Size</label>
                                <dx-select-box
                                    :items="fontSizeOptions"
                                    v-model="textImageSettings.fontSize"
                                />
                            </div>
                            <div class="col col-12 col-sm-12 col-md-6 form-group">
                                <label>Text Color</label>
                                <dx-color-box v-model="textImageSettings.textColor" />
                            </div>
                            <div class="col col-12 col-sm-12 col-md-6 form-group">
                                <label>Background Color</label>
                                <dx-color-box v-model="textImageSettings.backColor" />
                            </div>
                        </div>
                        <div v-if="isNumericValue || isDateValue" class="row">
                            <div class="col col-12 col-md-12 col-lg-8 form-group">
                                <label for="txt_sample">Example</label>
                                <input automation_id="txt_sample" v-model="formatSample" type="text" class="form-control" id="txt_sample" :readonly="true"/>
                            </div>
                        </div>
                    </rq-page-section>
                </rq-scroll-container>
            </div>
        </div>
        <statement-preview :statement="currentStatement"/>
    </div>
</template>
<script>

    import DxColorBox from "devextreme-vue/color-box";
    import StatementPreview from "./StatementPreview";
    import { StoredProcedureInfoModel, StoredProcedureDataModel, PromptLookupOptions, PromptDataFormat } from "./models";
    import { DateTime } from "luxon";

    export default {
        name: "StoredProcedureValueForm",
        components: {DxColorBox, StatementPreview},
        props: {
            storedProcedures: { type: Array, default: () => [] }
        },
        data(){
            return {
                selectedStoredProcedure: null,
                selectedField: null,
                availableStoredProcedures: [],
                moneyFormat: PromptDataFormat.None,
                dateFormat: PromptDataFormat.Numeric,
                stringFormat: PromptDataFormat.None,
                formatString: "",
                formatSample: "",
                serverFormatSample: "",
                textImageSettings: {
                    fontName: "Arial",
                    fontSize: "12",
                    textColor: "#000000",
                    backColor: "#FFFFFF"
                }
            }
        },
        created(){
            this.initializeData();
            this.loadGridConfig();
        },
        computed:{
            procDataGrid(){ return _.get(this, "$refs.procDataGrid.gridInstance", null) || {}; },
            fieldsDataGrid(){ return _.get(this, "$refs.fieldsDataGrid.gridInstance", null) || {}; },
            hasFieldSelected() { return !_.isEmpty(this.selectedField); },
            currentStatement(){
                const self = this;
                if(!self.selectedField) return "";

                let formatParameter = "";

                if(self.isDateValue || self.isNumericValue){
                    let id = self.isDateValue
                        ? self.dateFormat
                        : self.moneyFormat;
                    let selectedFormat = _.find(PromptLookupOptions.dataFormat, { id });
                    formatParameter = `~Format=${selectedFormat.value}`;
                    if(self.showCustomFormat){
                        formatParameter = formatParameter + `~CustomFormat=${self.formatString}`;
                    }
                }
                else if(self.stringFormat === PromptDataFormat.TextImage) {
                    let textParts = _.values(this.textImageSettings);
                    let textImageValue = _.joinParts(textParts, ";", false);
                    formatParameter = `~Format=TextImage~Options=${textImageValue}`;
                }

                return `[SPV: @${self.selectedStoredProcedure.alias}.${self.selectedField.name}${formatParameter}]`;
            },

            showDetails(){
                return !_.isNil(this.selectedStoredProcedure);
            },

            isDateValue(){
                if(_.isNil(this.selectedField)) return false;
                return this.selectedField.dataType === "DateTime";
            },

            isNumericValue(){
                if(_.isNil(this.selectedField)) return false;
                switch(this.selectedField.dataType){
                    case "Int16":
                    case "Int32":
                    case "Int64":
                    case "Decimal":
                    case "Double":
                        return true;
                    default:
                        return false;
                }
            },

            showCustomFormat(){
                return (this.isDateValue && this.dateFormat === PromptDataFormat.Custom)
                    || (this.isNumericValue && this.moneyFormat === PromptDataFormat.Custom);
            },

            showTextImageSettings() { return this.stringFormat === PromptDataFormat.TextImage; },

            moneyFormatOptions(){
                return PromptLookupOptions.moneyFormat;
            },

            dateFormatOptions(){
                return PromptLookupOptions.dateFormat;
            },

            stringFormatOptions(){
                return PromptLookupOptions.stringFormat;
            },

            fontOptions() {
                return [ "Arial", "Verdana", "Helvetica", "MICR Encoding", "Tahoma", "Trebuchet MS", "Times New Roman", "Georgia", "Garamond", "Courier New", "Brush Script MT" ];
            },

            fontSizeOptions() {
                return ["8", "10", "11", "12", "14", "16", "18", "20", "24", "28", "36", "48", "72", "80", "90", "96", "105", "120", "144"];
            }
        },
        watch:{
            selectedStoredProcedure(newValue){
                if(!this.selectedStoredProcedure.isHydrated)
                    this.hydrateSelected();
                else
                    this.refreshSettingsGrids();
            },
            moneyFormat(newValue){
                this.generateFormatSample();
            },
            dateFormat(newValue){
                this.generateFormatSample();
            },
            formatString(newValue){
                this.generateFormatSample();
            },
            isNumericValue(newValue){
                if(!newValue) return;
                this.generateFormatSample();
            },
            isDateValue(newValue){
                if(!newValue) return
                this.generateFormatSample();
            },
        },
        methods:{

            initializeData(){
                const self = this;
                _.forEach(self.storedProcedures, sp => {
                    self.availableStoredProcedures.push(new StoredProcedureInfoModel(sp));
                });
            },

            loadGridConfig(){
                const self = this;

                self.procGridConfig = {
                    selection: {
                        mode: "single"
                    },
                    columns: [
                        {
                            dataField: "name",
                            caption: "Stored Procedure"
                        },
                        {
                            dataField: "alias",
                            caption: "Alias"
                        }
                    ],
                }
                self.procDataSource = {
                    key: "clientKey",
                    load (loadOptions) {
                        return Promise.resolve(self.availableStoredProcedures);
                    },
                }
                self.fieldsGridConfig = {
                    height: 250,
                    selection: {
                        mode: "single"
                    },
                    columns: [
                        {
                            dataField: "name",
                            caption: "Field"
                        },
                        {
                            dataField: "dataType",
                            caption: "Data Type"
                        },
                    ],
                }
                self.fieldsDataSource = {
                    key: "clientKey",
                    load (loadOptions) {
                        return Promise.resolve(self.selectedStoredProcedure.resultColumns);
                    },
                }
            },

            procGridSelectionChanged(e){
                const self = this;
                if(e.selectedRowsData.length === 0) return;
                let selectedItem = e.selectedRowsData[0];
                self.selectedStoredProcedure = selectedItem;
            },

            fieldsGridSelectionChanged(e){
                const self = this;
                if(e.selectedRowsData.length === 0) return;
                let selectedItem = e.selectedRowsData[0];
                self.selectedField = selectedItem;
            },

            hydrateSelected(){
                const self = this;
                if(_.isNil(self.selectedStoredProcedure)) return;

                let promise = self.$api.DocumentTemplatesApi.fillStoredProcedure(self.selectedStoredProcedure).then(result => {
                    self.selectedStoredProcedure.isHydrated = true;
                    _.forEach(result.parameters, p => {
                        self.selectedStoredProcedure.parameters.push(new StoredProcedureDataModel(p));
                    });
                    _.forEach(result.resultColumns, c => {
                        self.selectedStoredProcedure.resultColumns.push(new StoredProcedureDataModel(c));
                    });
                    self.refreshSettingsGrids();
                }).catch(error => {
                    let msg = error.errorMessage || error.message;
                    self.$toast.error(msg);
                    self.refreshSettingsGrids();
                });

                self.$rqBusy.wait(promise);
            },

            refreshSettingsGrids(){
                const self = this;
                self.fieldsDataGrid.refresh();
            },

            generateFormatSample:_.debounce(function(){
                const self = this;
                if(_.isNil(self.selectedField)){
                    self.formatSample = "";
                    return;
                }

                if(!(self.isDateValue || self.isNumericValue)){
                    self.formatSample = "";
                    return;
                }

                let request = {
                    isNumeric: self.isNumericValue,
                    customFormatString: self.formatString
                };
                if(self.isNumericValue){
                    request.format = self.moneyFormat;
                    request.sampleNumericValue = self.showCustomFormat ? 100000 : 10;
                }
                else {
                    request.format = self.dateFormat;
                    request.sampleDateValue = DateTime.now().toLocaleString(DateTime.DATE_SHORT);
                }

                let promise = self.$api.DocumentTemplatesApi.generateFormatSample(request)
                    .then(result => {
                        self.formatSample = result;
                    })
                    .catch(error => {
                        var msg = error.errorMessage || error.message;
                        self.$toast.error(msg);
                    });

                return promise;
            }, 200)
        }
    }
</script>