<template>
    <standard-language-selection
        ref="selectionComponent"
        :promptInfo="promptInfo"
        v-model:standard-language-selected="addedStandardLanguage"
    />
</template>
<script>
import { DocumentPromptInfo, StandardLanguageModel } from '../../models';
import StandardLanguageSelection from './StandardLanguageSelection';
export default {
    components: {
        StandardLanguageSelection
    },
    props: {
        promptInfo: {
            default: function () {
                return new DocumentPromptInfo();
            }
        }
    },
    data: function() {
        return {
            addedStandardLanguage: []
        }
    },
    methods: {
        getItemPrompts(standardLanguageItem) {
            const self = this;
            return self.$api.DocumentsApi.getStandardLanguageSubPrompts(standardLanguageItem, self.promptInfo.orderDocumentID, self.promptInfo.ordersID)
                .then(result => {
                    standardLanguageItem.ordersID = self.promptInfo.ordersID;
                    standardLanguageItem.orderDocumentID = self.promptInfo.orderDocumentID;
                    standardLanguageItem.orderDocumentDescription = self.promptInfo.scopeDocumentDescription;
                    standardLanguageItem.prompts = _.map(result, p => {
                        let dpi = new DocumentPromptInfo(p);
                        dpi.ordersID = self.promptInfo.ordersID;
                        dpi.scopeDocumentDescription = self.promptInfo.scopeDocumentDescription;
                        return dpi;
                    });
                    return standardLanguageItem;
                })
                .catch(err => {
                    self.$toast.error(`The prompt request for clause, ${standardLanguageItem.description}, failed.  Change your selection and try again.`);
                    throw err;
                });
        },
        populateStandardLanguageList() {
            let self = this;

            self.promptInfo.prompt.standardLanguageList = [];

            this.addedStandardLanguage.forEach((rowData) => {
                if(rowData.isPackage) {
                    let packageItems = _.map(rowData.packageItems, item => item.data)
                    packageItems.forEach(item => {
                        if(!_.some(self.promptInfo.prompt.standardLanguageList, currentItem => currentItem.id === item.id))
                            self.promptInfo.prompt.standardLanguageList.push(item);
                    });
                }
                else {
                    if(!_.some(self.promptInfo.prompt.standardLanguageList, currentItem => currentItem.id === rowData.id))
                        self.promptInfo.prompt.standardLanguageList.push(rowData);
                }
            });

            let promptPromises = _.map(self.promptInfo.prompt.standardLanguageList, (i) => self.getItemPrompts(i));
            return self.$rqBusy.wait(Promise.all(promptPromises))
                .catch(err => {
                    self.$toast.error("An issue occured while retrieving prompts for the selected clauses.");
                });
        },
        focus() {
            _.invoke(this, "$refs.selectionComponent.focus");
        }
    }
}
</script>