<template>
    <transition name="simple-fade">
        <div v-show="loadProgressActive"
            class="load-progress-container">
            <div class="card load-progress-card">
                <div class="card-header">
                    <h3>{{title}}</h3>
                    <button
                        type="button"
                        class="btn btn-icon"
                        @click="loadProgressActive=false">
                        <FontAwesomeIcon icon="fas fa-times" />
                    </button>
                </div>
                <div
                    v-if="displayMessageOnFailure && loadFailure"
                    class="failure-message">
                    Some of the items being loaded had issues.  You can click the <FontAwesomeIcon icon="fa fa-info-circle" class="info-icon" /> icon next to the failed item(s) for more information,
                    the <em>Retry</em> button to try loading them again, and the <em>Ignore</em> button to close this dialog.
                </div>
                <div class="card-block">
                    <div v-if="itemsExist" class="load-progress">
                        <div v-for="item in items" :key="item.key" class="progress-item">
                            <span class="item-name lead">{{item.label}}</span>
                            <span
                                v-if="item.status === ITEM_STATUS.success"
                                class="item-status item-status-loaded">
                                <FontAwesomeIcon icon="fas fa-check" />
                            </span>
                            <span
                                v-else-if="item.status === ITEM_STATUS.failure"
                                class="item-status item-status-error">
                                <FontAwesomeIcon icon="fas fa-times" class="error-icon" />
                                <FontAwesomeIcon icon="fas fa-info-circle" class="info-icon"
                                     title="Error Info"
                                     @click="toggleErrorDisplay" />
                            </span>
                            <span v-else
                                class="sk-fading-circle inline-spinner">
                                <div v-for="n in 12" :class="'sk-circle' + n + ' sk-circle'" :key="n"></div>
                            </span>
                            <div v-if="item.status === ITEM_STATUS.failure"
                                 class="item-load-result">
                                <BCollapse
                                    :visible="showErrorMsg">
                                    <div
                                        class="result-message"
                                        v-html="item.loadResult">
                                    </div>
                                </BCollapse>
                            </div>
                        </div>
                    </div>
                    <div v-else class="no-items">
                        <span>No items to load.</span>
                    </div>
                </div>
                <div v-if="loadComplete && loadFailure" class="card-footer">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        @click="onIgnore">Ignore
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="onRetry">Retry
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>
<script setup>
    import { computed, ref, watch } from "vue";
    import { ProgressItem } from "@/shared/models/models";

    const props = defineProps({
        title: { type: String, default: "Loading..." },
        failureMessage: { type: String, default: "" },
        items: { type: Array, default: () => [] },
        displayMessageOnFailure: { type: Boolean, default: true },
        visible: { type: Boolean, default: false }
    });

    const emit = defineEmits(["update:visible", "complete", "retry", "ignore"]);

    const showErrorMsg = ref(false);

    const ITEM_STATUS = ProgressItem.status;

    const itemsProp = computed(() => props.items);
    const itemsExist = computed(() => !_.isEmpty(props.items));
    const loadComplete = computed(() => itemsExist.value && _.every(props.items, item => item.status !== ITEM_STATUS.loading));
    const loadSuccess = computed(() => itemsExist.value && _.every(props.items, item => item.status !== ITEM_STATUS.loading && item.status !== ITEM_STATUS.failure));
    const loadFailure = computed(() => itemsExist.value && loadComplete.value && _.some(props.items, item => item.status === ITEM_STATUS.failure));

    const loadProgressActive = computed({
        get() { return props.visible; },
        set(val) { emit("update:visible", val); }
    })

    watch(itemsProp, newValue => {
        if(newValue?.length === 0 || !loadComplete.value) return;
        onComplete();
    }, { deep: true });

    const toggleErrorDisplay = () => showErrorMsg.value = !showErrorMsg.value;

    function reset (failedOnly = false) {
        let resetItems = [];
        _.forEach(props.items, item => {
            if (item.status !== ITEM_STATUS.failure && failedOnly) return;

            item.status = ITEM_STATUS.loading;
            item.loadResult = "";

            resetItems.push(new ProgressItem(item));
        });
        return resetItems;
    }

    const currentStatus = () => loadComplete.value
        ? loadSuccess.value
            ? ITEM_STATUS.success
            : ITEM_STATUS.failure
        : ITEM_STATUS.loading;

    const resetFailed = () => reset(true);

    const onComplete = () => emit("complete", {
        success: loadSuccess.value,
        failed: _.filter(props.items, item => item.status === ITEM_STATUS.failure)
    });
    const onRetry = () => emit("retry");
    const onIgnore = () => emit("ignore", { failed: _.filter(props.items, item => item.status === ITEM_STATUS.failure) });

    defineExpose({
        currentStatus,
        resetFailed
    });
</script>