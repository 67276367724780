<template>
    <div class="rq-doc-dialog rq-tv-layout">
        <div class="row">
            <div class="col col-4 col-pc-3 rq-col-tree">
                <rq-tree-view
                    ref="treeView"
                    id="rq-mf-tree-view"
                    title="Prompts"
                    :items="treeItems"
                    v-model="selectedItemKey"
                    @item-selected="onItemSelected"
                    show-title-border>
                    <template #item-label="{ item }">
                        {{item.displayName}}
                    </template>
                </rq-tree-view>
            </div>
            <div class="col col-8 col-pc-9 rq-col-form">
                <div class="rq-stmt-form-container">
                    <rq-scroll-container>
                        <rq-no-data v-if="noSelection" text="Please Select a Prompt from the tree to the left" />
                        <div v-else class="row">
                            <div class="col col-12">
                                <rq-page-section title="Prompt Settings" :borderless="!isDropDown">
                                    <fieldset>
                                        <div class="row">
                                            <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                                                <label for="txt_prompt_name">Name</label>
                                                <input automation_id="txt_prompt_name" v-model="selectedPrompt.name" type="text" class="form-control" id="txt_prompt_name" :readonly="true"/>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col col-12 col-sm-12 col-md-12 col-lg-12 form-group">
                                                <label for="txt_prompt_text">Prompt Text</label>
                                                <input automation_id="txt_prompt_text" v-model="selectedPrompt.promptText" type="text" class="form-control" id="txt_prompt_text" :readonly="true"/>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col col-4 col-sm-12 col-md-4 col-lg-4 form-group">
                                                <label for="drp_data_type">Data Type</label>
                                                <rq-select-box
                                                    :items="dataTypeOptions"
                                                    :default-item-enabled="false"
                                                    v-model="selectedPrompt.dataType"
                                                    :disabled="true"
                                                />
                                            </div>
                                            <div class="col col-4 col-sm-12 col-md-4 col-lg-4 form-group">
                                                <label for="drp_level">Level</label>
                                                <rq-select-box
                                                    :items="levelOptions"
                                                    :default-item-enabled="false"
                                                    v-model="selectedPrompt.level"
                                                    :disabled="true"
                                                />
                                            </div>
                                            <div class="col col-4 col-sm-12 col-md-4 col-lg-4 form-group">
                                                <label for="drp_level">Save Options</label>
                                                <rq-select-box
                                                    :items="saveOptions"
                                                    :default-item-enabled="false"
                                                    v-model="selectedPrompt.saveOption"
                                                    :disabled="true"
                                                />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col col-4 col-sm-12 col-md-4 col-lg-4 form-group">
                                                <label for="txt_default_value">Default Value</label>
                                                <input v-if="isDefaultValueString" automation_id="txt_default_value" v-model="selectedPrompt.defaultValue" type="text" class="form-control" id="txt_default_value" :readonly="true"/>
                                                <rqdx-date-box v-if="isDefaultValueDate"
                                                    id="dtp_default_value"
                                                    v-model="selectedPrompt.defaultValue"
                                                    :disabled="true"
                                                />
                                                <div v-if="isDefaultValueMoney" class="input-group">
                                                    <span class="input-group-text">$</span>
                                                    <rqInputNumber
                                                        automation_id="txt_default_money_value"
                                                        defaultValue="0"
                                                        decimals="2"
                                                        cssClass="form-control"
                                                        v-model="selectedPrompt.defaultValue"
                                                        :disabled="true">
                                                    </rqInputNumber>
                                                </div>
                                            </div>
                                            <div v-if="isDefaultValueMoney || isDefaultValueDate" class="col col-4 col-sm-12 col-md-4 col-lg-4 form-group">
                                                <label>Format</label>
                                                <rq-select-box v-if="isDefaultValueMoney"
                                                    :items="moneyFormatOptions"
                                                    :default-item-enabled="false"
                                                    v-model="selectedPrompt.format"
                                                />
                                                <rq-select-box v-if="isDefaultValueDate"
                                                    :items="dateFormatOptions"
                                                    :default-item-enabled="false"
                                                    v-model="selectedPrompt.format"
                                                />
                                            </div>
                                            <div v-if="showCustomFormat" class="col col-4 col-sm-12 col-md-4 col-lg-4 form-group">
                                                <label>Format String</label>
                                                <input automation_id="txt_custom_date_format" v-model="selectedPrompt.formatString" type="text" class="form-control" id="txt_custom_date_format"/>
                                            </div>
                                            <div class="col col-4 col-sm-12 col-md-6 col-lg-4 form-group" v-if="isDefaultValueMoney || isDefaultValueDate">
                                                <label for="txt_sample">Example</label>
                                                <input automation_id="txt_sample" v-model="formatSample" type="text" class="form-control" id="txt_sample" :readonly="true"/>
                                            </div>
                                            <div class="col col-12 col-sm-12 col-md-6 col-lg-4 form-group">
                                                <label></label>
                                                <b-form-checkbox
                                                    automation_id="chk_required"
                                                    id="chk_required"
                                                    v-model="selectedPrompt.isRequired">Prompt Value Required</b-form-checkbox>
                                            </div>
                                        </div>
                                    </fieldset>
                                </rq-page-section>
                                <rq-page-section title="Drop Down List Items" v-show="isDropDown">
                                    <rqdx-action-data-grid
                                        ref="dataGrid"
                                        automation_id="tbl_prompt_value_drop_down_items"
                                        :data-source="gridDataSource"
                                        :config="gridConfig"
                                        export-file-name="drop-down-item-data"
                                        hide-search
                                        hide-show-column-chooser
                                    />
                                </rq-page-section>
                            </div>
                        </div>
                    </rq-scroll-container>
                </div>
                <statement-preview :statement="currentStatement"/>
            </div>
        </div>
    </div>
</template>

<script>

    import StatementPreview from "./StatementPreview";
    import { TreeViewItemModel } from "@/shared/models/rq-ui";
    import { StatementHelpers } from "./helpers";
    import { ClientDropDownListItem, PromptLookupOptions, PromptDataType, PromptDataFormat } from "./models";

    export default {
        name: "PromptValueForm",
        components: {StatementPreview},
        props: {
            prompts: {type: Array, default: () => []}
        },
        data(){
            return {
                treeItems:[],
                selectedItemKey: null,
                selectedItem: null,
                selectedPrompt: null,
                formatSample: ""
            }
        },
        computed:{
            dataGrid(){ return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },

            dataTypeOptions(){
                return PromptLookupOptions.dataType;
            },
            levelOptions(){
                return PromptLookupOptions.level;
            },
            saveOptions(){
                return PromptLookupOptions.saveOption;
            },
            moneyFormatOptions(){
                return PromptLookupOptions.moneyFormat;
            },
            dateFormatOptions(){
                return PromptLookupOptions.dateFormat;
            },
            dataFormatOptions(){
                return PromptLookupOptions.dataFormat;
            },
            isDefaultValueString(){
                return this.selectedPrompt.dataType !== PromptDataType.Money && this.selectedPrompt.dataType !== PromptDataType.Date;
            },
            isDefaultValueMoney(){
                return this.selectedPrompt.dataType === PromptDataType.Money;
            },
            isDefaultValueDate(){
                return this.selectedPrompt.dataType === PromptDataType.Date;
            },
            isDropDown(){
                return this.selectedPrompt.dataType === PromptDataType.DropDown;
            },
            showCustomFormat(){
                return this.selectedPrompt.dataType === PromptDataType.Date && this.selectedPrompt.format === PromptDataFormat.Custom;
            },
            currentStatement(){
                return StatementHelpers.buildPromptValueStatement(this.selectedPrompt);
            },
            noSelection(){
                return _.isNil(this.selectedPrompt);
            },
            dropDownValues(){
                if(_.isNil(this.selectedPrompt)) return [];
                return _.map(this.selectedPrompt.dropDownValues, v => new ClientDropDownListItem({value: v}));
            }
        },
        watch:{
            "selectedPrompt.format":function(newValue){
                this.generateFormatSample();
            },
            "selectedPrompt.formatString":function(newValue){
                this.generateFormatSample();
            }
        },
        created(){
            this.treeItems = StatementHelpers.mapPromptTreeItems(this.prompts);
            this.loadGridConfig();
        },
        mounted() {
            this.$emit("disable-ok");
        },
        methods:{
            loadGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "value",
                            dataType: "string",
                            caption: "Value",
                            allowEditing: true,
                        },
                    ],
                }
                self.gridDataSource = {
                    key: "clientKey",
                    load (loadOptions) {
                        return Promise.resolve(self.dropDownValues);
                    }
                }
            },

            onItemSelected(e){
                this.selectedItem = e.selectedItem;
                this.selectedPrompt = _.find(this.prompts, p => { return p.name === e.selectedItem.name; })
                this.$emit("enable-ok");
                if(!this.isDropDown || !_.isFunction(this.dataGrid.refresh)) return;
                this.dataGrid.refresh();
            },

            generateFormatSample:_.debounce(function(){
                const self = this;
                if(_.isNil(self.selectedPrompt)) return;

                if(!(self.selectedPrompt.dataType === PromptDataType.Money || self.selectedPrompt.dataType === PromptDataType.Date)) return;
                let isMoney = self.selectedPrompt.dataType === PromptDataType.Money;
                let request = {};
                if(isMoney){
                    request.isNumeric = true;
                    request.format = self.selectedPrompt.format;
                    request.sampleNumericValue = _.parseNumber(self.selectedPrompt.defaultValue, 0);
                }
                else{
                    request.isNumeric = false;
                    request.format = self.selectedPrompt.format;
                    request.sampleDateValue = self.selectedPrompt.defaultValue;
                    request.customFormatString = self.selectedPrompt.formatString;
                }

                let promise = self.$api.DocumentTemplatesApi.generateFormatSample(request).then(
                    result => {
                        self.formatSample = result;
                    }
                ).catch(error => {
                    var msg = error.errorMessage || error.message;
                    self.$toast.error(msg);
                });

                return promise;
            }, 200)
        }
    }
</script>