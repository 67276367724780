<template>
    <RqTabs
        :tabs="tabItems"
        :flex="false"
        v-model="tabIndex">
        <template #instance>
            <div class="row">
                <div class="col-6 form-group">
                    <label>Type:</label>
                    <rq-select-box
                        :items="instanceTypeItems"
                        :default-item-enabled="false"
                        v-model="field.instanceType"
                        @change="onInstanceTypeChange"
                    />
                </div>
                <div class="col-6 form-group">
                    <label>Index:</label>
                    <rq-input-number
                        default-value="0"
                        min-value="0"
                        value-event="input"
                        v-model="field.instanceIndex"
                    />
                </div>
            </div>
        </template>
        <template #merge-loop>
            <div class="row">
                <div class="col-6">
                    <rq-action-form-group
                        label="Repeat:"
                        labelFor="txt_merge_loop_repeat"
                        show-action
                    >
                        <template #action>
                            <b-form-checkbox
                                automation_id="chk_repeat_table_rows"
                                v-model="repeatTableRows">Repeat table rows
                            </b-form-checkbox>
                        </template>
                        <template #default>
                            <rq-input-number
                                id="txt_merge_loop_repeat"
                                automation_id="txt_merge_loop_repeat"
                                defaultValue="0"
                                minValue="0"
                                value-event="input"
                                v-model="mergeLoopRepeat"
                            />
                        </template>
                    </rq-action-form-group>
                </div>
                <div class="col-6">
                    <rq-action-form-group
                        label="Separator:"
                        labelFor="txt_merge_loop_separator"
                        show-action
                    >
                        <template #action>
                            <b-form-checkbox
                                automation_id="chk_include_cr_per_row"
                                v-model="includeCRPerRow">Include carriage return per row
                            </b-form-checkbox>
                        </template>
                        <template #default>
                            <input
                                id="txt_merge_loop_separator"
                                automation_id="txt_merge_loop_separator"
                                class="form-control"
                                v-model="mergeLoopSeparator"
                            />
                        </template>
                    </rq-action-form-group>
                </div>
            </div>
        </template>
    </RqTabs>
</template>

<script>
    import { mapGetters } from "vuex";
    import { RoleType } from "@/shared/models/enums";
    import { StatementHelpers } from "./helpers";

    export default {
        name:"MergeLoopForm",
        props: {
            field: { type: Object, default: () => ({}) },
            modelValue: { type: String, default: "" }
        },
        data () {
            return {
                tabIndex: 0,
                mergeLoopRepeat: null,
                mergeLoopSeparator: null,
                repeatTableRows: false,
                includeCRPerRow: false,
                useLoopOptions: false,
                allRoles: [],
                tabItems: [
                    { title: "Instance", name: "instance" },
                    { title: "Merge Loop", name: "merge-loop" }
                ]
            };
        },

        computed: {
            ...mapGetters([
                "lookupHelpers"
            ]),
            instanceTypeItems() { return StatementHelpers.getInstanceTypes(this.field, this.allRoles); },
            statementString() { return this.getStatementString(); }
        },

        watch: {
            tabIndex(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.reset();
            },
            statementString: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.$emit("update:modelValue", newValue);
                },
                immediate: true
            }
        },

        created() {
            this.allRoles = this.lookupHelpers.getRoles();
        },

        methods: {
            onInstanceTypeChange(e) {
                if(e.selectedValue === "Any" || this.field.instanceIndex > 0) return;
                this.field.instanceIndex = 1;
            },

            getStatementString() {
                let stmtParts = [];
                if(_.parseNumber(this.mergeLoopRepeat, 0) > 0) {
                    stmtParts.push(`~Repeat=${this.mergeLoopRepeat}`);
                }
                if(this.repeatTableRows) {
                    stmtParts.push("~TableRowRepeat=1");
                }
                if(!_.isEmpty(this.mergeLoopSeparator) || this.includeCRPerRow) {
                    let separatorString = `~Separator=${this.mergeLoopSeparator || ""}`;
                    if(this.includeCRPerRow)
                        separatorString += "CRLF";
                    stmtParts.push(separatorString);
                }
                let optionString = stmtParts.join("");
                return `[ML:${this.field.objectPath}${optionString}][ENDMERGE]`;
            },

            updateObjectPath() {},

            reset() {
                this.mergeLoopRepeat = null;
                this.mergeLoopSeparator = null;
                this.repeatTableRows = false;
                this.includeCRPerRow = false;
                this.useLoopOptions = false;
                this.field.instanceIndex = 0;
                this.field.instanceType = "Any";
            }
        }
    };
</script>