<template>
    <div class="rq-container rq-doc-dialog insert-documents">
        <div class="row">
            <div class="col col-12 col-lg-6 d-flex flex-column">
                <rq-page-section title="Filters" header-size="sm" borderless>
                    <div class="row">
                        <div class="col form-group mb-0">
                            <label>Description:</label>
                            <input
                                automation_id="txt_filter_description"
                                type="text"
                                class="form-control form-control-sm"
                                v-model="descriptionFilter"
                            >
                        </div>
                        <div v-if="showRegionFilter" class="col col-12 col-md-6 form-group mb-0">
                            <label>Region:</label>
                            <rq-select-box
                                id="drp_filter_region"
                                data-text-field="displayName"
                                data-value-field="regionID"
                                size="sm"
                                :items="regions"
                                v-model="selectedRegionId"
                            />
                        </div>
                    </div>
                </rq-page-section>
                <div class="grid-container">
                    <rqdx-data-grid
                        ref="dataGrid"
                        id="dg_insert_doc_dlg"
                        automation_id="dg_insert_doc_dlg"
                        :dataSource="gridDataSource"
                        :config="gridConfig"
                        @selectionChanged="onSelectionChanged"
                    />
                </div>
            </div>
            <div class="col col-12 col-lg-6">
                <rq-page-section title="Document Options" header-size="sm" borderless>
                    <div class="row">
                        <div class="col col-auto form-group">
                            <b-form-checkbox automation_id="chk_keep_doc_font" v-model="keepDocFont">Keep Doc Font</b-form-checkbox>
                        </div>
                        <div class="col col-auto form-group">
                            <b-form-checkbox automation_id="chk_bullets" v-model="bullets">Bullets</b-form-checkbox>
                        </div>
                        <div class="col col-12">
                            <fieldset :disabled="!bullets">
                                <div class="row">
                                    <div class="col-6 form-group">
                                        <label>Bullet Style:</label>
                                        <rq-select-box
                                            id="drp_bullet_styles"
                                            :items="bulletStyles"
                                            :default-item-enabled="false"
                                            v-model="bulletStyle"
                                        />
                                    </div>
                                    <div class="col-6 form-group">
                                        <label>First Number:</label>
                                        <rq-input-number id="txt_first_number" v-model="firstNumber" />
                                    </div>
                                    <div class="col-6 form-group">
                                        <label>Left Indent:</label>
                                        <rq-input-number id="txt_left_indent" v-model="leftIndent" />
                                    </div>
                                    <div class="col-6 form-group">
                                        <label>Hanging Indent:</label>
                                        <rq-input-number id="txt_hanging_indent" v-model="hangingIndent" />
                                    </div>
                                    <div class="col-6 form-group">
                                        <label>Text Before Bullet:</label>
                                        <input automation_id="txt_text_before" type="text" class="form-control" v-model="textBefore">
                                    </div>
                                    <div class="col-6 form-group">
                                        <label>Text After Bullet:</label>
                                        <input automation_id="txt_text_after" type="text" class="form-control" v-model="textAfter">
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </rq-page-section>
            </div>
        </div>
        <statement-preview :statement="currentStatement"/>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { DocumentTemplateDto } from "@documents/models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import DxFilterBuilder from "@/shared/utilities/DxFilterBuilder";
    import { SearchRequest } from "@/shared/models/models";
    import { NumberFormat } from "@/shared/models/enums";
    import StatementPreview from "./StatementPreview";

    export default {
        name:"InsertDocumentForm",
        components: { StatementPreview },
        props: {
            documentTemplateId: { type: Number, default: null },
            documentTemplateRegionId: { type: Number, default: null }
        },
        data () {
            const self = this;
            return {
                descriptionFilter: "",
                selectedRegionId: null,
                selectedItem: {},
                bullets: false,
                keepDocFont: false,
                bulletStyle: 0,
                firstNumber: 1,
                leftIndent: 0,
                hangingIndent: 0,
                textBefore: null,
                textAfter: null
            };
        },

        computed: {
            ...mapGetters([
                "lookupHelpers"
            ]),
            allRegions() { return this.lookupHelpers.getRegions(); },
            regions() {
                return _.filter(this.allRegions, r =>
                    _.parseBool(r.isAdminRegion) ||
                    r.regionID === this.documentTemplateRegionId
                );
            },
            bulletStyles() { return NumberFormat.lookupItems; },
            dataGridComponent() { return _.get(this, "$refs.dataGrid", null) || null; },
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || null; },
            currentStatement() {
                if(_.isEmpty(this.selectedItem)) return "";
                let keepDocFontStmt = this.keepDocFont ? "~KeepDocFont=True" : "";
                let bulletStmt = "";
                if(this.bullets) {
                    let bulletParts = [
                        "~Bullet=1",
                        this.textBefore,
                        this.textAfter,
                        this.hangingIndent,
                        this.firstNumber,
                        this.leftIndent
                    ];
                    bulletStmt = _.join(bulletParts, ";");
                }
                return `[RTFDOCUMENT:Name=${this.selectedItem.name}~RegID=${this.selectedItem.regID || 0}${keepDocFontStmt}${bulletStmt}]`;
            },
            adminRegionId() {
                let adminRegion = _.find(this.regions, r => _.parseBool(r.isAdminRegion));
                return adminRegion.regionID;
            },
            showRegionFilter() { return this.regions.length > 1; }
        },

        watch: {
            descriptionFilter(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.filterDocuments();
            },
            selectedRegionId(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.filterDocuments();
            }
        },

        created() {
            const self = this;
            self.initGridConfig();
        },

        methods: {

            initGridConfig(){
                const self = this;

                self.gridConfig = {
                    columns: [
                        { dataField: "name", dataType: "string" }, 
                        { dataField: "description", dataType: "string" }
                    ],
                    height: "100%",
                    remoteOperations: true,
                    hoverStateEnabled: true,
                    // scrolling: { mode: "virtual", rowRenderingMode: "virtual", useNative: false },
                    selection: { mode: "single" },
                    paging: { pageIndex: 0, pageSize: 50 }
                };

                self.gridDataSource = {
                    key: "documentTemplateID",
                    load: self.fetchData
                };
            },

            appendFilter(filterExpr, currentFilters=[]) {
                let filters = currentFilters.slice();
            },

            getCurrentSearchRequest (loadOptions) {
                const self = this;
                let request = new SearchRequest({
                    pageNumber: 1,
                    pageSize: 50,
                    pagingEnabled: true,
                    searchTerm: self.descriptionFilter
                });
                request.parseLoadOptions(loadOptions);
                let filterBuilder = new DxFilterBuilder();
                if(!_.isNil(self.documentTemplateId))
                    filterBuilder.append(["documentTemplateID", "<>", self.documentTemplateId]);
                if(!_.isNil(self.selectedRegionId))
                    filterBuilder.append(["regionID", "=", self.selectedRegionId]);
                else if(!_.isNil(self.documentTemplateRegionId))
                    filterBuilder.append([["regionID", "=", self.documentTemplateRegionId], "or", ["regionID", "=", self.adminRegionId]]);
                request.filter = filterBuilder.filterExpression;
                return request;
            },

            filterDocuments: _.debounce(function() {
                if(!this.gridInstance) return;
                this.gridInstance.refresh();
            }, 350, { "leading": false, "trailing": true }),

            fetchData(loadOptions) {
                const self = this;
                let request = self.getCurrentSearchRequest(loadOptions);
                let apiPromise = self.$api.DocumentTemplatesApi.search(request);
                return self.$rqBusy.wait(apiPromise, { targetComponent: self.dataGridComponent })
                    .then(response => {
                        return {
                            data: _.map(response.results, item => new DocumentTemplateDto(item)),
                            totalCount: response.totalRecords
                        };
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error(`Error loading ${self.itemTypeNamePlural}.`);
                        return error;
                    });
            },
            // RQ1 restricts to numbers in this dialog no matter what; making rq-input-number until it's determined whether it should function differently
            // onFirstNumberKeyPress (e) {
            //     let charCode = e.key.charCodeAt(0);
            //     let validCodes = CommonUtil.numberCharCodes();
            //     switch (this.numberFormat) {
            //         case NumberFormat.Letters:
            //             validCodes = CommonUtil.alphaCharCodes();
            //             break;
            //         case NumberFormat.CapitalLetters:
            //             validCodes = CommonUtil.alphaCharCodes(true);
            //             break;
            //         case NumberFormat.RomanNumbers:
            //             validCodes = CommonUtil.romanCharCodes(true);
            //             break;
            //         case NumberFormat.SmallRomanNumbers:
            //             validCodes = CommonUtil.romanCharCodes();
            //             break;
            //     }

            //     if (!_.includes(validCodes, charCode)) e.preventDefault();
            // },
            onSelectionChanged(e) {
                this.selectedItem = _.isEmpty(e.selectedRowsData) ? null : e.selectedRowsData[0];
            }
        }
    };
</script>