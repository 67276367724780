<template>
    <div class="card prompt-group-card">
        <div class="card-header">
            <span>{{groupTitle}}</span>
            <div class="prompt-group-actions">
                <slot name="prompt-group-actions"></slot>
            </div>
        </div>
        <div class="card-body">
            <div v-for="promptInfo in visiblePrompts" :key="promptInfo.clientKey" class="row">
                <div class="col-lg-12">
                    <standard-language-prompt
                        v-if="promptInfo.prompt.promptDataType === PromptDataType.StandardLanguage"
                        ref="promptComponents"
                        :promptInfo="promptInfo"
                    />
                    <prompt-field v-else
                        ref="promptComponents"
                        :key="promptInfo.clientKey"
                        :prompt="promptInfo.prompt"
                        :event-prefix="fieldEventPrefix"
                        @blur="onFieldBlur($event, promptInfo)"
                        show-label
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PromptField from "./PromptField";
    import { PromptDataType } from "../../models";
    import StandardLanguagePrompt from "./StandardLanguagePrompt";

    export default {
        components: { PromptField, StandardLanguagePrompt },

        props: {
            documentIndex: { default: 0 },
            groupTitle: { default: "" },
            prompts: { default: () => [] },
            fieldEventPrefix: { default: null }
        },

        data() {
            return {
                PromptDataType
            }
        },

        created() {
            const self = this;
            //need to initialize visibility based on initial values
            _.forEach(self.prompts, p => {
                if(!p.hasConditions) return;
                _.forEach(p.conditions, c =>{
                    let leftPromptName = c.leftPromptName;
                    let leftPrompt = _.find(self.prompts, x => { return x.promptName === leftPromptName});
                    if(leftPrompt){
                        p.updateAndEvaluateConditions(leftPromptName, leftPrompt.promptValue);
                    }
                    let rightPromptName = c.rightPromptName;
                    let rightPrompt = _.find(self.prompts, x => { return x.promptName === rightPromptName});
                    if(rightPrompt){
                        p.updateAndEvaluateConditions(rightPromptName, rightPrompt.promptValue);
                    }
                });
                // conditions that are NOT prompt based (i.e. static MergeField) need to be evaluated upfront and then don't need to be re-evaluated
                p.conditions.filter(c => c.evalReady && !c.evaluated).forEach(c => c.evaluate())
            });
        },

        computed: {
            visiblePrompts() { return _.filter(this.prompts, "isVisible")},
            hasStandardLanguagePrompts() { return _.some(this.prompts, p => p.prompt.promptDataType === PromptDataType.StandardLanguage); }
        },

        methods: {
            async loadPrompts() {
                if(!this.hasStandardLanguagePrompts) return;
                for (let i = 0; i < this.visiblePrompts.length; i++) {
                    if(this.visiblePrompts[i].prompt.promptDataType !== PromptDataType.StandardLanguage) continue;
                    let matchedPromptComponent = this.$refs.promptComponents.find(pc => pc?.promptInfo?.clientKey && pc.promptInfo.clientKey === this.visiblePrompts[i].clientKey)

                    if(matchedPromptComponent)
                        await matchedPromptComponent.populateStandardLanguageList();
                }
            },
            focus() {
                this.focusFirstPrompt();
            },
            focusFirstPrompt() {
                _.invoke(this, "$refs.promptComponents[0].focus");
            },
            focusLastPrompt() {
                _.invoke(this, `$refs.promptComponents[${this.prompts.length - 1}].focus`);
            },
            onFieldBlur(e, promptInfo) {
                _.forEach(this.prompts, p => {
                    if(!p.hasConditions || promptInfo.promptName === p.promptName) return;
                    p.updateAndEvaluateConditions(promptInfo.promptName, e.value);
                });
            }
        }
    };
</script>