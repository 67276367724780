<template>
    <div class="rq-doc-dialog rq-tv-layout">
        <div class="row">
            <div class="col col-4 col-pc-3 rq-col-tree">
                <merge-field-tree
                    ref="mergeFieldTree"
                    id="rq-mf-tree-view"
                    title="Fields"
                    :items="treeState.items"
                    v-model:scroll-position="treeViewScrollPosition"
                    v-model="selectedItemKey"
                    @item-selected="onItemSelected"
                    show-title-border
                />
            </div>
            <div class="col col-8 col-pc-9 rq-col-form">
                <div class="rq-stmt-form-container">
                    <rq-no-data v-if="noSelection || !selectedItem.mergeable" :text="noDataText" />
                    <div v-else class="row">
                        <div class="col col-12">
                            <rq-page-section title="Data Type:" borderless>
                                <template #header-actions>
                                    <div class="rq-mf-data-type">{{selectedItem.dataType}}</div>
                                </template>
                                <merge-field-loop-form
                                    v-if="selectedItem.allowMergeLoop"
                                    :field="selectedItem"
                                    v-model="fieldStatement"
                                />
                                <merge-field-string-form
                                    v-if="selectedItem.dataType === 'String'"
                                    :field="selectedItem"
                                    v-model="fieldStatement"
                                />
                                <merge-field-format-form
                                    v-if="selectedItem.dataType === 'Integer' || selectedItem.dataType === 'Decimal'"
                                    :field="selectedItem"
                                    :formats="integerFormats"
                                    :custom-format-value="11"
                                    v-model="fieldStatement"
                                />
                                <merge-field-format-form
                                    v-if="selectedItem.dataType === 'DateTime' || selectedItem.dataType === 'Date'"
                                    :field="selectedItem"
                                    :formats="dateFormats"
                                    :custom-format-value="6"
                                    v-model="fieldStatement"
                                />
                            </rq-page-section>
                        </div>
                    </div>
                </div>
                <statement-preview :statement="currentStatement"/>
            </div>
        </div>
    </div>
</template>

<script>
    import MergeFieldTree from "../MergeFieldTree";
    import MergeFieldStringForm from "./MergeFieldStringForm";
    import MergeFieldFormatForm from "./MergeFieldFormatForm";
    import MergeFieldLoopForm from "./MergeFieldLoopForm";
    import StatementPreview from "./StatementPreview";
    import { MergeFieldTreeItem } from "./models.js";

    export default {
        name:"MergeFieldSelection",
        components: { MergeFieldTree, MergeFieldLoopForm, MergeFieldStringForm, MergeFieldFormatForm, StatementPreview },
        props: {
            treeState: { type: Object, default: () => ({
                items: [],
                selectedKey: null,
                scrollPosition: 0
            })}
        },
        data () {
            const self = this;
            return {
                selectedItem: {},
                fieldStatement: "",
                integerFormats: [
                    { id: 1, name: "None", statementValue: "None", example: "10.0000" },
                    { id: 2, name: "Money-Numeric", statementValue: "MoneyNumeric", example: "$10.00" },
                    { id: 3, name: "Money-Words", statementValue: "MoneyWords", example: "Ten and 00/100" },
                    { id: 4, name: "Numeric-Words", statementValue: "NumericWords", example: "$10.00 (Ten and 00/100)" },
                    { id: 5, name: "Words-Numeric", statementValue: "WordsNumeric", example: "Ten and 00/100 ($10.00)" },
                    { id: 6, name: "Numeric-No-Decimal", statementValue: "NumericFormatted", example: "10" },
                    { id: 7, name: "Numeric-One-Decimal", statementValue: "NumericFormattedOptionA", example: "10.0" },
                    { id: 8, name: "Numeric-Two-Decimal", statementValue: "NumericFormattedOptionB", example: "10.00" },
                    { id: 9, name: "Numeric-Three-Decimal", statementValue: "NumericFormattedOptionC", example: "10.000" },
                    { id: 10, name: "Barcode", statementValue: "Barcode", example: "" },
                    { id: 11, name: "Custom", statementValue: "Custom", example: "#,#.00 - 100,000.00" }
                ],
                dateFormats: [
                    { id: 1, name: "None", statementValue: "None", example: "" },
                    { id: 2, name: "Numeric", statementValue: "Numeric", example: "" },
                    { id: 3, name: "Words", statementValue: "Words", example: "" },
                    { id: 4, name: "Words-Formal", statementValue: "WordsFormal", example: "" },
                    { id: 5, name: "Barcode", statementValue: "Barcode", example: "" },
                    { id: 6, name: "Custom", statementValue: "Custom", example: "" }
                ],
                selectedItemKey: _.get(self, "treeState.selectedKey", null),
                treeViewScrollPosition: _.getNumber(self, "treeState.scrollPosition", 0)
            };
        },

        computed: {
            noSelection() { return _.isEmpty(this.selectedItem); },
            noDataText() {
                if(this.noSelection) return "Select a field from the tree to the left.";
                if(!this.selectedItem.mergeable) return "Selection cannot be merged."
                return "";
            },
            currentStatement() {
                if(this.noSelection || (!this.selectedItem.mergeable && !this.selectedItem.isCustomData)) return "";
                let simpleMergeTypes = ["Boolean", "Time", "Byte[]", "Binary", "Image"];
                if(_.includes(simpleMergeTypes, this.selectedItem.dataType)) {
                    return `[MF:${this.selectedItem.objectPath}]`;
                }
                return this.fieldStatement;
            }
        },

        created() {
            const self = this;
            self.treeViewDataSource = { load: self.fetchChildData };
        },

        methods: {
            onItemSelected(e) {
                this.selectedItem = e.selectedItem;
            },

            getTreeState() {
                const self = this;
                return {
                    selectedKey: self.selectedItemKey,
                    items: _.invoke(self, "$refs.mergeFieldTree.getFieldTreeItems", null) || [],
                    scrollPosition: self.treeViewScrollPosition
                };
            }
        }
    };
</script>