<template>
    <div :class="{ 'form-group': true, 'form-required': prompt.isRequired, 'has-error': !prompt.isValid }">
        <label v-if="showLabel">{{prompt.promptText}}</label>

        <rqdx-date-box v-if="isDateType"
            ref="dateField"
            :input-attr="{ automation_id: automationId }"
            display-format="MM/dd/yyyy"
            :use-mask-behavior="true"
            class="form-control"
            :disabled="disabled"
            v-model="currentValue"
            @blur="onFieldBlur"
        />

        <rq-textarea v-else-if="isMemoType"
            ref="memoField"
            :automation_id="automationId"
            class="form-control"
            :max-height="88"
            :disabled="disabled"
            v-model="currentValue"
            @change="onInputChange"
            auto-resize
            @blur="onFieldBlur"
        />

        <dx-select-box v-else-if="isYesNoType"
            ref="yesNoField"
            :input-attr="{ automation_id: automationId }"
            class="form-control"
            :items="['Yes','No']"
            :disabled="disabled"
            v-model="currentValue"
            @blur="onFieldBlur"
        />

        <dx-select-box v-else-if="isSelectType"
            ref="selectField"
            :input-attr="{ automation_id: automationId }"
            class="form-control"
            :items="prompt.dropDownItems"
            :disabled="disabled"
            v-model="currentValue"
            @blur="onFieldBlur"
        />

        <div v-else-if="isStandardLanguage">
            <div v-for="standLang in prompt.standardLanguageList" :key="standLang.clientKey">
                {{standLang.description}}
            </div>
        </div>

        <input v-else
            v-focus-select-all
            ref="inputField"
            :automation_id="automationId"
            class="form-control"
            type="text"
            :disabled="disabled"
            v-model="currentValue"
            @keydown="onInputKeyDown"
            @keyup="onInputKeyUp"
            @change="onInputChange"
            @blur="onFieldBlur"
        />

        <rq-validation-feedback v-if="prompt.isRequired" :no-field-label="!showLabel" />
    </div>
</template>

<script>
    import { DocumentPrompt, DocumentPromptInfo, PromptDataType } from "../../models";
    import { DateTimeHelper } from '@/shared/utilities';

    export default {
        props: {
            prompt: { type: Object, default: () => ({}) },
            eventPrefix: { type: String, default: null },
            showLabel: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false }
        },

        data () {
            return {
                originalValue: null,
                updateAutosizeEvent: null
            };
        },
        watch: {
            currentValue() { this.prompt.validate();}
        },
        computed: {
            automationIdPrefix () {
                switch (this.prompt.dataType) {
                    case 2:
                        return 'dtp';
                    case 3:
                        return 'ta';
                    case 4:
                        return 'drp';
                    case 5:
                        return 'drp';
                    default:
                        return 'txt';
                }
            },
            automationId () { if (this.prompt.promptName) {return `${this.automationIdPrefix}${this.prompt.promptName}`;}  return 'no_id';  },
            isStringType () { return this.prompt.promptDataType === PromptDataType.String; },
            isMoneyType () {return this.prompt.promptDataType === PromptDataType.Money; },
            isDateType () {return this.prompt.promptDataType === PromptDataType.Date; },
            isMemoType () {return this.prompt.promptDataType === PromptDataType.Memo; },
            isYesNoType () {return this.prompt.promptDataType === PromptDataType.YesNo; },
            isSelectType () {return this.prompt.promptDataType === PromptDataType.Select; },
            isStandardLanguage () {return this.prompt.promptDataType === PromptDataType.StandardLanguage; },
            hasValue () {return this.prompt.promptValue && this.prompt.promptValue.trim().length > 0; },
            currentValue: {
                get() {
                    return this.isDateType
                        ? _.parseDate(this.prompt.promptValue)
                        : this.prompt.promptValue;
                },
                set(val) {
                    if(this.disabled) return;
                    if(!this.isDateType) {
                        this.prompt.promptValue = val;
                        this.$emit("blur", { value: this.prompt.promptValue });
                        return;
                    }

                    let isValidDate = DateTimeHelper.isValidDate(val);
                    this.prompt.promptValue = isValidDate
                        ? _.parseDateString(val, null, "MM/dd/yyyy")
                        : null;
                    this.$emit("blur", { value: this.prompt.promptValue });
                }
            }
        },

        created () {
            const self = this;
            self.originalValue = self.prompt.promptValue;
            if(!self.isMemoType || _.isNil(self.eventPrefix)) return;

            self.updateAutosizeEvent = `${self.eventPrefix}:update-autosize`;
            self.$events.on(self.updateAutosizeEvent, self.onUpdateAutosize);

        },

        beforeUnmount() {
            if(!self.isMemoType || _.isNil(self.updateAutosizeEvent)) return;
            self.$events.off(self.updateAutosizeEvent, self.onUpdateAutosize);
        },

        methods: {
            onInputKeyDown (e) {
                if (this.isMoneyType) {
                    this.handleMoneyKeyDown(e);
                }
            },

            onInputKeyUp (e) { },

            onInputChange (e) {
                if (!this.isMoneyType) return;
                this.handleMoneyChange(e);
            },

            onUpdateAutosize(){
                if(!this.$refs.memoField) return;
                this.$refs.memoField.updateAutosize();
            },

            onFieldBlur(e) {
                this.$emit("blur", { value: this.currentValue });
            },

            handleMoneyChange (e) {
                this.currentValue = accounting.formatMoney(this.currentValue);
                this.prompt.promptValue = this.currentValue;
            },

            handleMoneyKeyDown (e) {
                // console.log("key=%s, keyCode=%s, code=%s, which=%s", e.key, e.keyCode, e.code, e.which);

                let validKeyEntry = ((e.keyCode >= 48 && e.keyCode <= 57) ||
                    (e.keyCode >= 96 && e.keyCode <= 105) ||
                    e.keyCode == 110 ||
                    e.keyCode == 188 ||
                    e.keyCode == 190 ||
                    e.keyCode == 8 ||
                    e.keyCode == 9 ||
                    e.keyCode == 13 ||
                    e.keyCode == 37 ||
                    e.keyCode == 39);

                if (!validKeyEntry) {
                    e.preventDefault();
                }

                // -- if currency format needs to be scrutinized --
                // let currencyRegex = new RegExp(/^\$?(\d*\,)*(\d*\.)?\d+$/);
                // let newValue = e.target.value;
                // if (e.keyCode !== 8 && e.key.length === 1)
                //    newValue += e.key;
                // if (!currencyRegex.test(newValue))
                //    this.keyEntryValid = false;
            },

            updateSizeIfNeeded() {
                if(!this.isMemoType || !this.$refs.memoField) return;
                this.$refs.memoField.updateAutosize();
            },

            focus() {
                let fieldExpr = "inputField";
                switch(this.prompt.promptDataType){
                    case PromptDataType.Date:
                        fieldExpr = "dateField.instance";
                        break;
                    case PromptDataType.Memo:
                        fieldExpr = "memoField.$el";
                        break;
                    case PromptDataType.YesNo:
                        fieldExpr = "yesNoField.instance";
                        break;
                    case PromptDataType.Select:
                        fieldExpr = "selectField.instance";
                        break;
                }
                _.invoke(this, `$refs.${fieldExpr}.focus`);
            }
        }
    };
</script>
