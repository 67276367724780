<template>
    <div class="content-wrapper h-100">
        <rq-page-section :title="title" flex-full flex-body borderless>
            <template #header-secondary>
                <div class="rq-content-description">{{instructions}}</div>
            </template>
            <rqdx-action-data-grid
                ref="docDataGrid"
                automation_id="dg_order_fsDocs"
                :actions="selectionActions"
                :data-source="gridDataSource"
                :config="gridConfig"
                :persist-state="false"
                @assign="onAssignItem"
                @rowDoubleClick="onGridRowDoubleClick"
                hide-default-actions
                hide-search
            />
        </rq-page-section>
    </div>
</template>
<script>
    import { mapState } from "vuex";
    import { DocumentFileType, FileScanDocumentType } from "../../enums";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {

        props: {
            items: {type: Array, required: true, default: () => []},
            title: { default: "Document Management" },
            instructions: { default: "Select a document or documents below and click \"Add\" to assign them to the current task."},
            excludeSelectionActions: {type: Boolean, default: () => false }
        },

        data () {
            const self = this;
            return {
            };
        },

        computed: {
            ...mapState({
                order: state => state.orders.order,
            })
        },

        watch: {
            items:{
                handler: function(newValue, oldValue) {
                    if(!_.isEqual(newValue, oldValue)) {
                        this.refresh();
                    }
                },
                deep: true,
                immediate: false
            },
        },

        created () {
            this.initNonReactiveVariables();
            this.initGridConfig();
        },

        methods: {
            clear() {
                let grid = _.get(this.$refs, "docDataGrid.gridInstance", {});
                if (_.isEmpty(grid)) return;
                grid.option("focusedRowIndex", -1);
                grid.clearSelection();
            },
            initGridConfig () {
                const self = this;
                self.gridConfig = {
                    focusedRowEnabled: false,
                    wordWrapEnabled: false,
                    columns: [
                        { dataField: "commonFileName", dataType: "string", caption: "Description" },
                        {
                            dataField: "fileType",
                            dataType: "number",
                            cellTemplate (container, options) {
                                let rowData = options.data;
                                $("<span>")
                                    .text(DocumentFileType.displayValue(rowData.fileType))
                                    .appendTo(container);
                            }
                        },
                        { dataField: "publishedByDisplay", caption: "Author", cellTemplate: DxGridUtils.truncateCellTemplate, allowSearch: false, visible: false},
                        { dataField: "dateModified", dataType: "date", caption: "Date Modified", minWidth: 135, cellTemplate: DxGridUtils.dateTimeCellTemplate, allowSearch: false},
                    ],
                    noDataText: "No Available Documents"
                };
                self.gridDataSource = {
                    key: self.itemKey,
                    loadMode: "raw",
                    load () {
                        return Promise.resolve(self.items);
                    },
                };
            },
            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Document";
                self.itemTypeNamePlural = "Documents";
                self.itemKey = "fileScanDocumentID";
                if(!self.excludeSelectionActions){
                    self.selectionActions = [
                        { name: "assign", text: "Add", eventName: "assign", allowMultiSelection: true },
                    ];
                }
            },

            onGridRowDoubleClick (e) {
                this.$emit("assign-documents", [e.data]);
            },

            onAssignItem (e) {
                if(!e || !e.data) return;
                this.$emit("assign-documents", e.data);
            },

            updateDimensions () {
                let grid = _.get(this.$refs, "docDataGrid.gridInstance", {});
                if (_.isEmpty(grid)) return;
                grid.updateDimensions();
            },

            refresh () {
                let grid = _.get(this.$refs, "docDataGrid.gridInstance", {});
                if (_.isEmpty(grid)) return;
                this.clear();
                grid.refresh();
            },

            getSelectedItems(){
                return this.$refs.docDataGrid.gridInstance.getSelectedRowsData();
            }
        }

    };
</script>