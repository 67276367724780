
import { DocumentModel, DocumentGridModel, DocumentPromptGroup, DocumentPromptInfo, DocumentPrompt } from "./models";
import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
import { DateTime } from "luxon";

const searchHighlightTemplate = '<span class="dx-datagrid-search-text">$&</span>';
const highlightSearchText = (text, searchText="") =>
    _.isEmpty(_.trim(searchText)) ? text : _.replace(text, new RegExp(searchText, "ig"), searchHighlightTemplate);

const getDataRowElement = (item, searchText) => {
    let lastPrintDt = _.parseLuxonDateTime(item.data.lastPrint);
    let descriptionContent = item.data.isOrderItem ? `<strong>${item.data.description}</strong>` : item.data.description;
    return $("<tr />")
        .addClass("dx-row dx-data-row dx-column-lines")
        .addClass(item.rowIndex % 2 ? "dx-row-alt" : "")
        .append($("<td/>").addClass("text-center p-0").append(item.data.isRequired ? "<svg class='bool-cell-icon'><use href='#rq-fas-check'></use></svg>" : ""))
        .append($("<td/>").append(highlightSearchText(descriptionContent, searchText)))
        .append($("<td/>").append(_.replaceDelimiter(item.data.documentCategories, ",", ", ")))
        .append($("<td/>").append(_.isNil(lastPrintDt) ? "" : lastPrintDt.toFormat("MM/dd/yyyy")));
}

const getDocumentRow = (item, searchText) => {
    return getDataRowElement(item, searchText);
};
const getPackageRow = (item, searchText, onToggleCollapse) => {
    const toggleCollapseHandler = function(e) {
        onToggleCollapse(item.key);
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
    };

    let iconId = item.isExpanded ? "rq-fas-folder-open" : "rq-fas-folder";
    let $packageRowDescription = $("<span class='rq-package-description'/>")
        .append(`<svg class='rq-icon-symbol'><use href="#${iconId}"></use></svg>${highlightSearchText(item.data.description, searchText)}`)
        .on("click", toggleCollapseHandler);

    let $packageRowCollapseIcon = $("<span class='rq-package-collapse-icon'/>")
        .append($("<svg class='rq-icon-symbol'><use href='#rq-fas-caret-down'></use></svg>")
            .addClass(item.isExpanded ? "" : "fa-rotate-90")
        ).on("click", toggleCollapseHandler);
    let $packageCellContent = $("<div />")
        .addClass("rq-package-cell-content")
        .append($packageRowDescription)
        .append($packageRowCollapseIcon);

    item.isExpanded = true;

    return $("<tr />")
        .addClass("dx-data-row rq-package-row")
        .append($("<td/>")
            .addClass("rq-package-cell")
            .attr("colspan", 4)
            .append($packageCellContent)
        );
};

export default {
    highlightSearchText,
    mapDocuments (data) {
        let mapped = _.map(data, d => new DocumentGridModel(new DocumentModel({ clientKey:_.parseNumber(_.uniqueId()), ...d })));
        let packageDocs = _.remove(mapped, d => d.documentPackageID > 0);
        let packageGroups = _.groupBy(packageDocs, "documentPackageID");
        let packages = _.map(packageGroups, (g, id) => {
            return new DocumentGridModel({
                clientKey: _.parseNumber(g[0].data.clientKey, 0),
                documentPackageID: _.parseNumber(id, 0),
                description: g[0].data.documentPackageDescription,
                packageDocuments: g
            });
        });
        return _.concat(packages, mapped);
    },

    mapSavedPrompts (promptData) {
        let result = [];
        if(_.isEmpty(promptData)) return result;
        let docPrompts = _.map(promptData, p => new DocumentPrompt(p));
        let globalPrompts = _.remove(docPrompts, "isGlobal");
        let docGroups = _.groupBy(docPrompts, "scopeDocumentDescription");
        result = _.map(docGroups, (prompts, documentName) => new DocumentPromptGroup({ documentName, prompts }, false));
        if(!_.isEmpty(globalPrompts)) {
            result.unshift(new DocumentPromptGroup({
                documentName: "Global",
                prompts: _.sortBy(globalPrompts, "sequence"),
                isGlobal: true
            }, false));
        }
        return result;
    },

    getRowTemplate(item, searchText, onToggleCollapse) {
        return item.data.isPackage
            ? getPackageRow(item, searchText, onToggleCollapse)
            : getDocumentRow(item, searchText);
    },

    createChildGrid(detailElement, detailInfo, columns, onSelectionChanged, onRowDblClick) {
        var packageData = detailInfo.data;
        if(_.isEmpty(packageData.packageDocuments)) return;
        _.each(columns, (c,i) => c.width = detailInfo.component.columnOption(i, "width"));
        detailElement.addClass("internal-grid-container");
        return $("<div>")
            .addClass("internal-grid")
            .dxDataGrid({
                columns,
                showBorders: false,
                showColumnHeaders: false,
                dataSource: packageData.packageDocuments,
                selection: { mode: "multiple", showCheckBoxesMode: "never" },
                rowAlternationEnabled: false,
                paging: { enabled: false },
                onSelectionChanged,
                onRowDblClick,
                hoverStateEnabled: true,
                wordWrapEnabled: false
            })
            .appendTo(detailElement)
            .dxDataGrid("instance");
    }
};
