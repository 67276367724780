<template>
    <div class="rq-list-style">
        <div class="card">
            <div class="card-header-2">Bullets/Numbering</div>
            <div class="card-block">
                <list-style-selector
                    :bullet-character="options.bulletCharacter"
                    :number-format="options.numberFormat"
                    :first-number="options.firstNumber"
                    :text-before="textBefore"
                    :text-after="textAfter"
                    v-model="options.textControlListType"
                />
            </div>
        </div>
        <div class="card">
            <div class="card-header-2">Formatting</div>
            <div class="card-block">
                <div class="row">
                    <div class="col-6 form-group">
                        <label for="txt_bullet_number_position">Bullet/Number Position</label>
                        <rq-input-number
                            id="txt_bullet_number_position"
                            defaultValue="0"
                            append-text="in."
                            :decimals="3"
                            v-model="bulletNumberPosition"
                            input-group
                            v-focus-select-all
                        />
                    </div>
                    <div class="col-6 form-group">
                        <label for="">Separating Character</label>
                        <dx-select-box
                            :input-attr="{ id: 'drp_separating_character', automation_id: 'drp_separating_character' }"
                            value-expr="id"
                            display-expr="name"
                            :items="lookups.formatChars"
                            v-model="options.formatCharacter"
                            @value-changed="onSepCharChange"
                        />
                    </div>
                    <div class="col-6 form-group mb-0">
                        <label for="txt_text_position">Text Position</label>
                        <rq-input-number
                            id="txt_text_position"
                            defaultValue="0"
                            append-text="in."
                            :decimals="3"
                            v-model="textPosition"
                            input-group
                            v-focus-select-all
                        />
                    </div>
                    <div class="col-6 form-group mb-0">
                        <label for="drp_level">Level</label>
                        <dx-select-box
                            :input-attr="{ id: 'drp_level', automation_id: 'drp_level' }"
                            value-expr="value"
                            display-expr="text"
                            :disabled="fixedLevel"
                            :items="lookups.levels"
                            v-model="options.level"
                            @value-changed="onLevelChange"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header-2">Bullet Character</div>
            <div class="card-block">
                <div class="row">
                    <div class="col-6 d-flex flex-column justify-content-end">
                        <div class="row">
                            <div class="col-auto">
                                <div class="btn-group" role="group" aria-label="Bullet Characters">
                                    <b-btn
                                        v-for="(bChar, index) in lookups.bulletChars"
                                        :key="index"
                                        variant="light"
                                        :class="{ 'btn-char':true, 'active': bChar.charCode === currentCharCode }"
                                        :disabled="!bulletedListSelected"
                                        @click="onBulletCharClick(bChar.charCode)">
                                        <span>{{bChar.character}}</span>
                                    </b-btn>
                                    <b-btn v-if="extraCharCode != null"
                                        variant="light"
                                        :class="{ 'btn-char':true, 'active': extraCharCode === currentCharCode }"
                                        :disabled="!bulletedListSelected"
                                        @click="onBulletCharClick(extraCharCode)">
                                        <span>{{String.fromCharCode(extraCharCode)}}</span>
                                    </b-btn>
                                </div>
                            </div>
                            <div class="col">
                                <b-btn
                                    qautomation_id="btn_other_special_chars"
                                    variant="theme"
                                    :disabled="!bulletedListSelected"
                                    @click="onOtherCharactersClick"
                                    block>Other...</b-btn>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 form-group mb-0">
                        <label for="drp_bullet_size">Size</label>
                        <div class="input-group">
                            <dx-select-box
                                :input-attr="{ id: 'drp_bullet_size', automation_id: 'drp_bullet_size' }"
                                value-expr="value"
                                display-expr="text"
                                :items="lookups.bulletSizes"
                                :disabled="!bulletedListSelected"
                                v-model="options.bulletSize"
                            />
                            <span class="input-group-text">pt</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-0">
            <div class="card-header-2">Number Format</div>
            <div class="card-block">
                <div class="row">
                    <div class="col-6 form-group">
                        <label for="drp_number_format">Number Style</label>
                        <dx-select-box
                            :input-attr="{ id: 'drp_number_format', automation_id: 'drp_number_format' }"
                            value-expr="id"
                            display-expr="name"
                            :items="lookups.numberFormats"
                            :disabled="!numberedListSelected"
                            v-model="options.numberFormat"
                        />
                    </div>
                    <div class="col-6 form-group">
                        <label for="drp_text_before">Text Before</label>
                        <dx-select-box
                            :input-attr="{ id: 'drp_text_before', automation_id: 'drp_text_before' }"
                            value-expr="value"
                            display-expr="text"
                            :items="lookups.textBefore"
                            :disabled="!numberedListSelected"
                            v-model="textBefore"
                        />
                    </div>
                    <div class="col-6 form-group mb-0">
                        <label for="txt_start_at">Start At</label>
                        <input
                            id="txt_start_at"
                            automation_id="txt_start_at"
                            type="text"
                            class="form-control"
                            spellcheck="false"
                            :disabled="!numberedListSelected"
                            v-model="firstNumberValue"
                            @keypress="onFirstNumberKeyPress"
                        >
                    </div>
                    <div class="col-6 form-group mb-0">
                        <label for="drp_text_after">Text After</label>
                        <dx-select-box
                            :input-attr="{ id: 'drp_text_after', automation_id: 'drp_text_after' }"
                            value-expr="value"
                            display-expr="text"
                            :items="lookups.textAfter"
                            :disabled="!numberedListSelected"
                            v-model="textAfter"
                        />
                    </div>
                </div>
                <div class="row mb-0">
                    <div class="col-12">
                        <rq-radio-group
                            id="radios2" 
                            name="radioSubComponent" 
                            :disabled="!numberedListSelected" 
                            v-model="restartNumbering"
                            inline
                            :options="[
                                { automation_id: 'rdo_restart_numbering', value: 1, text: 'Restart Numbering'},
                                { automation_id: 'rdo_continue_list', value: 0, text: 'Continue List'}
                            ]"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { DocumentListFormat } from "@/shared/models/models";
    import { NumberFormat, TextControlListType, ListFormatCharacter } from "@/shared/models/enums";
    import ListStyleSelector from "./ListStyleSelector";
    import SpecialCharSelection from "./editor-dialogs/SpecialCharSelection";

    export default {
        name: "DocumentListOptions",

        components: { ListStyleSelector },

        props: {
            options: { type: DocumentListFormat, default: () => new DocumentListFormat() },
            readOnly: { type: Boolean, default: false },
            fixedLevel: { type: Boolean, default: false }
        },

        data () {
            return {
                numberdLabels: ["1", "2", "3"],
                restartNumbering: 1,
                currentCharCode: 0,
                extraCharCode: null,
                lastLevel: 1
            };
        },
        computed: {
            overrideListFormat () {return _.isEqual(this.options);},
            bulletedListSelected () {return this.options.textControlListType === 2;},
            numberedListSelected () {return this.options.textControlListType === 4 || this.options.textControlListType === 8;},
            firstNumberValue: {
                get () { return _.toListNumberChar(this.options.firstNumber, this.options.numberFormat); },
                set (val) { this.options.firstNumber = _.toListNumberValue(val, this.options.numberFormat); }
            },

            bulletNumberPosition: {
                get () { return _.parseNumber(this.options.leftIndent, 0) / 1440; },
                set (val) { this.options.leftIndent = Math.round(val * 1440); }
            },

            textPosition: {
                get () { return (_.parseNumber(this.options.leftIndent, 0) + _.parseNumber(this.options.hangingIndent, 0)) / 1440; },
                set (val) { this.options.hangingIndent = Math.round((val * 1440) - this.options.leftIndent); }
            },

            textBefore: {
                get () {
                    if (!this.options || _.isEmpty(_.trim(this.options.textBeforeNumber, " " + String.fromCharCode(0)))) return null;
                    return this.options.textBeforeNumber;
                },
                set (val) { this.options.textBeforeNumber = val || ""; }
            },
            textAfter: {
                get () {
                    if (!this.options || _.isEmpty(_.trim(this.options.textAfterNumber, " " + String.fromCharCode(0)))) return null;
                    return this.options.textAfterNumber;
                },
                set (val) { this.options.textAfterNumber = val || ""; }
            }
        },
        watch: {
            "options.bulletCharacter" (newValue, oldValue) {
                this.setCurrentCharCode(newValue);
            },
            restartNumbering (newValue, oldValue) {
                this.options.restartNumbering = newValue === 1;
            }

        },

        created () {
            this.buildLookups();
            this.restartNumbering = this.options.restartNumbering ? 1 : 0;
            this.setCurrentCharCode(this.options.bulletCharacter);
        },

        methods: {
            onSepCharChange(e) {
                if(e.selectedValue === ListFormatCharacter.Tab) return;
                this.textPosition = this.bulletNumberPosition;
            },
            onLevelChange(e) {
                let indentDiff = (this.options.level - this.lastLevel) * 360;
                this.options.leftIndent += indentDiff;
                this.options.hangingIndent += indentDiff;
                this.lastLevel = this.options.level;
            },
            onFirstNumberKeyPress (e) {
                let charCode = e.key.charCodeAt(0);
                let validCodes = _.numberCharCodes();
                switch (this.options.numberFormat) {
                    case NumberFormat.Letters:
                        validCodes = _.alphaCharCodes();
                        break;
                    case NumberFormat.CapitalLetters:
                        validCodes = _.alphaCharCodes(true);
                        break;
                    case NumberFormat.RomanNumbers:
                        validCodes = _.romanCharCodes(true);
                        break;
                    case NumberFormat.SmallRomanNumbers:
                        validCodes = _.romanCharCodes();
                        break;
                }

                if (!_.includes(validCodes, charCode)) e.preventDefault();
            },

            onBulletCharClick (charCode) {
                if (_.isNil(charCode) || isNaN(charCode)) return;
                this.options.bulletCharacter = String.fromCharCode(charCode);
            },

            onOtherCharactersClick () {
                const self = this;
                self.$dialog.open({
                    title: "Special Characters",
                    width: 605,
                    resizable: false,
                    adaptive: true,
                    component: SpecialCharSelection,
                    props: { modelValue: self.options.bulletCharacter },
                    onOk (e) {
                        self.options.bulletCharacter = e.component.selectedCharValue;
                    }
                });
            },

            buildLookups () {
                let mapVals = (items, defaultDisplay) => _.map(items, (value,idx) => ({ value, text: idx === 0 ? defaultDisplay : value }));
                this.lookups = {
                    numberFormats: NumberFormat.lookupItems,
                    formatChars: ListFormatCharacter.lookupItems,
                    bulletChars: [
                        { id: 0, name: "Default", character: String.fromCharCode(183), charCode: 183 },
                        { id: 1, name: "Dash", character: String.fromCharCode(45), charCode: 45 },
                        { id: 2, name: "Astrisk", character: String.fromCharCode(42), charCode: 42 },
                        { id: 3, name: "Diamond", character: String.fromCharCode(168), charCode: 168 },
                        { id: 4, name: "RightArrow", character: String.fromCharCode(174), charCode: 174 },
                        { id: 5, name: "White Bullet", character: String.fromCharCode(0x25E6), charCode: 0x25E6 },
                    ],
                    levels: mapVals([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], 1),
                    bulletSizes: mapVals([0, 6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 36, 48, 60, 71, 84, 96], "Auto"),
                    textBefore: mapVals([null,"(", "-", "<", "#", "§", "."], "None"),
                    textAfter: mapVals([null,".", ")", "-", ">"], "None")
                };
            },

            setCurrentCharCode (character) {
                if (_.isNil(character)) return;

                this.currentCharCode = character.charCodeAt(0);
                let inShortList = _.some(this.lookups.bulletChars, c => this.currentCharCode === c.charCode);
                if (inShortList || this.currentCharCode === this.extraCharCode) return;
                this.extraCharCode = this.currentCharCode;
            }
        }
    };
</script>
