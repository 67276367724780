<template>
    <rq-section-card :title="title" class="prompt-group" collapsible>
        <div class="row mb-3">
            <div class="col-auto p-0 ms-auto" v-rq-tooltip.hover.top :title="deleteAllTooltip">
                <b-btn
                    variant="theme"
                    size="sm"
                    :disabled="readOnly"
                    @click="onDeleteAll">Delete All</b-btn>
            </div>
        </div>
        <div v-for="prompt in prompts"
            :key="prompt.clientKey"
            class="row prompt-row">
            <div class="col-12">
                <saved-prompt-field
                    ref="promptFields"
                    :prompt="prompt"
                    @delete="onPromptDelete"
                />
            </div>
        </div>
    </rq-section-card>
</template>

<script>
    import { mapState } from "vuex";
    import SavedPromptField from "./SavedPromptField";
    export default {
        components: { SavedPromptField },

        props: {
            title: { type: String, default: "Prompt Group" },
            prompts: { type: Array, default: () => [] }
        },

        computed: {
            ...mapState({
                fileReadOnly: state => state.isPageReadOnly || state.orders.orderSummary.isLocked
            }),
            readOnly() { return this.fileReadOnly || _.some(this.$refs.promptFields, "readOnly"); },
            isGlobal() { return _.some(this.prompts, "isGlobal"); },
            deleteAllTooltip() {
                if(!this.readOnly) return "";
                return this.fileReadOnly ? "Access restricted on this file" : "One or more fields were saved by another user";
            }
        },

        methods: {
            onDeleteAll(){ this.$emit("delete-all", { description: this.title, prompts: this.prompts, isGlobal: this.isGlobal }); },
            onPromptDelete(e) { this.$emit("delete-prompt", e); }
        }
    };
</script>
