<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        title="Documents"
        title-size="sm"
        automation_id="dg_printDocs_allDocs"
        :actions="selectionActions"
        :data-source="gridDataSource"
        :config="gridConfig"
        :persist-state="false"
        v-model:search-value="searchText"
        @delete="onDelete"
        @rowClick="onGridRowClick"
        @rowDoubleClick="onGridRowDoubleClick"
        hide-export
        hide-show-column-chooser
        integrated-search
        remote-search
        rq-filters>
        <template #toolbar>
            <DocumentFilters
                v-model:file-documents-only="fileDocumentsOnlyValue"
                v-model:packages-only="packagesOnlyValue"
                v-model:selected-states="selectedStates"
            />
        </template>
    </rqdx-action-data-grid>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { DOCUMENT_ACTIONS } from "@/store/actions";
    import { DocumentGridModel } from '../../models';
    import { SearchRequest } from '@/shared/models/models';
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import DocumentHelpers from "../../helpers.js";
    import GridInvokerMixin from "@/shared/mixins/GridInvokerMixin";
    import { useVModel } from "@vueuse/core";
    import DocumentFilters from "./DocumentFilters.vue";

    export default {
        name: "AvailableDocuments",

        mixins: [ GridInvokerMixin({ grid:"dataGrid" }) ],

        components: { DocumentFilters },

        props: {
            hasDocuments: { type: Boolean, default: false },
            fileDocumentsOnly: { type: Boolean, default: false },
            packagesOnly: { type: Boolean, default: false },
            hasSelections: { type: Boolean, default: false }
        },

        emits: [
            "loaded",
            "update:fileDocumentsOnly",
            "update:packagesOnly",
            "update:hasDocuments",
            "update:hasSelections"
        ],

        setup(props, { emit }) {
            const fileDocumentsOnlyValue = useVModel(props, "fileDocumentsOnly", emit);
            const packagesOnlyValue = useVModel(props, "packagesOnly", emit);
            return {
                fileDocumentsOnlyValue,
                packagesOnlyValue
            };
        },

        data() {
            return {
                documents: [],
                gridDataSource: [],
                selectedItems: [],
                lookups: {},
                gridLoaded: false,
                isValid: false,
                ignoreSelectionEvent: false,
                componentLoaded: false,
                expandPackagesOnLoad: false,
                defaultFilter: true,
                childGrids: [],
                searchText: "",
                selectedStates: null
            };
        },

        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                isOrderLocked: state => state.orders.orderSummary.isLocked,
                readOnly: state => state.isPageReadOnly
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "usStatesLookup",
                "propertyStates"
            ]),
            selectionActions() {
                const self = this;
                return [{
                    eventName: "delete",
                    text: "Delete",
                    requireSelection: true,
                    allowMultiSelection: true,
                    disabled(e) { return self.readOnly || _.some(e.data, item => item.isRequired || !item.isOrderItem); }
                }];
            },
            usStates() { return this.lookupHelpers.getStates(); },
            documentCategories() { return this.lookupHelpers.getLookupItems(this.lookupItems.DOCUMENT_CATEGORIES); },
            stateFilter() { return _.join(this.selectedStates, ","); }
        },

        watch: {
            selectedItems(newValue, oldValue) {
                this.$emit("update:hasSelections", !_.isEmpty(newValue));
            },
            packagesOnly(newValue, oldValue){
                if(newValue === oldValue) return;
                this.refresh();
            },
            fileDocumentsOnly(newValue, oldValue){
                if(newValue === oldValue) return;
                this.refresh();
            },
            orderId(newVal, oldVal) {
                if(newVal === oldVal) return;
                this.gridRefresh();
            },
            stateFilter(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.refresh();
            }
        },

        created(){
            this.initGrid();
        },

        methods: {
            initGrid() {
                const self = this;

                const getCatValFilterExpr = val => [
                    ["DocumentCategories", "=", val],
                    "or",
                    ["DocumentCategories", "contains", `,${val},`],
                    "or",
                    ["DocumentCategories", "startswith", `${val},`],
                    "or",
                    ["DocumentCategories", "endswith", `,${val}`]
                ];

                const getCatFilterExpr = values => {
                    if(_.isNil(values)) return null;
                    if(!_.isArray(values)) {
                        return getCatValFilterExpr(values);
                    }
                    let result = [];
                    _.forEach(values, (val, index) => {
                        result.push(...getCatValFilterExpr(val));
                        if(index === values.length -1) return;
                        result.push("or");
                    });
                    return result;
                };

                self.selectedStates = _.isEmpty(self.propertyStates)
                    ? []
                    : [...self.propertyStates];

                self.gridConfig = {
                    columns: [
                        {
                            dataField: "isRequired",
                            dataType: "string",
                            caption: "Req?",
                            width: 80,
                            allowResizing: false
                        },
                        {
                            dataField: "description",
                            dataType: "string"
                        },
                        {
                            dataField: "documentCategories",
                            dataType: "string",
                            caption: "Categories",
                            calculateFilterExpression: getCatFilterExpr,
                            rqFilter: {
                                valueExpr: "name",
                                displayExpr: "name",
                                valueOperator: "contains",
                                dataSource: {
                                    loadMode: "raw",
                                    load: () => self.documentCategories
                                }
                            },
                            width: 155,
                            minWidth: 105
                        },
                        {
                            dataField: "lastPrint",
                            dataType: "datetime",
                            width: 110,
                            minWidth: 110
                        },
                    ],
                    dataRowTemplate(container, item) {
                        container.append(DocumentHelpers.getRowTemplate(item, self.searchText, self.onTogglePackageRow));
                    },
                    masterDetail: {
                        autoExpandAll: false,
                        enabled: false,
                        template(detailElement, detailInfo) {
                            let $childGrid = DocumentHelpers.createChildGrid(
                                detailElement,
                                detailInfo,
                                [
                                    { dataField: "isRequired", caption: "Req?", cellTemplate: DxGridUtils.boolCellTemplate },
                                    {
                                        dataField: "description",
                                        cellTemplate(cellElement, cellInfo) {
                                            cellElement.append(
                                                DocumentHelpers.highlightSearchText(
                                                    cellInfo.data.isOrderItem ? `<strong>${cellInfo.text}</strong>` : cellInfo.text,
                                                    self.searchText
                                                )
                                            );
                                        }
                                    },
                                    {
                                        dataField: "documentCategories",
                                        dataType: "string",
                                        caption: "Categories"
                                    },
                                    {
                                        dataField: "lastPrint",
                                        dataType: "datetime"
                                    },
                                ],
                                self.onChildGridSelectionChanged,
                                self.onGridRowDoubleClick
                            );

                            if(_.isNil($childGrid)) return;

                            self.childGrids.push({
                                key: detailInfo.key,
                                instance: $childGrid
                            });
                        }
                    },
                    // summary: {
                    //     totalItems: [
                    //         { name: "TotalDocsLabel", column: "description", alignment: "left", customizeText: () => "AVAILABLE DOCUMENTS" },
                    //         { name: "TotalDocs", column: "lastPrint", alignment: "right", summaryType: "count" }
                    //     ]
                    // },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,150], showInfo: true, visible: true },
                    remoteOperations: true,
                    selection: { mode: "multiple", showCheckBoxesMode: "never" },
                    noDataText: "No documents found",
                    wordWrapEnabled: false,
                    onSelectionChanged: self.onGridSelectionChanged,
                    onRowCollapsed: self.onGridRowCollapsed,
                    onOptionChanged: self.onGridOptionChanged,
                    allowColumnReordering: false
                };

                self.gridDataSource = {
                    key: "clientKey",
                    load: self.fetchGridData
                };
            },

            async fetchGridData(loadOptions){
                const self = this;
                if(self.cancelFetchData(loadOptions)) return;
                try {
                    var request = new SearchRequest({
                        parameters: {
                            orderId: self.orderId,
                            stateFilter: self.stateFilter
                        },
                        searchTerm: self.searchText
                    });
                    request.parseLoadOptions(loadOptions);
                    if(self.packagesOnlyValue) request.addFilter("isPackage", "=", true);
                    if(self.fileDocumentsOnlyValue) request.addFilter("orderDocumentID", ">", 0);

                    self.childGrids = [];
                    let apiPromise = self.$api.DocumentsApi.search(request);
                    let response = await self.$rqBusy.wait(apiPromise);
                    self.documents = _.map(response.results, dt => new DocumentGridModel(dt));
                    self.$emit("update:hasDocuments", !_.isEmpty(self.documents));
                    if(self.packagesOnly || !_.isEmpty(_.trim(self.searchText)))
                        self.expandAllPackages();

                    return { data: self.documents, totalCount: response.totalRecords };
                }
                catch(error) {
                    console.error(error);
                    self.$toast.error("An issue occurred retrieving the requested document templates.");
                    throw error;
                }
            },

            onDelete(e) {
                const self = this;
                if (self.readOnly) return;
                self.removeFromOrder(
                    _.filter(e.data, item => !item.isPackage && item.isOrderItem),
                    _.filter(e.data, item => item.isPackage && item.isOrderItem)
                );
            },

            onGridRowDoubleClick(e) {
                const self = this;
                e.event.stopPropagation();
                if (e.rowType !== "data") return;
                self.addToQueue([e.data]);
            },

            onTogglePackageRow(rowKey) {
                if (this.invokeGridMethod("isRowExpanded", rowKey))
                    this.invokeGridMethod("collapseRow", rowKey);
                else
                    this.invokeGridMethod("expandRow", rowKey);
            },

            onGridSelectionChanged(e) {
                const self = this;
                if(self.ignoreSelectionEvent) return;
                self.ignoreSelectionEvent = true;

                self.clearChildGridSelection();
                self.selectedItems = e.selectedRowsData;

                self.$nextTick(() => {
                    self.ignoreSelectionEvent = false;
                });
            },

            onChildGridSelectionChanged(e) {
                const self = this;

                if(self.ignoreSelectionEvent) return;
                self.ignoreSelectionEvent = true;

                self.invokeGridMethod("clearSelection");
                self.selectedItems = e.selectedRowsData.slice();

                self.$nextTick().then(() => {
                    self.ignoreSelectionEvent = false;
                });
            },

            onGridRowClick(e) {
                const self = this;
                if (e.rowType !== "data") return;
                if (e.isSelected && e.data.isPackage)
                    self.clearChildGridSelection(e.key);
            },

            onGridRowCollapsed(e) {
                this.clearChildGridSelection(e.key);
            },

            onGridOptionChanged: _.debounce(function(e) {
                const self = this;
                if(!_.includes(e.fullName, "column") || !_.includes(e.fullName, "width") || _.isEmpty(self.childGrids)) return;
                let columnCount = self.invokeGridMethod("columnCount");
                let columnWidths = [];
                for(let i=0; i < columnCount; i++) {
                    columnWidths.push(self.invokeGridMethod("columnOption", i, "width"));
                }
                _.each(self.childGrids, grid => _.each(columnWidths, (w,i) => _.invoke(grid, "instance.columnOption", i, "width", w)));
            }, 300, { leading: false, trailing: true }),

            addSelectedToQueue() {
                return this.addToQueue(this.selectedItems);
            },

            gridRefresh () { return this.invokeGridMethod("refresh"); },

            addAllToQueue() {
                return this.addToQueue(this.documents);
            },

            async addToQueue(items) {
                const self = this;
                if(_.isEmpty(items)) return;
                let pkgs = _.filter(items, item => item.isPackage).slice();
                let docs = _.filter(items, item => !item.isPackage).slice();
                await self.$store.dispatch(DOCUMENT_ACTIONS.ADD_LIST_TO_QUEUE, { docs, pkgs });
                if(_.every(items, "isOrderItem")) return;
                self.refresh();
            },

            removeFromOrder (docs, pkgs) {
                const self = this;
                let okHandler = async () => {
                    let storePromise = self.$store.dispatch(DOCUMENT_ACTIONS.REMOVE_FROM_ORDER, { docs, pkgs });
                    await self.$rqBusy.wait(storePromise);
                    self.refresh();
                    return true;
                };
                self.$dialog.confirm("Remove From File", "Are you sure you want to remove this document/package from the file?", okHandler);
            },

            removeItemFromOrder(item) {
                const self = this;
                let isPackage = (item.documentPackageID > 0 && !item.orderDocumentID);
                let storePromise = null;
                let okHandler = async () => {
                    storePromise = isPackage
                        ? self.$store.dispatch(DOCUMENT_ACTIONS.REMOVE_PKG_FROM_ORDER, item.documentPackageID)
                        : self.$store.dispatch(DOCUMENT_ACTIONS.REMOVE_DOC_FROM_ORDER, item.orderDocumentID);
                    await self.$rqBusy.wait(storePromise)
                    self.refresh();
                    return true;
                };
                self.$dialog.confirm("Remove From File", `Are you sure you want to remove this ${ isPackage ? "package" : "document" } from the file?`, okHandler);
            },

            clearSelected() {
                const self = this;
                self.selectedItems = [];
                self.clearChildGridSelection();
                self.invokeGridMethod("clearSelection");
            },

            clearChildGridSelection(key=null) {
                const self = this;
                if(_.isEmpty(self.childGrids)) return;
                _.forEach(self.childGrids, grid => {
                    if(!_.isNil(key) && key !== grid.key) return;
                    grid.instance.clearSelection();
                })
            },

            cancelFetchData(loadOptions) {
                return _.isEmpty(loadOptions)
                    || _.get(loadOptions, "filter[0]", null) === "clientKey"
                    || _.get(loadOptions, "filter[0][0]", null) === "clientKey";
            },

            expandAllPackages() {
                let packageItems = _.filter(this.documents, "isPackage");
                if(_.isEmpty(packageItems)) return;
                _.each(packageItems, item => {
                    if(_.isNil(item.clientKey)) return;
                    this.invokeGridMethod("expandRow", item.clientKey);
                });
            },

            emitLoaded() {
                this.componentLoaded = true;
                this.$emit("loaded");
            },

            refresh() {
                this.clearSelected();
                this.invokeGridMethod("collapseAll", -1);
                return this.invokeGridMethod("refresh");
            }
        }

    };
</script>